import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';
import { setSnackbar } from './snackbarSlices';
import { setRefreshSduAppointments } from './sduAppointmentsSlice';

const dialerSlice = createSlice({
    name: 'dialer',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {
        setDialer: (state, action) => {
            state.data = action.payload;
        },
        setDisableLeadDialer: (state, action) => {
            const leadId = action.payload;
            const dialerLeadId = state.data.id
            if (leadId === dialerLeadId) {
                const selectedItem = state.data;
                selectedItem.disabled = !selectedItem.disabled;
                selectedItem.lead_status_1.name = !selectedItem.disabled ? 'IN ATTESA' : 'DISATTIVATO';
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDialer.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchDialer.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchDialer.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
            .addCase(updateDialer.pending, (state) => {
                state.isError = false;
            })
            .addCase(updateDialer.fulfilled, (state) => {
                state.isError = false;
            })
            .addCase(updateDialer.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setDialer, setDisableLeadDialer, setRefreshDialer } = dialerSlice.actions;

export const fetchDialer = createAsyncThunk('fetch/dialer', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true))

        const state = getState();
        const currentLeadId = getState().manager.currentLeadId;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/dialer/${currentLeadId}`, {
            params: {
                page: state.dialerListQueryPage,
            }
        });

        if (response.status === 200) {

            dispatch(setIsLoading(false))
            return response.data.lead;

        } else {
            throw new Error('Error loading api');
        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;

    }

});

export const updateDialer = createAsyncThunk('update/dialer', async (_, { getState, dispatch }) => {

    const state = getState();
    const token = state.auth.token;
    const dialer = state.dialer.data;
    const snackbar = state.snackbar;
    const loggedId = state.auth.loggedId;
    const refreshSduAppointments = state.sduAppointments.refreshSduAppointments;
    const { currentLeadId } = state.manager;

    try {

        dispatch(setIsLoading(true));

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/dialer/update/${currentLeadId}/${loggedId}`,
            {
                op_assigned: dialer.op_assigned || null,
                op_master: dialer.op_master || null,
                problem_id: dialer.lead_problems?.id || null,
                source: dialer.lead_sources?.source || dialer.lead_sources?.label,
                referer: dialer.referer,
                note_default: dialer.note_default,
                customer_name: dialer.customer_name,
                customer_phone: dialer.customer_phone,
                customer_zipcode: dialer.customer_zipcode,
                customer_email: dialer.customer_email,
                status1_id: dialer.lead_status_1?.id || null,
                status2_id: dialer.lead_status_2?.id || null,
                status3_id: dialer.lead_status_3?.id || null,
                status4_id: dialer.lead_status_4?.id || null,
                appointment_at: dialer.appointment_at,
                calendar_at: dialer.calendar_at,
                disabled: [17, 19].includes(dialer.lead_status_1?.id) ? false : dialer.disabled,
                shop_id: dialer.shop_id,
                shop_info_id: dialer.shop_info_id,
                device_revenue: dialer.device_revenue,
                revenue: dialer.revenue,
                disputed: dialer.disputed,
                note_shop: dialer.note_shop,
            });

        if (response.status === 200) {
            dispatch(setSnackbar({ ...snackbar, is_saved_lead_success: true }))
            dispatch(setIsLoading(false));
            dispatch(setRefreshSduAppointments(!refreshSduAppointments));
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {
        dispatch(setSnackbar({ ...snackbar, is_saved_lead_error: true }))
        console.error('Error get Data:', error);
        throw error;
    }

});

export const updateDialerDisableLead = createAsyncThunk('update/dialer/disableLead', async (dataitemIndex, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;
        const id = state.historyLead.data[dataitemIndex].id;
        const disableNewValue = state.historyLead.data[dataitemIndex].disabled;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/dialer/disable-lead/${id}`,
            {
                disabled: disableNewValue,
                status1_id: disableNewValue === true ? 45 : 1
            });

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;
    }

});

export default dialerSlice.reducer;
