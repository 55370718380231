import React from 'react';
import { useSelector } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';

const LogRecordingsTable = () => {

  const logRecordings = useSelector((state) => state.logRecordings.data);

  const listLogRecordings = logRecordings.map((recordings) => {

    const { id, op, created_at, file_name } = recordings;

    return (
      <tr key={id}>
        <td>{id}</td>
        <td>{op}</td>
        <td>{created_at}</td>
        <td><ReactAudioPlayer
          src={file_name}
          controls
          preload="none"
          controlsList="nodownload"
        /></td>
      </tr>
    )
  })

  return (
    <div className="table-responsive datatable-custom table-fix-head card " id="table-log-recordings">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="8" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">LOG RECORDINGS</h5>
              </th>
            </tr>
            <tr>
              <th>ID</th>
              <th>OP ASSIGNED</th>
              <th>CREATED AT</th>
              <th>RECORDING</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {listLogRecordings && listLogRecordings.length > 0 ? (
              listLogRecordings
            ) : (
              <tr className="text-primary card-subtitle mb-2">
                <td colSpan="4" >No Results</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default LogRecordingsTable