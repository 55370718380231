import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid, Radio } from '@mui/material';
import OpSettings from '../../components/Modals/WorkToDo/OpSettings';
import NavbarRight from '../NavbarRight';
import FormControlLabel from '@mui/material/FormControlLabel';
import { setCurrentModal, setCurrentTabEditLead } from '../../redux/slices/modalSlice';
import { setCountdownSeconds, setRefreshManager } from '../../redux/slices/managerSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';
import { setRefreshWorkToDo, setWorkToDoType } from '../../redux/slices/workToDoSlice';

const ModalWorkToDo = () => {

    const dispatch = useDispatch();
    const refreshWorkToDo = useSelector((state) => state.workToDo.refreshWorkToDo);
    const { loggedId, loggedEmail } = useSelector((state) => state.auth);
    const { currentModal } = useSelector((state) => state.modal);
    const { refreshManager } = useSelector((state) => state.manager);
    const workToDoType = useSelector((state) => state.workToDo.workToDoType);

    const handleClose = () => {
        dispatch(setCurrentModal(""));
        dispatch(setCountdownSeconds(30));
        dispatch(setCurrentTabEditLead(0));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
    };

    const handleClickRefreshWorkToDo = () => {
        dispatch(setRefreshWorkToDo(!refreshWorkToDo))
        dispatch(setRefreshManager(!refreshManager))
    }

    const handleChangeStatus = (event) => {
        const newValue = event.target.value;
        dispatch(setWorkToDoType(newValue));
    };

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }} style={{ background: '#1a73e8', marginRight: 0 }}>

                <Toolbar>

                    <Grid container spacing={0}>

                        <Grid item xs={10}>
                            {!loggedEmail.includes('@udibox.it') &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            }

                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Work To Do</Button>
                            </Badge>

                            <IconButton
                                variant="text" color="inherit" onClick={handleClickRefreshWorkToDo}>
                                <RefreshIcon />
                            </IconButton>

                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NavbarRight />
                            </Box>
                        </Grid>
                    </Grid>

                </Toolbar>

            </AppBar>

            <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                <div className='row'>
                    <div className='col-12'>
                        <FormControlLabel
                            checked={workToDoType === 'ALL'}
                            onChange={(event) => handleChangeStatus(event, 'ALL')}
                            value="ALL"
                            control={<Radio />}
                            label="All"
                        />

                        <FormControlLabel
                            checked={workToDoType === 'ONLINE'}
                            onChange={(event) => handleChangeStatus(event, 'ONLINE')}
                            value="ONLINE"
                            control={<Radio />}
                            label="Online"
                        />

                        <FormControlLabel
                            checked={workToDoType === 'OFFLINE'}
                            onChange={(event) => handleChangeStatus(event, 'OFFLINE')}
                            value="OFFLINE"
                            control={<Radio />}
                            label="Offline"
                        />

                        {!loggedEmail.includes('@udibox.it') &&
                            <>
                                <FormControlLabel
                                    checked={workToDoType === 'YOU'}
                                    onChange={(event) => handleChangeStatus(event, 'YOU')}
                                    value="YOU"
                                    control={<Radio />}
                                    label={"OP" + loggedId}
                                />
                            </>
                        }

                        <FormControlLabel
                            checked={workToDoType === 'ECOMMERCE'}
                            onChange={(event) => handleChangeStatus(event, 'ECOMMERCE')}
                            value="ECOMMERCE"
                            control={<Radio />}
                            label="Ecommerce"
                        />
                    </div>
                    <div className='col-12 mt-2 mb-5'>
                        <OpSettings workToDoType={workToDoType} />
                    </div>
                </div>
            </div>

        </Dialog>
    );
}

export default ModalWorkToDo