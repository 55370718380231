import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { setLeadSearchParams } from '../../../../redux/slices/leadSearchSlice';

const OpAssignedSearch = () => {

    const dispatch = useDispatch();
    const manager = useSelector((state) => state.manager.data);
    const leadSearchParams = useSelector((state) => state.leadSearch.params);

    return (
        <Autocomplete
            fullWidth
            id="op_assigned"
            options={manager.op_settings}
            getOptionLabel={(option) => option.op_name || leadSearchParams.op_name}
            value={leadSearchParams.op_id || null}
            isOptionEqualToValue={(option) => option.op_name === leadSearchParams.op_name}
            onChange={(event, option) => {
                if (option) {
                    dispatch(
                        setLeadSearchParams(
                            {
                                ...leadSearchParams,
                                'op_id': option.id,
                                'op_name': option.op_name
                            })
                    );
                }
                else {
                    dispatch(
                        setLeadSearchParams(
                            {
                                ...leadSearchParams,
                                'op_id': null,
                                'op_name': null
                            })
                    );
                }
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        {option.op_name}
                    </li>
                );
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField fullWidth {...params} label="Op Assigned" />}
        />
    )
}

export default OpAssignedSearch
