import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const dialerNavbarSlice = createSlice({
    name: 'dialerNavbar',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {

    },
});

//export const { } = defaultSlice.actions;

export const updateDialerDisableLead = createAsyncThunk('update/dialer/menu/disableLead', async (_, { getState }) => {

    try {

        const state = getState();
        const { token, loggedId } = state.auth;
        const currentLeadId = getState().manager.currentLeadId;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/dialer/menu/disable-lead`,
            {
                lead_id: currentLeadId,
                logged_op: loggedId
            });

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;
    }

});

export const updateDialerTransferLead = createAsyncThunk('update/dialer/menu/transferLead', async (_, { getState }) => {

    try {

        const state = getState();
        const { token, loggedId } = state.auth;
        const currentLeadId = getState().manager.currentLeadId;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/dialer/menu/transfer-lead`,
            {
                lead_id: currentLeadId,
                op_id: loggedId
            });

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;
    }

});

export const updateDialerTransferLeadToOp7 = createAsyncThunk('update/dialer/menu/transferLeadToOp7', async (_, { getState }) => {

    try {

        const state = getState();
        const { token, loggedId } = state.auth;
        const currentLeadId = getState().manager.currentLeadId;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/dialer/menu/transfer-lead-to-op7`,
            {
                lead_id: currentLeadId,
                op_id: loggedId
            });

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;
    }

});

export const updateDialerPostponeCalendar = createAsyncThunk('update/dialer/menu/posponeCalendar', async (postponeData, { getState }) => {

    try {

        const state = getState();
        const { token } = state.auth;
        const currentLeadId = getState().manager.currentLeadId;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/dialer/menu/postpone-calendar`,
            {
                lead_id: currentLeadId,
                calendar_at: postponeData
            });

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;
    }

});


export default dialerNavbarSlice.reducer;
