import React from 'react'
import NearestShopsTable from './TabShopAssociate/NearestShopsTable';
import LeadZipcode from './TabShopAssociate/LeadZipcode';
import ItalyDbByCustomerZipcodeTable from './TabShopAssociate/ItalyDbByCustomerZipcodeTable';

const TabShopAssociate = () => {

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-center">
        <LeadZipcode />
      </div>
      <div className="col-12 d-flex justify-content-center">
        <NearestShopsTable />
      </div>
      <div className="col-12 d-flex justify-content-center mt-5">
        <ItalyDbByCustomerZipcodeTable/> 
      </div>
    </div>
  )
}

export default TabShopAssociate