import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoadingSmall } from './managerSlice';

const initialParams = {
    op_id: '',
    op_name: '',
    id: '',
    lead_id: '',
    customer_name: '',
    customer_phone: '',
    created_at_from: '',
    created_at_to: '',
    status1_id: null,
    status2_id: null,
    status3_id: null,
    status4_id: null,
    note: ''
}

const logNotesSearchSlice = createSlice({
    name: 'logNotesSearch',
    initialState: {
        data: [],
        params: initialParams,
        refreshLogNotesSearch: false,
        isError: false
    },
    reducers: {
        setLogNotesSearchParams: (state, action) => {
            state.params = action.payload;
        },
        setResetLogNotesSearchParams: (state) => {
            state.params = initialParams; 
        },
        setRefreshLogNotesSearch: (state, action) => {
            state.refreshLogNotesSearch = action.payload;
        },
        setQueryPage: (state, action) => {
            state.params.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogNotesSearch.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchLogNotesSearch.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchLogNotesSearch.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setLogNotesSearchParams, setRefreshLogNotesSearch, setResetLogNotesSearchParams, setQueryPage } = logNotesSearchSlice.actions;

export const fetchLogNotesSearch = createAsyncThunk('fetch/logNotesSearch', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoadingSmall(true));

        const state = getState();
        const { page, id, op_id, lead_id, customer_name, customer_phone, created_at_from, created_at_to, status1_id, status2_id, status3_id, status4_id, note } = getState().logNotesSearch.params;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/search/log-notes', {
            params: {
                page,
                id,
                op_logged: op_id,
                lead_id,
                customer_name,
                customer_phone,
                created_at_from,
                created_at_to,
                status1_id,
                status2_id,
                status3_id,
                status4_id,
                note
            }
        });

        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;

    } finally {
        dispatch(setIsLoadingSmall(false));
    }

});


export default logNotesSearchSlice.reducer;
