import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid } from '@mui/material';
import NavbarRight from '../../components/NavbarRight';
import TableLogReleases from '../../components/Modals/LogReleases/TableLogReleases';
import { setCurrentModal } from '../../redux/slices/modalSlice';
import { setCountdownSeconds } from '../../redux/slices/managerSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';

const ModalLogReleases = () => {

    const dispatch = useDispatch();
    const { loggedName } = useSelector((state) => state.auth);
    const isLoading = useSelector((state) => state.manager.isLoading);
    const { currentModal } = useSelector((state) => state.modal);

    const handleClose = () => {
        dispatch(setCurrentModal(''));
        dispatch(setCountdownSeconds(30));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
    };

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }} style={{ background: '#1a73e8', marginRight: 0 }}>

                <Toolbar>

                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            {loggedName.includes('Operatore') &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Log Releases</Button>
                            </Badge>

                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NavbarRight />
                            </Box>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            {!isLoading ? (
                <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                    <div className='row mt-2'>
                        <div className='col-12 mt-2'>
                            <TableLogReleases />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                </div>
            )}

        </Dialog>
    );
}

export default ModalLogReleases