import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import CreatedAtSearch from '../LeadSearch/Form/CreatedAtSearch';
import AppointmentAtSearch from '../LeadSearch/Form/AppointmentAtSearch';
import StatusSearch from '../LeadSearch/Form/StatusSearch';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { setLeadSearchParams, setRealtimeSearch } from '../../../redux/slices/leadSearchSlice';
import { setRefreshRemarketing, setTypeDate } from '../../../redux/slices/remarketingSlice';
import { storeRemarketing } from '../../../redux/slices/remarketingSlice';
import { storeLogAction } from '../../../redux/slices/logActionStoreSlice';

const FormRmktSearch = () => {

    const dispatch = useDispatch();
    const leadSearch = useSelector((state) => state.leadSearch.data);
    const leadSearchParams = useSelector((state) => state.leadSearch.params);
    const typeDate = useSelector((state) => state.remarketing.typeDate);
    const refreshRemarketing = useSelector((state) => state.remarketing.refreshRemarketing);
    const { currentModal } = useSelector((state) => state.modal);

    const handleAddRemarketing = async () => {
        dispatch(storeRemarketing())
        dispatch(setRefreshRemarketing(!refreshRemarketing))
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/addRemarketing',
                value: JSON.stringify(leadSearchParams),
                input_type: 'button'
            })
        );
    }

    useEffect(() => {
        dispatch(setRealtimeSearch(true))
    }, [])

    return (
        <div>
            <FormControlLabel
                checked={typeDate === 'created_at'}
                onChange={() => {
                    dispatch(setTypeDate('created_at'))
                    dispatch(
                        setLeadSearchParams(
                            {
                                ...leadSearchParams,
                                appointment_at_from: null,
                                appointment_at_to: null
                            }
                        )
                    )
                }}
                value="created_at"
                control={<Radio />}
                label="Created at"
            />
            <FormControlLabel
                checked={typeDate === 'appointment_at'}
                onChange={() => {
                    dispatch(setTypeDate('appointment_at'))
                    dispatch(
                        setLeadSearchParams(
                            {
                                ...leadSearchParams,
                                created_at_from: null,
                                created_at_to: null
                            }
                        )
                    )
                }}
                value="appointment_at"
                control={<Radio />}
                label="Appointment at"
            />

            {typeDate === 'created_at' &&
                <CreatedAtSearch />
            }

            {typeDate === 'appointment_at' &&
                <AppointmentAtSearch />
            }

            <StatusSearch />

            {leadSearch.total > 0 ? (
                leadSearchParams &&
                    (leadSearchParams.status1_id ||
                        leadSearchParams.status2_id ||
                        leadSearchParams.status3_id ||
                        leadSearchParams.status4_id) &&
                    ((leadSearchParams.created_at_from && leadSearchParams.created_at_to) ||
                        (leadSearchParams.appointment_at_from && leadSearchParams.appointment_at_to)) ? (
                    <Button variant="contained" onClick={() => handleAddRemarketing()}>
                        Aggiungi Remarketing ({leadSearch.total})
                    </Button>
                ) : null
            ) : (
                <Button variant="contained" onClick={() => handleAddRemarketing()}>
                    Aggiungi Remarketing ({leadSearch.total})
                </Button>
            )}

        </div>
    )
}

export default FormRmktSearch
