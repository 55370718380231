import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { setLogNotesSearchParams, setRefreshLogNotesSearch } from '../../../../redux/slices/logNotesSearchSlice';

const StatusSearch = () => {

    const dispatch = useDispatch();
    const logNotesSearchParams = useSelector((state) => state.logNotesSearch.params);
    const status = useSelector((state) => state.status.data);
    const { refreshLogNotesSearch } = useSelector((state) => state.logNotesSearch);

    return (
        <>
            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <Autocomplete
                    fullWidth
                    id="status1_id"
                    options={status.status1}
                    getOptionLabel={(option) => (option && logNotesSearchParams.status1_id !== null ? option.name : "")}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, status1_id: option.id }));
                        }
                        else {
                            dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, status1_id: "" }));
                        }
                        dispatch(setRefreshLogNotesSearch(!refreshLogNotesSearch));
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status 1" />}
                />

                <Autocomplete
                    fullWidth
                    id="status2_id"
                    options={status.status2}
                    getOptionLabel={(option) => (option && logNotesSearchParams.status2_id !== null ? option.name : "")}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, status2_id: option.id }));
                        }
                        else {
                            dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, status2_id: "" }));
                        }
                        dispatch(setRefreshLogNotesSearch(!refreshLogNotesSearch));
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status 2" />}
                />
            </Stack>

            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <Autocomplete
                    fullWidth
                    id="status3_id"
                    options={status.status3}
                    getOptionLabel={(option) => (option && logNotesSearchParams.status3_id !== null ? option.name : "")}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, status3_id: option.id }));
                        }
                        else {
                            dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, status3_id: "" }));
                        }
                        dispatch(setRefreshLogNotesSearch(!refreshLogNotesSearch));
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status 3" />}
                />

                <Autocomplete
                    fullWidth
                    id="status4_id"
                    options={status.status4}
                    getOptionLabel={(option) => (option && logNotesSearchParams.status4_id !== null ? option.name : "")}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, status4_id: option.id }));
                        }
                        else {
                            dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, status4_id: "" }));
                        }
                        dispatch(setRefreshLogNotesSearch(!refreshLogNotesSearch));
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status 4" />}
                />
            </Stack>
        </>
    )
}

export default StatusSearch