import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const sduAppointmentsSlice = createSlice({
    name: 'problems',
    initialState: {
        data: [],
        refreshSduAppointments: false,
        isError: false
    },
    reducers: {
        setRefreshSduAppointments: (state, action) => {
            state.refreshSduAppointments = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSduAppointments.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchSduAppointments.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchSduAppointments.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setRefreshSduAppointments } = sduAppointmentsSlice.actions;

export const fetchSduAppointments = createAsyncThunk('fetch/sduAppointments', async (_, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;
        const shopInfoId = getState().dialer.data.shop_info?.id;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/sdu-appointments/${shopInfoId}`);
        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default sduAppointmentsSlice.reducer;
