import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';

const initialParams = {
    opName: "",
    lead_ids: "",
    op: "",
    source: ""
};

const remarketingByLeadsSlice = createSlice({
    name: 'remarketingByLeads',
    initialState: {
        data: [],
        params: initialParams,
        isError: false
    },
    reducers: {
        setRemarketingByLeadsParams: (state, action) => {
            state.params = action.payload;
        },
    },
});

export const { setRemarketingByLeadsParams } = remarketingByLeadsSlice.actions;

export const storeRemarketingByLeads = createAsyncThunk('store/remarketingByLeads', async (_, { getState, dispatch }) => {

    dispatch(setIsLoading(true));

    try {
        const state = getState();
        const token = state.auth.token;
        const { lead_ids, source } = state.remarketingByLeads.params;
        const { opIdTo } = state.transfers.params;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/settings/remarketing/store-rmkt-by-leads`, {
            lead_ids : lead_ids.split('\n').join(','),
            opIdTo,
            source
        });

        if (response.status === 200) {
            dispatch(setIsLoading(false))
            dispatch(setRemarketingByLeadsParams(initialParams));
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {
        console.error('Error getting data:', error);
        throw error;
    }

});

export default remarketingByLeadsSlice.reducer;
