import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { setDialer } from '../../../../../redux/slices/dialerSlice';
import { Button } from '@mui/material';
import { storeLogAction } from '../../../../../redux/slices/logActionStoreSlice';

const OpAssignedDropdown = () => {

    const dispatch = useDispatch();
    const manager = useSelector((state) => state.manager.data);
    const dialer = useSelector((state) => state.dialer.data);
    const [opAssignedUnlocked, setOpAssignedUnlocked] = useState(true);
    const { currentModal } = useSelector((state) => state.modal);
    const { loggedId } = useSelector((state) => state.auth);

    const handleOnClickUnlock = () => {
        setOpAssignedUnlocked(false)
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/shopAssociate/OpAssigned/UnlockOpAssigned',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                {opAssignedUnlocked &&
                    <Button onClick={() => handleOnClickUnlock()} variant="contained" style={{ position: 'absolute', zIndex: 10, bottom: 22, right: 40, fontSize: 10 }}>UNLOCK</Button>
                }
                <Autocomplete
                    disabled={opAssignedUnlocked}
                    disableClearable
                    id="op_assigned"
                    options={manager.op_settings}
                    getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option.id : String(dialer.op_assigned)}
                    value={dialer.op_assigned || null}
                    isOptionEqualToValue={(option) => option.id === dialer.op_assigned}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setDialer({ ...dialer, op_assigned: option.id }))
                        } else {
                            dispatch(setDialer({ ...dialer, op_assigned: [] }))
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.id}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField required margin="normal" {...params} label="Op Assigned" />} />

            </div>

            {loggedId === 7 &&
                <Autocomplete disableClearable
                    id="op_master"
                    options={manager.op_settings}
                    getOptionLabel={(option) => typeof option === 'string' || option instanceof String ? option.id : String(dialer.op_master)}
                    value={dialer.op_master || null}
                    isOptionEqualToValue={(option) => option.id === dialer.op_master}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setDialer({ ...dialer, op_master: option.id }))
                        } else {
                            dispatch(setDialer({ ...dialer, op_master: [] }))
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.id}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField required margin="normal" {...params} label="Op Master" />} />
            }

        </>

    )
}

export default OpAssignedDropdown