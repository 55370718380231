import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const nearestShopsSlice = createSlice({
    name: 'nearestShops',
    initialState: {
        data: [],
        isLoadingNearestShops: false,
        isError: false
    },
    reducers: {
        setIsLoadingNearestShops: (state, action) => {
            state.isLoadingNearestShops = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNearestShops.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchNearestShops.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchNearestShops.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setIsLoadingNearestShops } = nearestShopsSlice.actions;

export const fetchNearestShops = createAsyncThunk('fetch/nearestShops', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoadingNearestShops(true));

        const state = getState();
        const customerZipcode = getState().dialer.data.customer_zipcode;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/find-nearest-shops/${customerZipcode}`);
        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;

    } finally {

        dispatch(setIsLoadingNearestShops(false));
        
    }

});

export default nearestShopsSlice.reducer;
