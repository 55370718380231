import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Box, Grid } from '@mui/material';
import NavbarRight from '../../components/NavbarRight';
import { setCurrentModal } from '../../redux/slices/modalSlice';
import { setRefreshLogNotesSearch, setResetLogNotesSearchParams } from '../../redux/slices/logNotesSearchSlice';

import FormLogNotesSearch from '../../components/Modals/LogNotesSearch/FormLogNotesSearch';
import TableLogNotesSearch from '../../components/Modals/LogNotesSearch/TableLogNotesSearch';
import { setCountdownSeconds } from '../../redux/slices/managerSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';

const ModalLogNotesSearch = () => {

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.manager.isLoading);
    const logNotesSearchParams = useSelector((state) => state.logNotesSearch.params);
    const { loggedEmail } = useSelector((state) => state.auth);
    const { refreshLogNotesSearch } = useSelector((state) => state.logNotesSearch);
    const { currentModal } = useSelector((state) => state.modal);

    const handleClose = () => {
        dispatch(setCurrentModal(''));
        dispatch(setCountdownSeconds(30));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
    }

    const handleClickResetLogNotesSearch = () => {
        dispatch(setResetLogNotesSearchParams());
        dispatch(setRefreshLogNotesSearch(!refreshLogNotesSearch))
    }

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }} style={{ background: '#1a73e8', marginRight: 0 }}>

                <Toolbar>

                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            {!loggedEmail.includes('@udibox.it') &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            }

                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Log Notes</Button>
                            </Badge>

                            {logNotesSearchParams.lead_id &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>{logNotesSearchParams.lead_id}</Button>
                                </Badge>
                            }

                            {logNotesSearchParams.customer_name &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>{logNotesSearchParams.customer_name}</Button>
                                </Badge>
                            }

                            {logNotesSearchParams.customer_phone &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>{logNotesSearchParams.customer_phone}</Button>
                                </Badge>
                            }

                            {logNotesSearchParams.customer_email &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>{logNotesSearchParams.customer_email}</Button>
                                </Badge>
                            }

                            {((logNotesSearchParams.created_at_from && logNotesSearchParams.created_at_from !== "Invalid Date") && (logNotesSearchParams.created_at_to && logNotesSearchParams.created_at_to !== "Invalid Date")) &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Created At: {logNotesSearchParams.created_at_from} - {logNotesSearchParams.created_at_to}</Button>
                                </Badge>
                            }

                            {((logNotesSearchParams.appointment_at_from && logNotesSearchParams.appointment_at_from !== "Invalid Date") && (logNotesSearchParams.appointment_at_to && logNotesSearchParams.appointment_at_to !== "Invalid Date")) &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Appointment At: {logNotesSearchParams.appointment_at_from} - {logNotesSearchParams.appointment_at_to}</Button>
                                </Badge>
                            }

                            {logNotesSearchParams.op_name &&
                                <>
                                    <Badge color="primary" style={{ marginRight: 15 }}>
                                        <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>OP{logNotesSearchParams.op_id}</Button>
                                    </Badge>
                                    <Badge color="primary" style={{ marginRight: 15 }}>
                                        <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>{logNotesSearchParams.op_name}</Button>
                                    </Badge>
                                </>
                            }

                            {logNotesSearchParams.source &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>{logNotesSearchParams.source}</Button>
                                </Badge>
                            }

                            {logNotesSearchParams.business_name &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>{logNotesSearchParams.business_name}</Button>
                                </Badge>
                            }

                            {logNotesSearchParams.shop_province &&
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>{logNotesSearchParams.shop_province}</Button>
                                </Badge>
                            }

                            <IconButton
                                variant="text"
                                edge="end" color="inherit" aria-label="close" onClick={handleClickResetLogNotesSearch}>
                                <BackspaceIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NavbarRight />
                            </Box>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            {!isLoading ? (
                <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                    <div className='row mt-2'>
                        <div className='col-3 mt-2'>
                            <FormLogNotesSearch />
                        </div>
                        <div className='col-9 mt-2'>
                            <TableLogNotesSearch />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                </div>
            )}

        </Dialog>
    );
}

export default ModalLogNotesSearch