import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { setDialer } from '../../../../../redux/slices/dialerSlice';
import { storeLogAction } from '../../../../../redux/slices/logActionStoreSlice';

const LeadZipcode = () => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const isLoadingNearestShops = useSelector((state) => state.nearestShops.isLoadingNearestShops);
    const { currentModal } = useSelector((state) => state.modal);

    const handleRemoveAssociatedShop = () => {

        dispatch(setDialer(
            {
                ...dialer,
                'shop_id': 0,
                'shop_info_id': 0,
                shop: {
                    ...dialer.shop,
                    id: null,
                    business_name: null
                },
                shop_info: {
                    id: null,
                    shop_address: null,
                    shop_province: null,
                }
            }
        ));

        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/tabs/shopAssociate/removeShopAssociated',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    }

    return (
        <>

            <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
                {isLoadingNearestShops &&
                    <CircularProgress style={{ position: 'absolute', top: 22 }} />
                }
                <TextField
                    required
                    style={{ 'marginRight': 15 }}
                    inputProps={{ maxLength: 5 }}
                    autoComplete="off"
                    onChange={(event) => {
                        dispatch(setDialer({ ...dialer, customer_zipcode: event.target.value }))
                    }}
                    margin="normal" fullWidth label="Zipcode" id="customer_zipcode" value={dialer.customer_zipcode || ""} />
                <Button
                    onClick={(event) => handleRemoveAssociatedShop(event)}
                    type="submit" variant="contained" data-type="SAVE_NOTE" style={{ height: 40, width: 370, marginTop: 23 }}>Remove Shop Associated</Button>
            </div>
        </>
    )
}

export default LeadZipcode
