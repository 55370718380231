import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import FormNewLead from '../../Modals/AddNewLead/FormNewLead';
import { setQueryPage, setRefreshLogNotesSearch } from '../../../redux/slices/logNotesSearchSlice';
import { setCountdownSeconds, setIsLoadingSmall } from '../../../redux/slices/managerSlice';
import { storeLogAction } from '../../../redux/slices/logActionStoreSlice';

const TableLeadsSearch = () => {

    const dispatch = useDispatch();
    const logNotesSearch = useSelector((state) => state.logNotesSearch.data);
    const isLoadingSmall = useSelector((state) => state.manager.isLoadingSmall);
    const { refreshLogNotesSearch } = useSelector((state) => state.logNotesSearch);
    const { last_page } = useSelector((state) => state.logNotesSearch.data);
    const { page } = useSelector((state) => state.logNotesSearch.params);
    const { currentModal } = useSelector((state) => state.modal);

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        dispatch(setCountdownSeconds(30));
        setOpen(false);
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
    }

    const goToPage = (event, value) => {
        dispatch(setIsLoadingSmall(true))
        dispatch(setQueryPage(value));
        dispatch(setRefreshLogNotesSearch(!refreshLogNotesSearch));
    }

    const list = Array.isArray(logNotesSearch.data) ? logNotesSearch.data.map((notes) => {

        const { id, lead_id, op_logged, note, created_at } = notes;
        const { customer_name, customer_phone } = notes.leads;

        return (
            <tr key={id}>
                <td>{op_logged}</td>
                <td>{id}</td>
                <td>{lead_id}</td>
                <td>{customer_name}</td>
                <td>{customer_phone}</td>
                <td className='col-responsive'>{note}</td>
                <td>
                    <ul className='list-unstyled m-0'>
                        {notes.lead_status_1 &&
                            <li>{notes.lead_status_1?.name}</li>
                        }
                        {notes.lead_status_2 &&
                            <li>{notes.lead_status_2?.name}</li>
                        }
                        {notes.lead_status_3 &&
                            <li>{notes.lead_status_3?.name}</li>
                        }
                        {notes.lead_status_4 &&
                            <li>{notes.lead_status_4?.name}</li>
                        }
                    </ul>
                </td>
                <td>{created_at}</td>
            </tr>
        )
    }) : <tr>
        <td colSpan="12" className="text-center">No Results found</td>
    </tr>

    return (
        <>
            <div className="table-responsive datatable-custom table-fix-head card" id="table-dialer">
                <div className="table-responsive text-nowrap">
                    <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                        <thead>
                            <tr>
                                <th colSpan="12" className="mt-2 mb-2">
                                    {!isLoadingSmall ? (
                                        <h5 className="text-center text-white mb-0 pb-2">LOG NOTES ({logNotesSearch.total || 0})</h5>
                                    ) : (
                                        <h5 className="text-center text-white mb-0 pb-2">LOG NOTES </h5>
                                    )}
                                </th>
                            </tr>
                            <tr>
                                {/* <th></th> */}
                                <th>OP LOGGED</th>
                                <th>ID</th>
                                <th>LEAD ID</th>
                                <th>CUSTOMER NAME</th>
                                <th>CUSTOMER PHONE</th>
                                <th>NOTE</th>
                                <th>STATUS</th>
                                <th>CREATED AT</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {!isLoadingSmall ? (
                                list
                            ) : (
                                <tr>
                                    <td colSpan="12"><CircularProgress /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {logNotesSearch.total > 0 &&
                <>
                    <div className="row mt-4 mb-5">
                        <div className="col-12 d-flex justify-content-center">
                            {!isLoadingSmall &&
                                <Stack spacing={2}>
                                    <Pagination count={last_page} defaultPage={page} onChange={goToPage} color="primary" />
                                </Stack>
                            }
                        </div>
                    </div>
                </>
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormNewLead />
                </Box>
            </Modal>

        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default TableLeadsSearch
