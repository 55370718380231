import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { setIsLoadingSmall } from '../../../redux/slices/managerSlice';
import { setIsDrawerOpen, setQueryPage, setRefreshLogActionsSearch, setValueObject } from '../../../redux/slices/logActionsSearchSlice';

const TableLogActions = () => {

  const dispatch = useDispatch();
  const logActions = useSelector((state) => state.logActionsSearch.data);
  const isLoadingSmall = useSelector((state) => state.manager.isLoadingSmall);
  const refreshLogActionsSearch = useSelector((state) => state.logActionsSearch.refreshLogActionsSearch);

  const goToPage = (event, value) => {
    dispatch(setIsLoadingSmall(true))
    dispatch(setQueryPage(value))
    dispatch(setRefreshLogActionsSearch(!refreshLogActionsSearch))
  }

  const toggleDrawer = (value) => {
    dispatch(setIsDrawerOpen(true))
    dispatch(setValueObject(value))
  };

  const list = Array.isArray(logActions.data) ? logActions.data.map((log) => {

    const { id, op_logged, type, section, input_type, value, created_at } = log;

    return (
      <tr key={id}>
        {/{"/.test(value) ? (
          <td style={{ width: 50 }}>
            <Button onClick={() => toggleDrawer(value)}>Open</Button>
          </td>
        ) : (
          <td style={{ width: 50 }}>{value}</td>
        )}
        <td>{id}</td>
        <td>{created_at}</td>
        <td>{op_logged}</td>
        <td>{type}</td>
        <td>{section}</td>
        <td>{input_type}</td>
      </tr>
    )
  }) : <tr>
    <td colSpan="13" className="text-center">No Results found</td>
  </tr>

  return (
    <div className="table-responsive datatable-custom table-fix-head card" id="table-log-actions">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="8" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">
                  <div className="d-flex justify-content-center">
                    <span style={{ marginTop: 15 }} >ACTIONS ({logActions.total})</span>
                  </div>
                </h5>
              </th>
            </tr>
            <tr>
              <th>VALUE</th>
              <th>ID</th>
              <th>CREATED AT</th>
              <th>OP LOGGED</th>
              <th>TYPE</th>
              <th>SECTION</th>
              <th>INPUT TYPE</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {!isLoadingSmall ? (
              list
            ) : (
              <tr>
                <td colSpan="12"><CircularProgress /></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {logActions.total > 0 &&
        <div className="col-12 d-flex justify-content-center mt-4 mb-5">
          {!isLoadingSmall &&
            <Stack spacing={2}>
              <Pagination count={logActions.last_page} defaultPage={logActions.current_page} onChange={goToPage} color="primary" />
            </Stack>
          }
        </div>
      }
    </div>


  )
}

export default TableLogActions