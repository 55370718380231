import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import DashboardSearch from './DashboardSearch';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setDashboardOpDetailParams, setOpenDashboardDetail } from '../../../redux/slices/dashboardOpDetailSlice';
import DashboardDetail from './DashboardDetail';

const DashboardBox = () => {

    const dispatch = useDispatch();

    const dashboardOp = useSelector((state) => state.dashboardOp.data);
    const dashboardOpDetail = useSelector((state) => state.dashboardOpDetail.data);
    const { type_date } = useSelector((state) => state.dashboardOp.params);

    const created_at_low_rate = [0, 14.99];
    const created_at_medium_rate = [15, 19.99];
    const created_at_high_rate = 20;
    const appointment_at_low_rate = [0, 39.99];
    const appointment_at_medium_rate = [40, 49.99];
    const appointment_at_high_rate = 50;
    const appointment_created_at_low_rate = [0, 39.99];
    const appointment_created_at_medium_rate = [40, 49.99];
    const appointment_created_at_high_rate = 50;

    const handleClickOpenDashboardDetail = (op_master, type_box, type_leads ) => {
        dispatch(setOpenDashboardDetail(true));
        dispatch(
            setDashboardOpDetailParams({
                ...dashboardOpDetail.params,
                op_master,
                type_box,
                type_leads
            })
        );
    };

    const papers = dashboardOp?.dashboard ? dashboardOp.dashboard.map((op, index) => {

        return (

            <div key={index} className="mt-3">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Stack spacing={1} direction="row" sx={{ margin: '0 auto' }}>

                            <Badge color="secondary" badgeContent={0} style={{ marginLeft: 7 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: '#efef57', color: 'black', fontSize: 15, width: 60 }}>{op.position}°</Button>
                            </Badge>

                            <Badge color="secondary" badgeContent={0} style={{ marginLeft: 7 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: '#1a73e8', color: 'white', fontSize: 15, width: 60 }}>OP{op.id}</Button>
                            </Badge>

                            <Badge color="secondary" badgeContent={0} style={{ marginLeft: 7 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: '#1a73e8', color: 'white', fontSize: 15, width: 230 }}>{op.op_name}</Button>
                            </Badge>

                            {((type_date === 'created_at') && op.sum_rate >= created_at_low_rate[0] && op.sum_rate <= created_at_low_rate[1]) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#e80000' }}>{op.sum_rate}%</div>
                                </>
                            }
                            {((type_date === 'created_at') && op.sum_rate >= created_at_medium_rate[0] && op.sum_rate <= created_at_medium_rate[1]) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#f5a71c' }}>{op.sum_rate}%</div>
                                </>
                            }
                            {((type_date === 'created_at') && op.sum_rate >= created_at_high_rate) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#4fca00' }}>{op.sum_rate}%</div>
                                </>
                            }

                            {(type_date === 'appointment_at' && op.sum_rate >= appointment_at_low_rate[0] && op.sum_rate <= appointment_at_low_rate[1]) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#e80000' }}>{op.sum_rate}%</div>
                                </>
                            }
                            {(type_date === 'appointment_at' && op.sum_rate >= appointment_at_medium_rate[0] && op.sum_rate <= appointment_at_medium_rate[1]) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#f5a71c' }}>{op.sum_rate}%</div>
                                </>
                            }
                            {(type_date === 'appointment_at' && op.sum_rate >= appointment_at_high_rate) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#4fca00' }}>{op.sum_rate}%</div>
                                </>
                            }

                            {(type_date === 'appointment_created_at' && op.sum_rate >= appointment_created_at_low_rate[0] && op.sum_rate <= appointment_created_at_low_rate[1]) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#e80000' }}>{op.sum_rate}%</div>
                                </>
                            }
                            {(type_date === 'appointment_created_at' && op.sum_rate >= appointment_created_at_medium_rate[0] && op.sum_rate <= appointment_created_at_medium_rate[1]) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#f5a71c' }}>{op.sum_rate}%</div>
                                </>
                            }
                            {(type_date === 'appointment_created_at' && op.sum_rate >= appointment_created_at_high_rate) &&
                                <>
                                    <div className='dashboard-paper-avarage-number' style={{ width: '150px', color: '#4fca00' }}>{op.sum_rate}%</div>
                                </>
                            }
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>

                        <div className='row align-items-stretch'>

                            <div className='col-11 d-flex flex-column' style={{ paddingRight: 0 }}>

                                <Box className='dashboard-papers'>

                                    <Paper elevation={2} style={{ backgroundColor: '#1a73e8', color: '#fff', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ fontSize: '0.8em', transform: 'rotate(-90deg)', whiteSpace: 'nowrap' }}><strong>NEW LEADS</strong></div>
                                    </Paper>

                                    {type_date === 'created_at' ? (

                                        <>
                                            <Paper className={`dashboard-paper${op.new_leads.pending_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.new_leads.pending_leads > 0 ? handleClickOpenDashboardDetail(op.id, 'pending_leads', 'new_leads') : null}>
                                                <div className='dashboard-paper-title' style={{ color: '#f5a71c' }}>PENDING LEADS</div>
                                                <div className='dashboard-paper-number' style={{ color: '#f5a71c', fontWeight: 'bold' }}>{op.new_leads.pending_leads}</div>
                                            </Paper>

                                            <Paper className={`dashboard-paper${op.new_leads.negative_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.new_leads.negative_leads > 0 ? handleClickOpenDashboardDetail(op.id, 'negative_leads', 'new_leads') : null}>
                                                <div className='dashboard-paper-title' style={{ color: '#e80000' }}>NEGATIVE LEADS</div>
                                                <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{op.new_leads.negative_leads}</div>
                                            </Paper>
                                        </>

                                    ) : (

                                        <>
                                            <Paper className="dashboard-paper dashboard-paper-no-pointer" elevation={2}></Paper>
                                            <Paper className="dashboard-paper dashboard-paper-no-pointer" elevation={2}></Paper>
                                        </>

                                    )}

                                    <Paper className={`dashboard-paper${op.new_leads.pending_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.new_leads.pending_apt > 0 ? handleClickOpenDashboardDetail(op.id, 'pending_apt', 'new_leads') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#f5a71c' }}>PENDING APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#f5a71c', fontWeight: 'bold' }}>{op.new_leads.pending_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${op.new_leads.negative_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.new_leads.negative_apt ? handleClickOpenDashboardDetail(op.id, 'negative_apt', 'new_leads') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#e80000' }}>NEGATIVE APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{op.new_leads.negative_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${op.new_leads.disputed_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.new_leads.disputed_apt ? handleClickOpenDashboardDetail(op.id, 'disputed_apt', 'new_leads') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#e80000' }}>DISPUTED APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{op.new_leads.disputed_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${op.new_leads.positive_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.new_leads.positive_apt ? handleClickOpenDashboardDetail(op.id, 'positive_apt', 'new_leads') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#4fca00' }}>POSITIVE APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#4fca00', fontWeight: 'bold' }}>{op.new_leads.positive_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${op.new_leads.total_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.new_leads.total_leads ? handleClickOpenDashboardDetail(op.id, 'total_leads', 'new_leads') : null}>
                                        <div className='dashboard-paper-title'>{type_date === 'created_at' ? 'TOTAL LEADS' : 'TOTAL APT'}</div>
                                        <div className='dashboard-paper-number' style={{ fontWeight: 'bold' }}>{op.new_leads.total_leads}</div>
                                    </Paper>

                                    <Paper className='dashboard-paper dashboard-paper-no-pointer' elevation={2}>
                                        <div className='dashboard-paper-title'>REVENUE</div>
                                        <div className='dashboard-paper-number' style={{ fontWeight: 'bold' }}><small>€</small> {op.new_leads.revenue}</div>
                                    </Paper>

                                    <Paper className='dashboard-paper dashboard-paper-no-pointer' elevation={2} style={{ border: (op.new_leads.pending_leads + op.new_leads.pending_apt > 0) ? '2px solid #f5a71c' : '2px solid #4fca00' }}>
                                        {((type_date === 'created_at') && op.new_leads.rate >= created_at_low_rate[0] && op.new_leads.rate <= created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && op.new_leads.rate >= created_at_medium_rate[0] && op.new_leads.rate <= created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && op.new_leads.rate >= created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_at' && op.new_leads.rate >= appointment_at_low_rate[0] && op.new_leads.rate <= appointment_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && op.new_leads.rate >= appointment_at_medium_rate[0] && op.new_leads.rate <= appointment_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && op.new_leads.rate >= appointment_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_created_at' && op.new_leads.rate >= appointment_created_at_low_rate[0] && op.new_leads.rate <= appointment_created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && op.new_leads.rate >= appointment_created_at_medium_rate[0] && op.new_leads.rate <= appointment_created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && op.new_leads.rate >= appointment_created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.new_leads.rate}%</div>
                                            </>
                                        }
                                    </Paper>

                                </Box>

                                <Box className='dashboard-papers'>

                                    <Paper elevation={2} style={{ backgroundColor: '#1a73e8', color: '#fff', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => op.rmkt.negative_leads ? handleClickOpenDashboardDetail(op.id, 'negative_leads', 'rmkt') : null}>
                                        <div style={{ fontSize: '0.8em', transform: 'rotate(-90deg)', whiteSpace: 'nowrap' }}><strong>RMKT</strong></div>
                                    </Paper>

                                    {type_date === 'created_at' ? (

                                        <>
                                            <Paper className={`dashboard-paper${op.rmkt.pending_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.rmkt.pending_leads ? handleClickOpenDashboardDetail(op.id, 'pending_leads', 'rmkt') : null}>
                                                <div className='dashboard-paper-title' style={{ color: '#f5a71c' }}>PENDING LEADS</div>
                                                <div className='dashboard-paper-number' style={{ color: '#f5a71c', fontWeight: 'bold' }}>{op.rmkt.pending_leads}</div>
                                            </Paper>

                                            <Paper className={`dashboard-paper${op.rmkt.negative_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.rmkt.negative_leads ? handleClickOpenDashboardDetail(op.id, 'negative_leads', 'rmkt') : null}>
                                                <div className='dashboard-paper-title' style={{ color: '#e80000' }}>NEGATIVE LEADS</div>
                                                <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{op.rmkt.negative_leads}</div>
                                            </Paper>
                                        </>

                                    ) : (

                                        <>
                                            <Paper className="dashboard-paper dashboard-paper-no-pointer" elevation={2}></Paper>
                                            <Paper className="dashboard-paper dashboard-paper-no-pointer" elevation={2}></Paper>
                                        </>

                                    )}

                                    <Paper className={`dashboard-paper${op.rmkt.pending_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.rmkt.pending_apt ? handleClickOpenDashboardDetail(op.id, 'pending_apt', 'rmkt') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#f5a71c' }}>PENDING APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#f5a71c', fontWeight: 'bold' }}>{op.rmkt.pending_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${op.rmkt.negative_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.rmkt.negative_apt ? handleClickOpenDashboardDetail(op.id, 'negative_apt', 'rmkt') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#e80000' }}>NEGATIVE APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{op.rmkt.negative_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${op.rmkt.disputed_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.rmkt.disputed_apt ? handleClickOpenDashboardDetail(op.id, 'disputed_apt', 'rmkt') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#e80000' }}>DISPUTED APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{op.rmkt.disputed_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${op.rmkt.positive_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.rmkt.positive_apt ? handleClickOpenDashboardDetail(op.id, 'positive_apt', 'rmkt') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#4fca00' }}>POSITIVE APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#4fca00', fontWeight: 'bold' }}>{op.rmkt.positive_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${op.rmkt.total_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => op.rmkt.total_leads ? handleClickOpenDashboardDetail(op.id, 'total_leads', 'rmkt') : null}>
                                        <div className='dashboard-paper-title'>{type_date === 'created_at' ? 'TOTAL LEADS' : 'TOTAL APT'}</div>
                                        <div className='dashboard-paper-number' style={{ fontWeight: 'bold' }}>{op.rmkt.total_leads}</div>
                                    </Paper>

                                    <Paper className='dashboard-paper dashboard-paper-no-pointer' elevation={2}>
                                        <div className='dashboard-paper-title'>REVENUE</div>
                                        <div className='dashboard-paper-number' style={{ fontWeight: 'bold' }}><small>€</small> {op.rmkt.revenue}</div>
                                    </Paper>

                                    <Paper className='dashboard-paper dashboard-paper-no-pointer' elevation={2} style={{ border: (op.rmkt.pending_leads + op.rmkt.pending_apt > 0) ? '2px solid #f5a71c' : '2px solid #4fca00' }}>
                                        {((type_date === 'created_at') && op.rmkt.rate >= created_at_low_rate[0] && op.rmkt.rate <= created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && op.rmkt.rate >= created_at_medium_rate[0] && op.rmkt.rate <= created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && op.rmkt.rate >= created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_at' && op.rmkt.rate >= appointment_at_low_rate[0] && op.rmkt.rate <= appointment_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && op.rmkt.rate >= appointment_at_medium_rate[0] && op.rmkt.rate <= appointment_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && op.rmkt.rate >= appointment_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_created_at' && op.rmkt.rate >= appointment_created_at_low_rate[0] && op.rmkt.rate <= appointment_created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && op.rmkt.rate >= appointment_created_at_medium_rate[0] && op.rmkt.rate <= appointment_created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && op.rmkt.rate >= appointment_created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.rmkt.rate}%</div>
                                            </>
                                        }
                                    </Paper>

                                </Box>

                            </div>

                            <div className='col-1 d-flex align-items-stretch' style={{ padding: 0 }}>
                                <Box className='dashboard-papers' sx={{ padding: '8px 0' }}>
                                    <Paper className='dashboard-paper-total-average' elevation={2} style={{ border: (op.new_leads.pending_leads + op.new_leads.pending_apt > 0) ? '2px solid #f5a71c' : '2px solid #4fca00' }}>
                                        {((type_date === 'created_at') && op.sum_rate >= created_at_low_rate[0] && op.sum_rate <= created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.sum_rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && op.sum_rate >= created_at_medium_rate[0] && op.sum_rate <= created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.sum_rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && op.sum_rate >= created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.sum_rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_at' && op.sum_rate >= appointment_at_low_rate[0] && op.sum_rate <= appointment_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.sum_rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && op.sum_rate >= appointment_at_medium_rate[0] && op.sum_rate <= appointment_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.sum_rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && op.sum_rate >= appointment_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.sum_rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_created_at' && op.sum_rate >= appointment_created_at_low_rate[0] && op.sum_rate <= appointment_created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{op.sum_rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && op.sum_rate >= appointment_created_at_medium_rate[0] && op.sum_rate <= appointment_created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{op.sum_rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && op.sum_rate >= appointment_created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{op.sum_rate}%</div>
                                            </>
                                        }
                                    </Paper>
                                </Box>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

        )

    }) : [];

    const totals = () => {

        const { new_leads, rmkt, sum_rate } = dashboardOp.totals || {};

        return (

            <div className='dashboard-papers-total mt-4'>

                <Accordion>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Stack spacing={1} direction="row" style={{ margin: '0 auto' }}>
                            <Badge color="secondary" badgeContent={0}>
                                <Button disabled variant="contained" style={{ backgroundColor: '#1a73e8', color: 'white', fontSize: 15, marginRight: 10 }}>Totals</Button>
                                {((type_date === 'created_at') && sum_rate >= created_at_low_rate[0] && sum_rate <= created_at_low_rate[1]) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{sum_rate}%</div>
                                    </>
                                }
                                {((type_date === 'created_at') && sum_rate >= created_at_medium_rate[0] && sum_rate <= created_at_medium_rate[1]) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{sum_rate}%</div>
                                    </>
                                }
                                {((type_date === 'created_at') && sum_rate >= created_at_high_rate) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{sum_rate}%</div>
                                    </>
                                }

                                {(type_date === 'appointment_at' && sum_rate >= appointment_at_low_rate[0] && sum_rate <= appointment_at_low_rate[1]) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{sum_rate}%</div>
                                    </>
                                }
                                {(type_date === 'appointment_at' && sum_rate >= appointment_at_medium_rate[0] && sum_rate <= appointment_at_medium_rate[1]) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{sum_rate}%</div>
                                    </>
                                }
                                {(type_date === 'appointment_at' && sum_rate >= appointment_at_high_rate) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{sum_rate}%</div>
                                    </>
                                }

                                {(type_date === 'appointment_created_at' && sum_rate >= appointment_created_at_low_rate[0] && sum_rate <= appointment_created_at_low_rate[1]) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{sum_rate}%</div>
                                    </>
                                }
                                {(type_date === 'appointment_created_at' && sum_rate >= appointment_created_at_medium_rate[0] && sum_rate <= appointment_created_at_medium_rate[1]) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{sum_rate}%</div>
                                    </>
                                }
                                {(type_date === 'appointment_created_at' && sum_rate >= appointment_created_at_high_rate) &&
                                    <>
                                        <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{sum_rate}%</div>
                                    </>
                                }
                            </Badge>
                        </Stack>
                    </AccordionSummary>

                    <AccordionDetails>

                        <div className='row align-items-stretch'>

                            <div className='col-11 d-flex flex-column' style={{ paddingRight: 0 }}>

                                <Box className='dashboard-papers'>

                                    <Paper elevation={2} style={{ backgroundColor: '#1a73e8', color: '#fff', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ fontSize: '0.8em', transform: 'rotate(-90deg)', whiteSpace: 'nowrap' }}><strong>NEW LEADS</strong></div>
                                    </Paper>

                                    {type_date === 'created_at' ? (

                                        <>
                                            <Paper className={`dashboard-paper ${new_leads?.pending_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => new_leads?.pending_leads > 0 ? handleClickOpenDashboardDetail(null, 'pending_leads', 'new_leads') : null}>
                                                <div className='dashboard-paper-title' style={{ color: '#f5a71c' }}>PENDING LEADS</div>
                                                <div className='dashboard-paper-number' style={{ color: '#f5a71c', fontWeight: 'bold' }}>{new_leads?.pending_leads}</div>
                                            </Paper>

                                            <Paper className={`dashboard-paper ${new_leads?.negative_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => new_leads?.negative_leads > 0 ? handleClickOpenDashboardDetail(null, 'negative_leads', 'new_leads') : null}>
                                                <div className='dashboard-paper-title' style={{ color: '#e80000' }}>NEGATIVE LEADS</div>
                                                <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{new_leads?.negative_leads}</div>
                                            </Paper>
                                        </>

                                    ) : (

                                        <>
                                            <Paper className="dashboard-paper dashboard-paper-no-pointer" elevation={2}></Paper>
                                            <Paper className="dashboard-paper dashboard-paper-no-pointer" elevation={2}></Paper>
                                        </>

                                    )}

                                    <Paper className={`dashboard-paper ${new_leads?.pending_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => new_leads?.pending_apt > 0 ? handleClickOpenDashboardDetail(null, 'pending_apt', 'new_leads') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#f5a71c' }}>PENDING APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#f5a71c', fontWeight: 'bold' }}>{new_leads?.pending_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper ${new_leads?.negative_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => new_leads?.negative_apt > 0 ? handleClickOpenDashboardDetail(null, 'negative_apt', 'new_leads') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#e80000' }}>NEGATIVE APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{new_leads?.negative_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper ${new_leads?.disputed_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => new_leads?.disputed_apt > 0 ? handleClickOpenDashboardDetail(null, 'disputed_apt', 'new_leads') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#e80000' }}>DISPUTED APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{new_leads?.disputed_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper ${new_leads?.positive_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => new_leads?.positive_apt > 0 ? handleClickOpenDashboardDetail(null, 'positive_apt', 'new_leads') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#4fca00' }}>POSITIVE APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#4fca00', fontWeight: 'bold' }}>{new_leads?.positive_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper ${new_leads?.total_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => new_leads?.total_leads > 0 ? handleClickOpenDashboardDetail(null, 'total_leads', 'new_leads') : null}>
                                        <div className='dashboard-paper-title'>{type_date === 'created_at' ? 'TOTAL LEADS' : 'TOTAL APT'}</div>
                                        <div className='dashboard-paper-number' style={{ fontWeight: 'bold' }}>{new_leads?.total_leads}</div>
                                    </Paper>

                                    <Paper className='dashboard-paper dashboard-paper-no-pointer' elevation={2}>
                                        <div className='dashboard-paper-title'>REVENUE</div>
                                        <div className='dashboard-paper-number' style={{ fontWeight: 'bold' }}><small>€</small> {new_leads?.revenue}</div>
                                    </Paper>

                                    <Paper className='dashboard-paper dashboard-paper-no-pointer' elevation={2} style={{ border: (new_leads?.pending_leads + new_leads?.pending_apt > 0) ? '2px solid #f5a71c' : '2px solid #4fca00' }}>
                                        {((type_date === 'created_at') && new_leads?.rate >= created_at_low_rate[0] && new_leads?.rate <= created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{new_leads?.rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && new_leads?.rate >= created_at_medium_rate[0] && new_leads?.rate <= created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{new_leads?.rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && new_leads?.rate >= created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{new_leads?.rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_at' && new_leads?.rate >= appointment_at_low_rate[0] && new_leads?.rate <= appointment_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{new_leads?.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && new_leads?.rate >= appointment_at_medium_rate[0] && new_leads?.rate <= appointment_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{new_leads?.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && new_leads?.rate >= appointment_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{new_leads?.rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_created_at' && new_leads?.rate >= appointment_created_at_low_rate[0] && new_leads?.rate <= appointment_created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{new_leads?.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && new_leads?.rate >= appointment_created_at_medium_rate[0] && new_leads?.rate <= appointment_created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{new_leads?.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && new_leads?.rate >= appointment_created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{new_leads?.rate}%</div>
                                            </>
                                        }
                                    </Paper>

                                </Box>

                                <Box className='dashboard-papers'>

                                    <Paper elevation={2} style={{ backgroundColor: '#1a73e8', color: '#fff', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ fontSize: '0.8em', transform: 'rotate(-90deg)', whiteSpace: 'nowrap' }}><strong>RMKT</strong></div>
                                    </Paper>

                                    {type_date === 'created_at' ? (

                                        <>
                                            <Paper className={`dashboard-paper${rmkt?.pending_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => rmkt?.pending_leads > 0 ? handleClickOpenDashboardDetail(null, 'pending_leads', 'rmkt') : null}>
                                                <div className='dashboard-paper-title' style={{ color: '#f5a71c' }}>PENDING LEADS</div>
                                                <div className='dashboard-paper-number' style={{ color: '#f5a71c', fontWeight: 'bold' }}>{rmkt?.pending_leads}</div>
                                            </Paper>

                                            <Paper className={`dashboard-paper${rmkt?.negative_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => rmkt?.negative_leads > 0 ? handleClickOpenDashboardDetail(null, 'negative_leads', 'rmkt') : null}>
                                                <div className='dashboard-paper-title' style={{ color: '#e80000' }}>NEGATIVE LEADS</div>
                                                <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{rmkt?.negative_leads}</div>
                                            </Paper>
                                        </>

                                    ) : (

                                        <>
                                            <Paper className="dashboard-paper dashboard-paper-no-pointer" elevation={2}></Paper>
                                            <Paper className="dashboard-paper dashboard-paper-no-pointer" elevation={2}></Paper>
                                        </>

                                    )}

                                    <Paper className={`dashboard-paper${rmkt?.pending_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => rmkt?.pending_apt > 0 ? handleClickOpenDashboardDetail(null, 'pending_apt', 'rmkt') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#f5a71c' }}>PENDING APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#f5a71c', fontWeight: 'bold' }}>{rmkt?.pending_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${rmkt?.negative_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => rmkt?.negative_apt > 0 ? handleClickOpenDashboardDetail(null, 'negative_apt', 'rmkt') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#e80000' }}>NEGATIVE APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{rmkt?.negative_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${rmkt?.disputed_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => rmkt?.disputed_apt > 0 ? handleClickOpenDashboardDetail(null, 'disputed_apt', 'rmkt') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#e80000' }}>DISPUTED APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#e80000', fontWeight: 'bold' }}>{rmkt?.disputed_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${rmkt?.positive_apt === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => rmkt?.positive_apt > 0 ? handleClickOpenDashboardDetail(null, 'positive_apt', 'rmkt') : null}>
                                        <div className='dashboard-paper-title' style={{ color: '#4fca00' }}>POSITIVE APT</div>
                                        <div className='dashboard-paper-number' style={{ color: '#4fca00', fontWeight: 'bold' }}>{rmkt?.positive_apt}</div>
                                    </Paper>

                                    <Paper className={`dashboard-paper${rmkt?.total_leads === 0 ? ' dashboard-paper-no-pointer' : ''}`} elevation={2} onClick={() => rmkt?.total_leads > 0 ? handleClickOpenDashboardDetail(null, 'total_leads', 'rmkt') : null}>
                                        <div className='dashboard-paper-title'>{type_date === 'created_at' ? 'TOTAL LEADS' : 'TOTAL APT'}</div>
                                        <div className='dashboard-paper-number' style={{ fontWeight: 'bold' }}>{rmkt?.total_leads}</div>
                                    </Paper>

                                    <Paper className='dashboard-paper dashboard-paper-no-pointer' elevation={2}>
                                        <div className='dashboard-paper-title'>REVENUE</div>
                                        <div className='dashboard-paper-number' style={{ fontWeight: 'bold' }}><small>€</small> {rmkt?.revenue}</div>
                                    </Paper>

                                    <Paper className='dashboard-paper dashboard-paper-no-pointer' elevation={2} style={{ border: (rmkt?.pending_leads + rmkt?.pending_apt > 0) ? '2px solid #f5a71c' : '2px solid #4fca00' }}>
                                        {((type_date === 'created_at') && rmkt?.rate >= created_at_low_rate[0] && rmkt?.rate <= created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{rmkt?.rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && rmkt?.rate >= created_at_medium_rate[0] && rmkt?.rate <= created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{rmkt?.rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && rmkt?.rate >= created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{rmkt?.rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_at' && rmkt?.rate >= appointment_at_low_rate[0] && rmkt?.rate <= appointment_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{rmkt?.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && rmkt?.rate >= appointment_at_medium_rate[0] && rmkt?.rate <= appointment_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{rmkt?.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && rmkt?.rate >= appointment_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{rmkt?.rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_created_at' && rmkt?.rate >= appointment_created_at_low_rate[0] && rmkt?.rate <= appointment_created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{rmkt?.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && rmkt?.rate >= appointment_created_at_medium_rate[0] && rmkt?.rate <= appointment_created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{rmkt?.rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && rmkt?.rate >= appointment_created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{rmkt?.rate}%</div>
                                            </>
                                        }
                                    </Paper>

                                </Box>
                            </div>

                            <div className='col-1 d-flex align-items-stretch' style={{ padding: 0 }}>
                                <Box className='dashboard-papers' sx={{ padding: '8px 0' }}>
                                    <Paper className='dashboard-paper-total-average' elevation={2} style={{ border: (new_leads?.pending_leads + new_leads?.pending_apt > 0) ? '2px solid #f5a71c' : '2px solid #4fca00' }}>
                                        {((type_date === 'created_at') && sum_rate >= created_at_low_rate[0] && sum_rate <= created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{sum_rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && sum_rate >= created_at_medium_rate[0] && sum_rate <= created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{sum_rate}%</div>
                                            </>
                                        }
                                        {((type_date === 'created_at') && sum_rate >= created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{sum_rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_at' && sum_rate >= appointment_at_low_rate[0] && sum_rate <= appointment_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{sum_rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && sum_rate >= appointment_at_medium_rate[0] && sum_rate <= appointment_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{sum_rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_at' && sum_rate >= appointment_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{sum_rate}%</div>
                                            </>
                                        }

                                        {(type_date === 'appointment_created_at' && sum_rate >= appointment_created_at_low_rate[0] && sum_rate <= appointment_created_at_low_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#e80000' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#e80000' }}>{sum_rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && sum_rate >= appointment_created_at_medium_rate[0] && sum_rate <= appointment_created_at_medium_rate[1]) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#f5a71c' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#f5a71c' }}>{sum_rate}%</div>
                                            </>
                                        }
                                        {(type_date === 'appointment_created_at' && sum_rate >= appointment_created_at_high_rate) &&
                                            <>
                                                <div className='dashboard-paper-avarage-title' style={{ color: '#4fca00' }}>TOTAL RATE</div>
                                                <div className='dashboard-paper-avarage-number' style={{ color: '#4fca00' }}>{sum_rate}%</div>
                                            </>
                                        }
                                    </Paper>
                                </Box>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>

        )

    }

    return (
        <div key="0" className='row' style={{ marginBottom: 50 }}>
            <DashboardSearch />
            <DashboardDetail />
            <div className='row'>
                {totals()}
                {papers}
            </div>
        </div>
    )

}

export default DashboardBox