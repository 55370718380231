import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { copyToClipboard } from '../../helpers.js'
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { setCurrentModal, setCurrentTabEditLead } from '../../redux/slices/modalSlice.js';
import { setCurrentLeadId } from '../../redux/slices/managerSlice.js';
import { setDisableLeadHistoryLead } from '../../redux/slices/historyLeadSlice.js';
import { setDisableLeadDialer, updateDialerDisableLead } from '../../redux/slices/dialerSlice.js';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';
import BadgeCustomerJoy from './BadgeCustomerJoy.js';

const DialerHistoryLead = () => {

  const dispatch = useDispatch();
  const historyLead = useSelector((state) => state.historyLead.data);
  const logCustomerJoyTokens = useSelector((state) => state.logCustomerJoyToken.data.data);

  const handleClickLeadEdit = async (id) => {
    dispatch(setCurrentModal('modalLeadEdit'))
    dispatch(setCurrentLeadId(id))
    setTimeout(() => {
      dispatch(setCurrentTabEditLead(0));
    }, 2000);
    dispatch(
      storeLogAction({
        type: 'manager',
        section: 'manager/dialer/historyLead/editLead',
        value: id,
        input_type: 'button'
      })
    );
  }

  const handleDisabled = (event, id) => {
    const leadId = id;
    const dataitemIndex = historyLead.findIndex(x => x.id === id);
    dispatch(setDisableLeadHistoryLead(dataitemIndex))
    dispatch(setDisableLeadDialer(leadId))
    dispatch(updateDialerDisableLead(dataitemIndex));
    dispatch(
      storeLogAction({
        type: 'manager',
        section: 'manager/historyLead/disableLead',
        value: id,
        input_type: 'button'
      })
    );
  }

  const list = Array.isArray(historyLead) ? historyLead?.map((leads) => {

    const { id, created_at, op_assigned, source, customer_name, customer_phone, lead_status_1, lead_status_2, lead_status_3, lead_status_4, appointment_at, calendar_at, disabled, rmkt, shop, shop_info, log_notes_by_lead_id, user_action, user_answer } = leads;

    const isCustomerJoy = logCustomerJoyTokens && logCustomerJoyTokens.some(token => token.lead_id === id);

    return (
      <tr key={id}>
        <td>

          <BadgeCustomerJoy isCustomerJoy={isCustomerJoy} user_action={user_action} user_answer={user_answer} lead_status_1={lead_status_1} />

          <IconButton
            variant="text"
            sx={{
              ml: 1,
              borderRadius: "4px",
              bgcolor: 'primary.main',
              color: 'white',
              ':hover': {
                bgcolor: 'secondary.main',
                color: 'white',
              },
            }}
            edge="start" color="inherit" aria-label="close" onClick={() => handleClickLeadEdit(id)}>
            <EditIcon />
          </IconButton>

          {log_notes_by_lead_id && log_notes_by_lead_id.length > 0 &&
            <HtmlTooltip
              placement="right"
              title={
                <>
                  {log_notes_by_lead_id.map((singleNote, index) => {
                    return (
                      <ul key={index} className="list-unstyled">
                        <li><strong>{singleNote.created_at}</strong> (<strong>OP{singleNote.op_logged}</strong>) {singleNote.note}</li>
                      </ul>
                    );
                  })
                  }
                </>
              }
            >

              <IconButton
                variant="text"
                sx={{
                  ml: 1,
                  borderRadius: "4px",
                  bgcolor: 'primary.main',
                  color: 'white',
                  ':hover': {
                    bgcolor: 'secondary.main',
                    color: 'white',
                  },
                }}
                edge="start" color="inherit" aria-label="close">
                <Badge
                  badgeContent={log_notes_by_lead_id.length} color="secondary">
                  <CommentIcon />
                </Badge>
              </IconButton>
            </HtmlTooltip>
          }

        </td>

        <td>
          {(lead_status_1?.id === 1 || lead_status_1?.id === 45) &&
            <Switch
              checked={disabled}
              onChange={(event) => handleDisabled(event, id)}
              value={disabled}
              inputProps={{ 'aria-label': 'controlled' }}
              name="disabled"
            />
          }
        </td>
        <td>
          {rmkt > 0 &&
            <CheckIcon />
          }
        </td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(source) }}>{source}</td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(id) }}>{id}</td>
        <td>{op_assigned}</td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_name) }}>{customer_name}</td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_phone) }}>{customer_phone}</td>
        <td>
          <ul className='list-unstyled m-0'>
            <li>{lead_status_1?.name}</li>
            {lead_status_2 &&
              <li>{lead_status_2.name}</li>
            }
            {lead_status_3 &&
              <li>{lead_status_3.name}</li>
            }
            {lead_status_4 &&
              <li>{lead_status_4.name}</li>
            }
          </ul>
        </td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(shop?.business_name + "\n" + shop_info?.shop_address + "\n" + shop_info?.shop_province) }}>{shop?.business_name} <br />{shop_info?.shop_address} {shop_info?.shop_province}</td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(created_at) }}>{created_at}</td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(appointment_at) }}>{appointment_at}</td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(calendar_at) }}>{calendar_at}</td>
      </tr >
    )
  }) : <tr>
    <td colSpan="13" className="text-center">Search not found</td>
  </tr>

  return (
    <div className="table-responsive datatable-custom table-fix-head card" id="table-dialer">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle" id="table-lead-history">
          <thead>
            <tr>
              <th colSpan="13" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">HISTORY LEAD LIST</h5>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>DISABLED</th>
              <th>RMKT</th>
              <th>SOURCE</th>
              <th>ID</th>
              <th>OP ASSIGNED</th>
              <th>NAME/SURNAME</th>
              <th>PHONE</th>
              <th>STATUS</th>
              <th>SHOP</th>
              <th>CREATED AT</th>
              <th>APPOINTMENT AT</th>
              <th>CALENDAR AT</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {list}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 800,
    width: 800,
    height: 400,
    overflowY: 'scroll',
    fontSize: '1em',
    fontWeight: 'normal',
    border: '1px solid #dadde9',
  },
}));

export default DialerHistoryLead