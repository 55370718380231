import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoadingSmall } from './managerSlice';

const initialParams = {
    page: "",
    op_id: "",
    op_name: "",
    created_at_from: "",
    created_at_to: "",
    type: "",
    value: "",
}

const logActionsSearchSlice = createSlice({
    name: 'logActionsSearch',
    initialState: {
        data: [],
        params: initialParams,
        isDrawerOpen: false,
        refreshLogActionsSearch: false,
        isError: false,
        valueObject: {}
    },
    reducers: {
        setLogActionsSearchParams: (state, action) => {
            state.params = action.payload;
        },
        setResetLogActionsSearchParams: (state) => {
            state.params = initialParams; 
        },
        setQueryPage: (state, action) => {
            state.params.page = action.payload;
        },
        setRefreshLogActionsSearch: (state, action) => {
            state.refreshLogActionsSearch = action.payload;
        },
        setIsDrawerOpen: (state, action) => {
            state.isDrawerOpen = action.payload;
        },
        setValueObject: (state, action) => {
            state.valueObject = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogActionsSearch.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchLogActionsSearch.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchLogActionsSearch.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setLogActionsSearchParams, setQueryPage, setRefreshLogActionsSearch, setResetLogActionsSearchParams, setIsDrawerOpen, setValueObject } = logActionsSearchSlice.actions;

export const fetchLogActionsSearch = createAsyncThunk('fetch/logActionsSearch', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoadingSmall(true));

        const state = getState();
        const token = state.auth.token;
        const { page, op_id, created_at_from, created_at_to, type, value } = state.logActionsSearch.params;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/log-actions', {
            params: {
                page,
                op_logged: op_id,
                created_at_from,
                created_at_to,
                type,
                value
            }
        });

        if (response.status === 200) {
            
            dispatch(setIsLoadingSmall(false));
            return response.data;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default logActionsSearchSlice.reducer;
