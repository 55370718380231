import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';

const initialParams = {
    page: 1,
};

const logCustomerJoyTokenSlice = createSlice({
    name: 'logCustomerJoyToken',
    initialState: {
        data: [],
        params: initialParams,
        refreshLogCustomerJoyToken: false,
        isError: false
    },
    reducers: {
        setLogCustomerJoyToken: (state, action) => {
            state.data = action.payload;
        },
        setQueryPageCustomerJoyToken: (state, action) => {
            state.params.page = action.payload;
        },
        setRefreshLogCustomerJoyToken: (state, action) => {
            state.refreshLogCustomerJoyToken = action.payload;
        },
        setResetCustomerJoyTokenParams: (state) => {
            state.params = initialParams;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogCustomerJoyToken.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchLogCustomerJoyToken.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchLogCustomerJoyToken.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setLogCustomerJoyToken, setQueryPageCustomerJoyToken, setRefreshLogCustomerJoyToken, setResetCustomerJoyTokenParams } = logCustomerJoyTokenSlice.actions;

export const fetchLogCustomerJoyToken = createAsyncThunk('fetch/customerJoy/token', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true));

        const state = getState();
        const token = state.auth.token;
        const { page, lead_id, source, customer_name, customer_phone, status1_id } = state.logCustomerJoyUser.params;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/log-customer-joy-tokens', {
            params: {
                page,
                lead_id,
                source,
                customer_name,
                customer_phone,
                status1_id
            }
        });

        if (response.status === 200) {

            dispatch(setIsLoading(false));
            return response.data;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export const deleteCustomerJoyToken = createAsyncThunk('delete/customerJoy/token', async (id, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;

        const response = await instance.delete(`/log-customer-joy-tokens/destroy/${id}`);

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {
        console.error('Error getting data:', error);
        throw error;
    }

});

export default logCustomerJoyTokenSlice.reducer;
