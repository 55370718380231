import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import BasicTabs from '../../components/Modals/LeadEdit/BasicTabs';
import { setCurrentModal, setCurrentTabEditLead } from '../../redux/slices/modalSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';
import { setCountdownSeconds } from '../../redux/slices/managerSlice';
import { setNoteForm } from '../../redux/slices/actionBarTwoSlice';
import { setCustomerJoyToken } from '../../redux/slices/customerJoyTokenSlice';

const ModalLeadEdit = () => {

    const dispatch = useDispatch();
    const { currentModal } = useSelector((state) => state.modal);
    const dialer = useSelector((state) => state.dialer.data);

    const handleClose = () => {
        dispatch(setCustomerJoyToken({
            token: "",
            success: false,
        }));
        dispatch(setNoteForm(""));
        dispatch(setCurrentModal(''));
        dispatch(setCountdownSeconds(30));
        dispatch(setCurrentTabEditLead(0));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    };

    return (
        <Dialog fullScreen open={true} onClose={handleClose}>
            <BasicTabs handleClose={handleClose}></BasicTabs>
        </Dialog>
    );
}

export default ModalLeadEdit