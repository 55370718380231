import axios from 'axios';

let baseURL;

switch (window.location.hostname) {

    case "localhost":

        baseURL = 'https://api-customer-care-manager.test/api/';

        break;

    default:

        baseURL = 'https://api.customercaremanager.it/api/';

        break;

}

const instance = axios.create({

    baseURL,

    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },

});

export default instance;