import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { setCurrentModal } from '../redux/slices/modalSlice';
import { setIsLogged, setToken } from '../redux/slices/authSlice';
import { storeLogAction } from '../redux/slices/logActionStoreSlice';
import Tooltip from '@mui/material/Tooltip';
import CommentIcon from '@mui/icons-material/Comment';
import AddIcon from '@mui/icons-material/Add';

const NavbarRight = () => {

    const dispatch = useDispatch();
    const { loggedName, loggedEmail } = useSelector((state) => state.auth);
    const { currentModal } = useSelector((state) => state.modal);

    // MENU ACTIONS
    const [anchorElSettingsMenu, setAnchorElSettingsMenu] = useState(null);
    const openSettingsMenu = Boolean(anchorElSettingsMenu);

    // MENU OPEN SETTINGS
    const handleClickSettingsMenu = (event) => {
        setAnchorElSettingsMenu(event.currentTarget);
    };
    const handleCloseSettingsMenu = () => {
        setAnchorElSettingsMenu(null);
    };

    const handleClickModal = (value) => {
        dispatch(setCurrentModal(value));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'navbarRight/' + value,
                value: null,
                input_type: 'button'
            })
        );
    }

    const handleClickLogout = () => {
        dispatch(setIsLogged(false));
        dispatch(setToken(''));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'navbarRight/logout',
                value: null,
                input_type: 'button'
            })
        );
    }

    return (
        <>
            {(loggedEmail !== "amministrazione@specialistidelludito.it" && loggedEmail !== "logistica@specialistidelludito.it") &&
                <Tooltip title="Work To Do" arrow>
                    <IconButton
                        variant="text"
                        sx={buttonDialerNavbarPrimaryStyles}
                        edge="start" color="inherit" aria-label="close" onClick={() => handleClickModal('modalWorkToDo')}>
                        <DashboardIcon fontSize="small" />
                    </IconButton>
                </Tooltip>

            }

            {(loggedEmail !== "amministrazione@specialistidelludito.it") &&
                <Tooltip title="Dashboard" arrow>
                    <IconButton
                        variant="text"
                        sx={buttonDialerNavbarPrimaryStyles}
                        edge="start" color="inherit" aria-label="close" onClick={() => handleClickModal('modalDashboardOp')}>
                        <MonitorHeartIcon fontSize="small" />
                    </IconButton>
                </Tooltip>

            }

            <Tooltip title="Leads" arrow>
                <IconButton
                    variant="text"
                    sx={buttonDialerNavbarPrimaryStyles}
                    edge="start" color="inherit" aria-label="close" onClick={() => handleClickModal('modalLeadSearch')}>
                    <SearchIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            <Tooltip title="Log Notes" arrow>
                <IconButton
                    variant="text"
                    sx={buttonDialerNavbarPrimaryStyles}
                    edge="start" color="inherit" aria-label="close" onClick={() => handleClickModal('modalLogNotesSearch')}>
                    <CommentIcon sx={{ width: 20 }} />
                </IconButton>
            </Tooltip>

            <Tooltip title="Add Lead" arrow>
                <IconButton
                    variant="text"
                    sx={buttonDialerNavbarPrimaryStyles}
                    edge="start" color="inherit" aria-label="close" onClick={() => handleClickModal('modalAddLead')}>
                    <AddIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Settings and Logs" arrow>
                <IconButton
                    sx={buttonDialerNavbarPrimaryStyles}
                    aria-label="more"
                    id="long-button-settings"
                    aria-controls={openSettingsMenu ? 'long-menu-settings' : undefined}
                    aria-expanded={openSettingsMenu ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClickSettingsMenu}
                >
                    <SettingsIcon fontSize="small" />
                </IconButton>
            </Tooltip>


            <Menu
                id="long-menu-settings"
                MenuListProps={{
                    'aria-labelledby': 'long-button-settings',
                }}
                anchorEl={anchorElSettingsMenu}
                anchorOrigin={{ vertical: "bottom", horizontal: 0 }}
                transformOrigin={{ vertical: "top", horizontal: 125 }}
                open={openSettingsMenu}
                onClose={handleCloseSettingsMenu}
                PaperProps={{
                    style: {
                        maxHeight: 1000,
                        width: '20ch',
                        paddingTop: 0
                    },
                }}
            >
                <MenuItem disabled>{loggedName}</MenuItem>
                {loggedName && loggedName.includes('Operatore') &&
                    <div>
                        <MenuItem onClick={() => handleClickModal('modalAssigns')}>Assigns</MenuItem>
                        <MenuItem onClick={() => handleClickModal('modalTransfers')}>Transfers</MenuItem>
                    </div>
                }
                <MenuItem onClick={() => handleClickModal('modalRemarketingByLeads')}>Remarketing</MenuItem>
                <MenuItem onClick={() => handleClickModal('modalLogCustomerJoy')}>Log Customer Joy</MenuItem>
                <MenuItem onClick={() => handleClickModal('modalLogActionsSearch')}>Log Actions</MenuItem>
                {loggedEmail && loggedEmail !== "amministrazione@specialistidelludito.it" &&
                    <MenuItem onClick={() => handleClickModal('modalLogReleases')}>Log Releases</MenuItem>
                }
                <MenuItem onClick={() => handleClickLogout()}>Logout</MenuItem>
            </Menu>

        </>
    )

}

const buttonDialerNavbarPrimaryStyles = {
    backgroundColor: 'primary.main',
    color: 'white',
    borderRadius: '4px',
    marginLeft: '10px',
    border: '1px solid #1a73e8',
    ':hover': {
        bgcolor: 'secondary.main',
        color: 'white',
    },
}

export default NavbarRight