import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/material';
import { setDialer } from '../../../../../redux/slices/dialerSlice';
import { storeLogAction } from '../../../../../redux/slices/logActionStoreSlice';

const SourcesDropdown = () => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const sources = useSelector((state) => state.sources.data);
    const [sourceUnlocked, setSourceUnlocked] = useState(true);
    const { currentModal } = useSelector((state) => state.modal);

    const handleOnClickUnlock = () => {
        setSourceUnlocked(false)
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/shopAssociate/Source/UnlockSource',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            {sourceUnlocked &&
                <Button onClick={() => handleOnClickUnlock()} variant="contained" style={{ position: 'absolute', zIndex: 10, bottom: 22, right: 40, fontSize: 10 }}>UNLOCK</Button>
            }
            <Autocomplete
                disabled={sourceUnlocked}
                id="source"
                options={sources}
                getOptionLabel={(option) => option.source || dialer.lead_sources?.source}
                defaultValue={dialer.lead_sources?.source ?? null}
                value={dialer.lead_sources?.source ?? null}
                isOptionEqualToValue={(option, value) => option.source === value}
                onChange={(event, option) => {
                    if (option) {
                        dispatch(setDialer({ ...dialer, lead_sources: option }))
                    } else {
                        dispatch(setDialer({ ...dialer, lead_sources: [] }))
                    }
                }}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.source}
                        </li>
                    );
                }}
                renderInput={(params) => <TextField required margin="normal" {...params} label="Source" />} />
        </div>
    )
}

export default SourcesDropdown