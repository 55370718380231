import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import BackspaceIcon from '@mui/icons-material/Backspace';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid } from '@mui/material';
import NavbarRight from '../../components/NavbarRight';
import TableLogActions from '../../components/Modals/LogActions/TableLogActions';
import FormLogActionsSearch from '../../components/Modals/LogActions/FormLogActionsSearch';
import { setCurrentModal } from '../../redux/slices/modalSlice';
import { setQueryPage, setRefreshLogActionsSearch, setResetLogActionsSearchParams } from '../../redux/slices/logActionsSearchSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';
import { setCountdownSeconds } from '../../redux/slices/managerSlice';
import DrawerLogActions from './LogActions/DrawerLogActions';

const ModalLogActions = () => {

    const dispatch = useDispatch();
    const refreshLogActionsSearch = useSelector((state) => state.logActionsSearch.refreshLogActionsSearch);
    const { loggedEmail } = useSelector((state) => state.auth);
    const { currentModal } = useSelector((state) => state.modal);

    const handleClose = () => {
        dispatch(setCurrentModal(''));
        dispatch(setCountdownSeconds(30));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );

    };


    const handleClickResetLogActions = () => {
        dispatch(setResetLogActionsSearchParams())
        dispatch(setRefreshLogActionsSearch(!refreshLogActionsSearch))
        dispatch(setQueryPage(1))
    };

    const handleClickRefreshLogActions = () => {
        dispatch(setRefreshLogActionsSearch(!refreshLogActionsSearch))
        dispatch(setQueryPage(1))
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={true}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }} style={{ background: '#1a73e8', marginRight: 0 }}>

                    <Toolbar>

                        <Grid container spacing={0}>
                            <Grid item xs={10}>
                                {!loggedEmail.includes('@udibox.it') &&
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleClose}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }
                                <Badge color="primary" style={{ marginRight: 15 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Log Actions</Button>
                                </Badge>

                                <IconButton style={{ marginRight: 2 }}
                                    variant="text"
                                    edge="end" color="inherit" aria-label="close" onClick={handleClickResetLogActions}>
                                    <BackspaceIcon />
                                </IconButton>

                                <IconButton
                                    variant="text"
                                    edge="end" color="inherit" aria-label="close" onClick={handleClickRefreshLogActions}>
                                    <RefreshIcon />
                                </IconButton>

                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <NavbarRight />
                                </Box>
                            </Grid>
                        </Grid>

                    </Toolbar>
                </AppBar>

                <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                    <DrawerLogActions />
                    <div className='row mt-2'>
                        <div className='col-3 mt-2'>
                            <FormLogActionsSearch />
                        </div>
                        <div className='col-9 mt-2'>
                            <TableLogActions />
                        </div>
                    </div>
                </div>

            </Dialog>
        </div>
    );
}

export default ModalLogActions