import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import { setLeadSearchParams, setRefreshLeadSearch } from '../../../../redux/slices/leadSearchSlice';

const DefaultSearch = (props) => {

    const { handleKeyEnter } = props;

    const dispatch = useDispatch();
    const leadSearchParams = useSelector((state) => state.leadSearch.params);
    const { realtimeSearch, refreshLeadSearch } = useSelector((state) => state.leadSearch);

    return (
        <>
            <TextField
                sx={{ marginTop: 0 }}
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, lead_id: event.target.value }));
                    realtimeSearch && dispatch(setRefreshLeadSearch(!refreshLeadSearch));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="ID" id="lead_id" value={leadSearchParams.lead_id || ""}
            />

            <TextField
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, customer_name: event.target.value }));
                    (realtimeSearch && leadSearchParams.customer_name && leadSearchParams.customer_name.length > 5) &&
                        dispatch(setRefreshLeadSearch(!refreshLeadSearch));

                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Customer Name" id="customer_name" value={leadSearchParams.customer_name || ""}
            />

            <TextField
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, customer_phone: event.target.value }));
                    (realtimeSearch && leadSearchParams.customer_phone && leadSearchParams.customer_phone.length > 5) &&
                        dispatch(setRefreshLeadSearch(!refreshLeadSearch));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Customer Phone" id="customer_phone" value={leadSearchParams.customer_phone || ""}
            />

            <TextField
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, customer_zipcode: event.target.value }));
                    (realtimeSearch && leadSearchParams.customer_zipcode && leadSearchParams.customer_zipcode.length > 2) &&
                        dispatch(setRefreshLeadSearch(!refreshLeadSearch));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Customer Zipcode" id="customer_zipcode" value={leadSearchParams.customer_zipcode || ""}
            />

            <TextField
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, customer_email: event.target.value }));
                    (realtimeSearch && leadSearchParams.customer_email && leadSearchParams.customer_email.length > 5) &&
                        dispatch(setRefreshLeadSearch(!refreshLeadSearch));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Customer Email" id="customer_email" value={leadSearchParams.customer_email || ""}
            />

            <TextField
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, ecommerce_order_id: event.target.value }));
                    (realtimeSearch && leadSearchParams.ecommerce_order_id) &&
                        dispatch(setRefreshLeadSearch(!refreshLeadSearch));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Ecommerce Order ID" id="ecommerce_order_id" value={leadSearchParams.ecommerce_order_id || ""}
            />

            <TextField
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, ecommerce_order_note: event.target.value }));
                    (realtimeSearch && leadSearchParams.ecommerce_order_note) &&
                        dispatch(setRefreshLeadSearch(!refreshLeadSearch));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Ecommerce Order Note" id="ecommerce_order_id" value={leadSearchParams.ecommerce_order_note || ""}
            />

        </>
    )
}

export default DefaultSearch