import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setOpIdTo } from '../../../redux/slices/transfersSlices';

const OperatorsCheckboxTransfers = () => {
    const dispatch = useDispatch();
    const manager = useSelector((state) => state.manager.data);
    const operatorsSettings = manager.op_settings.map((setting) => setting);
    const { opIdFrom, opIdTo } = useSelector((state) => state.transfers.params);

    const handleReset = () => {
        // Esegui azioni di reset qui
        // Ad esempio, reimposta tutti i checkbox a uno stato predefinito (deselezionati)
        operatorsSettings.forEach((setting) => {
            if (opIdTo.includes(setting.id)) {
                dispatch(setOpIdTo(setting.id, false)); // Assumi che setOpIdTo accetti un secondo parametro per impostare il valore del checkbox
            }
        });
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup row>
                    {operatorsSettings.map((setting) => (
                        <FormControlLabel
                            key={setting.id}
                            control={
                                <Checkbox
                                    checked={opIdTo.includes(setting.id)}
                                    name={opIdFrom}
                                    style={{ color: setting.status ? 'green' : 'red' }}
                                    onChange={() => dispatch(setOpIdTo(setting.id))}
                                />
                            }
                            label={`OP${setting.id}`}
                        />
                    ))}
                </FormGroup>
                <Button variant="contained" color="primary" onClick={handleReset}>
                    Reset
                </Button>
            </div>
        </>
    );
};

export default OperatorsCheckboxTransfers;
