import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const italyDbByZipcodeSlice = createSlice({
    name: 'italyDbByZipcode',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItalyDbByZipcode.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchItalyDbByZipcode.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchItalyDbByZipcode.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

//export const { } = italyDbByZipcodeSlice.actions;

export const fetchItalyDbByZipcode = createAsyncThunk('fetch/italyDbByZipcode', async (_, { getState }) => {

    try {

        const state = getState();
        const customerZipcode = getState().dialer.data.customer_zipcode;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/italy-db/${customerZipcode}`);
        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default italyDbByZipcodeSlice.reducer;
