import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { storeLogAction } from '../../../../redux/slices/logActionStoreSlice';

const ActionsBarOne = (props) => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const { currentModal } = useSelector((state) => state.modal);
    const { handleGoToTabNote } = props;

    const saveButtons = () => {

        let component;

        // APPOINTMENT AT IS REQUIRED
        if ((dialer.lead_status_1?.id === 17 || dialer.lead_status_1?.id === 19) &&
            (dialer.appointment_at === null || dialer.appointment_at === undefined)) {
            component = <Button color="error" variant="contained">Save (Appointment At is required!)</Button>
        }

        else if ((dialer.lead_status_2?.id === 56) && (dialer.calendar_at === null || dialer.calendar_at === undefined)) {
            component = <Button color="error" variant="contained">Save (Calendar At is required!)</Button>
        }

        else {
            component = <>
                <Button type="submit" variant="contained" onClick={handleClickSave}>Save</Button>
                <Button onClick={(event) => handleGoToTabNote(event)} variant="outlined" endIcon={<ArrowForwardIcon />}>
                    Save and continue
                </Button>
            </>
        }
        return component;
    }

    const handleClickPyAsevolution = async (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(dialer.shop?.business_vat);
        try {
            await axios.get('http://127.0.0.1:5001/');
            dispatch(
                storeLogAction({
                    type: currentModal,
                    section: 'modal/actionBarOne/asEvolution',
                    value: JSON.stringify(dialer),
                    input_type: 'button'
                })
            );
        } catch (err) {
            console.log("As Evolution Error! " + err);
        }
    }

    const handleClickWhatsapp = () => {
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/actionBarOne/whatsApp',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    };

    const handleClickCall = () => {
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/actionBarOne/whatsApp',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    };

    const handleClickSave = () => {
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/actionBarOne/save',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    };

    return (
        <div className='fixed-bottom bg-white p-4 d-flex justify-content-center'>
            <Stack spacing={2} direction="row">
                {(dialer.shop?.business_vat && dialer.shop?.ilpas_schedule) &&
                    <Button variant="contained" onClick={(event) => handleClickPyAsevolution(event)}>AsEvolution</Button>
                }
                {(!dialer.shop?.business_vat && dialer.shop?.ilpas_schedule) &&
                    <Button type="reset" style={{ backgroundColor: '#ed4c78' }} variant="contained">AsEvolution Vat Number Missing</Button>
                }
                <Button href={`https://api.whatsapp.com/send?phone=39${dialer.customer_phone}`} onClick={handleClickWhatsapp} target="_blank" rel="noopener noreferrer" variant="contained">Whatsapp</Button>
                <Button href={`callto:${dialer.customer_phone}`} onClick={handleClickCall} variant="contained">Call</Button>
                {saveButtons()}
            </Stack>
        </div>
    )

}

export default ActionsBarOne