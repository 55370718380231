import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { copyToClipboard, copyTable } from '../../../helpers';
import { setOpenDashboardDetail, setResetDashboardOpDetailParams } from '../../../redux/slices/dashboardOpDetailSlice';

const DashboardDetail = () => {

    const dispatch = useDispatch();

    const dashboardOpDetail = useSelector((state) => state.dashboardOpDetail.data);
    const { date_from, date_to, type_date } = useSelector((state) => state.dashboardOp.params);
    const { openDashboardDetail } = useSelector((state) => state.dashboardOpDetail);
    const { op_master, type_box, type_leads } = useSelector((state) => state.dashboardOpDetail.params);

    const handleClickCloseDashboardDetail = () => {
        dispatch(setOpenDashboardDetail(false));
        dispatch(setResetDashboardOpDetailParams());
    };

    const convertSecondsToHourMinutesSeconds = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds / 60) % 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    const list = Array.isArray(dashboardOpDetail) ? dashboardOpDetail.map((leads) => {

        const { id, shop_id, shop_info_id, source, op_master, customer_name, customer_phone, lead_status_1, lead_status_2, lead_status_3, lead_status_4, appointment_at, appointment_created_at, calendar_at, created_at, updated_at, revenue, duration } = leads;

        return (
            <tr key={id}>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(id) }}>{id}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(source) }}>{source}</td>
                {op_master ? (
                    <td>{op_master}</td>
                ) : (
                    <td><span className='text-danger fw-bold'>MISSING!</span></td>
                )}
                <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_name) }}>{customer_name}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_phone) }}>{customer_phone}</td>
                <td>
                    <ul className='list-unstyled m-0'>
                        <li>{lead_status_1?.name}</li>
                        {lead_status_2 &&
                            <li>{lead_status_2?.name}</li>
                        }
                        {lead_status_3 &&
                            <li>{lead_status_3?.name}</li>
                        }
                        {lead_status_4 &&
                            <li>{lead_status_4?.name}</li>
                        }
                    </ul>
                </td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(appointment_at) }}>{appointment_at}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(appointment_created_at) }}>{appointment_created_at}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(leads.shop.business_name + " " + leads.shop_info.shop_address + " " + leads.shop_info.shop_province) }}>
                    {leads.shop &&
                        <ul className='list-unstyled m-0'>
                            <li>{leads.shop?.business_name}</li>
                            <li>{leads.shop_info?.shop_address}</li>
                            <li>{leads.shop_info?.shop_province}</li>
                        </ul>
                    }
                </td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_id) }}>{shop_id}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_info_id) }}>{shop_info_id}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(calendar_at) }}>{calendar_at}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(created_at) }}>{created_at}</td>
                {
                    type_box !== "worked_leads" && type_box !== "shop_calls" && type_box !== "total_calls" &&
                    <>
                        <td className='copy-clipboard' onClick={() => { copyToClipboard(updated_at) }}>{updated_at}</td>
                        <td className='copy-clipboard' onClick={() => { copyToClipboard(revenue) }}>{revenue > 0 ? <span><small>€</small> {revenue}</span> : null}</td>
                    </>
                }
                {
                    (type_box === "worked_leads" || type_box === "shop_calls" || type_box === "total_calls") &&
                    <td>{convertSecondsToHourMinutesSeconds(duration)}</td>
                }
            </tr >
        )
    }) : <div>No results found</div>


    return (
        <BootstrapDialog
            onClose={handleClickCloseDashboardDetail}
            open={openDashboardDetail}
            className='dashboard-dialog'
        >
            <BootstrapDialogTitle onClose={handleClickCloseDashboardDetail}>
                {op_master &&
                    <Badge color="primary" style={{ marginRight: 15 }}>
                        <Button disabled variant="contained" style={{ color: 'black' }}>OP MASTER {op_master}</Button>
                    </Badge>
                }
                <Badge color="primary" style={{ marginRight: 15 }}>
                    <Button disabled variant="contained" style={{ color: 'black' }}>{dayjs(date_from).format('DD/MM/YYYY')}</Button>
                </Badge>
                <Badge color="primary" style={{ marginRight: 15 }}>
                    <Button disabled variant="contained" style={{ color: 'black' }}>{dayjs(date_to).format('DD/MM/YYYY')}</Button>
                </Badge>
                <Badge color="primary" style={{ marginRight: 15 }}>
                    <Button disabled variant="contained" style={{ color: 'black' }}>{type_box}</Button>
                </Badge>
                <Badge color="primary" style={{ marginRight: 15 }}>
                    <Button disabled variant="contained" style={{ color: 'black' }}>{type_leads}</Button>
                </Badge>
                <Badge color="primary" style={{ marginRight: 15 }}>
                    <Button disabled variant="contained" style={{ color: 'black' }}>{type_date}</Button>
                </Badge>
                <Badge color="primary" style={{ marginRight: 15 }}>
                    <Button disabled variant="contained" style={{ color: 'black' }}>{dashboardOpDetail.length} Leads</Button>
                </Badge>

                <Button onClick={() => { copyTable('.table-dashboard-detail') }}><FileCopyIcon /></Button>

            </BootstrapDialogTitle>
            <DialogContent>
                <div className="table-responsive datatable-custom table-fix-head card" id="table-dashboard-detail">
                    <div className="table-responsive text-nowrap">
                        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle table-dashboard-detail">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>SOURCE</th>
                                    <th>OP MASTER</th>
                                    <th>NAME/SURNAME</th>
                                    <th>CUSTOMER PHONE</th>
                                    <th>STATUS</th>
                                    <th>APPOINTMENT AT</th>
                                    <th>APPOINTMENT CREATED AT</th>
                                    <th>SHOP</th>
                                    <th>IDC</th>
                                    <th>IDR</th>
                                    <th>CALENDAR AT</th>
                                    <th>CREATED AT</th>
                                    {(type_box !== "worked_leads" && type_box !== "shop_calls" && type_box !== "total_calls") &&
                                        <>
                                            <th>UPDATED AT</th>
                                            <th>REVENUE</th>
                                        </>
                                    }
                                    {(type_box === "worked_leads" || type_box === "shop_calls" || type_box === "total_calls") &&
                                        <th>CALL DURATION</th>
                                    }
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {list}
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
        </BootstrapDialog>
    )
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {

    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default DashboardDetail