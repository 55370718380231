import React from 'react';
import { useSelector } from 'react-redux';

const ShopIlpasCalendar = () => {

  const dialer = useSelector((state) => state.dialer.data);

  const { ilpas_collaboratori, ilpas_filiali, ilpas_recapiti } = dialer.shop_info;

  return (
    <div className="table-responsive datatable-custom table-fix-head card mb-3">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="8" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">ILPAS CALENDAR</h5>
              </th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            <tr>
              <td className="col-md-1 pl-2 pr-0 pt-0 pb-0">COLLABORATORI</td>
              <td>{ilpas_collaboratori}</td>
            </tr>
            <tr>
              <td className="col-md-1 pl-2 pr-0 pt-0 pb-0">FILIALI</td>
              <td>{ilpas_filiali}</td>
            </tr>
            <tr>
              <td className="col-md-1 pl-2 pr-0 pt-0 pb-0">RECAPITI</td>
              <td>{ilpas_recapiti}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )


}

export default ShopIlpasCalendar
