import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { setLeadSearchParams, setRefreshLeadSearch } from '../../../../redux/slices/leadSearchSlice';

const ShopsSearch = () => {

    const dispatch = useDispatch();
    const shops = useSelector((state) => state.shops.data);
    const leadSearchParams = useSelector((state) => state.leadSearch.params);
    const { refreshLeadSearch } = useSelector((state) => state.leadSearch);

    return (
        <Autocomplete
            fullWidth
            id="business_id"
            options={shops}
            getOptionLabel={(option) => option.business_name || leadSearchParams.business_name}
            value={leadSearchParams.business_id || null}
            isOptionEqualToValue={(option) => option.business_name === leadSearchParams.business_name}
            onChange={(event, option) => {
                if (option) {
                    dispatch(
                        setLeadSearchParams(
                            {
                                ...leadSearchParams,
                                business_id: option.id,
                                business_name: option.business_name
                            })
                    );
                }
                else {
                    dispatch(
                        setLeadSearchParams(
                            {
                                ...leadSearchParams,
                                business_id: "",
                                business_name: ""
                            })
                    );
                }
                dispatch(setRefreshLeadSearch(!refreshLeadSearch));
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField fullWidth {...params} label="Shops" />}
        />
    )
}

export default ShopsSearch
