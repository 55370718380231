import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        loggedId: null,
        loggedName: null,
        loggedEmail: null,
        loggedOpName: null,
        isLogged: false,
        isLoginError: false,
        isError: false
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setLoggedId: (state, action) => {
            state.loggedId = action.payload;
        },
        setLoggedName: (state, action) => {
            state.loggedName = action.payload;
        },
        setLoggedEmail: (state, action) => {
            state.loggedEmail = action.payload;
        },
        setLoggedOpName: (state, action) => {
            state.loggedOpName = action.payload;
        },
        setIsLogged: (state, action) => {
            state.isLogged = action.payload;
        },
        setIsLoginError: (state, action) => {
            state.isLoginError = action.payload;
        },
    },
});

export const { setToken, setIsLogged, setLoggedId, setLoggedName, setLoggedEmail, setLoggedOpName, setIsLoginError } = authSlice.actions;

export const fetchToken = createAsyncThunk('fetch/auth', async (credential, { dispatch }) => {

    try {
        const response = await instance.post('/login', credential);
        const { token } = response.data;
        const op_id = parseInt(response.data.user.name.replace(/[^0-9]/g, ''));
        const name = response.data.user.name;
        const email = response.data.user.email;
        dispatch(setToken(token));
        dispatch(setLoggedId(op_id));
        dispatch(setLoggedName(name));
        dispatch(setLoggedEmail(email));
        dispatch(setIsLogged(true));
        dispatch(setIsLoginError(false));

        if (email === "amministrazione@specialistidelludito.it") {
            dispatch(setLoggedId(12));
        }
        if (email === "logistica@specialistidelludito.it") {
            dispatch(setLoggedId(13));
        }

        return true;

    } catch (error) {
        dispatch(setIsLoginError(true));
        return false;
    }

});

export default authSlice.reducer;
