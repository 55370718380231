import React from 'react';
import { useSelector } from 'react-redux';
import StatusDropdown from './TabAppointment/StatusDropdown';
import AppointmentAtPicker from './TabAppointment/AppointmentAtPicker';
import CalendarAtPicker from './TabAppointment/CalendarAtPicker';
import SduAppointmentsTable from './TabAppointment/SduAppointmentsTable';
import ShopOpeningTimeTable from './TabAppointment/ShopOpeningTimeTable';
import ProblemsDropdown from './TabAppointment/ProblemsDropdown';
import ShopInfo from './TabAppointment/ShopInfo';
import ShopAvailability from './TabAppointment/ShopAvailability';
import ShopInstructions from './TabAppointment/ShopInstructions';
import NoteFromShop from './TabAppointment/NoteFromShop';
import EcommerceOrderTable from './TabAppointment/EcommerceOrderTable';
import ShopIlpasCalendar from './TabAppointment/ShopIlpasCalendar';

const TabAppointment = () => {

    const dialer = useSelector((state) => state.dialer.data);

    return (
        <>
            <div className="row">
                <div className="col-4">
                    <StatusDropdown />
                    <ProblemsDropdown />
                </div>
                <div className="col-4 mt-3">
                    <AppointmentAtPicker />
                </div>
                <div className="col-4 mt-3">
                    <CalendarAtPicker />
                </div>
            </div>

            {dialer.ecommerce_order_note &&
                <div className="row" style={({ marginTop: '20px' })}>
                    <div className="col-12">
                        <EcommerceOrderTable />
                    </div>
                </div>
            }

            {dialer.shop?.id &&
                <div className="row" style={({ marginTop: '20px' })}>
                    <div className="col-5">
                        <SduAppointmentsTable />
                        <ShopAvailability />
                        <ShopInstructions />
                    </div>
                    <div className="col-3">
                        {dialer.shop?.ilpas_schedule &&
                            <ShopIlpasCalendar />
                        }
                        <ShopOpeningTimeTable />
                    </div>
                    <div className="col-4">
                        <ShopInfo />
                        <NoteFromShop />
                    </div>
                </div>
            }


        </>
    )

}

export default TabAppointment