import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { exportLeads, setEcommerceNoteToggle, setQueryPage, setRealtimeSearch, setRefreshLeadSearch } from '../../../redux/slices/leadSearchSlice';
import DefaultSearch from './Form/DefaultSearch';
import CreatedAtSearch from './Form/CreatedAtSearch';
import AppointmentAtSearch from './Form/AppointmentAtSearch';
import OpAssignedSearch from './Form/OpAssignedSearch';
import SourceSearch from './Form/SourceSearch';
import StatusSearch from './Form/StatusSearch';
import ShopsSearch from './Form/ShopsSearch';
import ItalyDbSearch from './Form/ItalyDbSearch';
import ShopIds from './Form/ShopIds';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { copyToClipboard } from '../../../helpers';
import SearchIcon from '@mui/icons-material/Search';

const FormLeadsSearch = () => {

    const dispatch = useDispatch();
    const leadSearch = useSelector((state) => state.leadSearch.data);
    const { realtimeSearch, ecommerceNoteToggle, refreshLeadSearch } = useSelector((state) => state.leadSearch);
    const { loggedEmail } = useSelector((state) => state.auth);
    const { total } = useSelector((state) => state.leadSearch.data);

    const handleKeyEnter = (event) => {
        if (event.key === 'Enter') {
            dispatch(setQueryPage(1));
            dispatch(setRefreshLeadSearch(!refreshLeadSearch));
        }
    }

    const handleSwitchRealTimeSearch = () => {
        dispatch(setRealtimeSearch(!realtimeSearch));
    }

    const handleSwitchEcommerceNoteToggle = () => {
        dispatch(setEcommerceNoteToggle(!ecommerceNoteToggle));
    }

    const handleExportLeads = () => {
        dispatch(exportLeads());
    }

    const handleSearch = () => {
        dispatch(setRefreshLeadSearch(!refreshLeadSearch));
    }

    const handleCopyIds = () => {
        if (leadSearch.data && Array.isArray(leadSearch.data)) {
            const ids = leadSearch.data.map(item => item.id);
            const idsString = ids.join('\n');
            copyToClipboard(idsString);
        } else {
            alert("I dati non sono nel formato corretto.");
        }
    }


    return (
        <>

            <Stack spacing={2} direction="row" style={{ marginBottom: 10 }}>
                <FormControlLabel control={
                    <Switch
                        checked={realtimeSearch}
                        onChange={handleSwitchRealTimeSearch}
                        value={realtimeSearch}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Realtime" />
            </Stack>

            <Stack spacing={2} direction="row" style={{ marginBottom: 10 }}>
                <FormControlLabel control={
                    <Switch
                        checked={ecommerceNoteToggle}
                        onChange={handleSwitchEcommerceNoteToggle}
                        value={ecommerceNoteToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Ecommerce Note" />
            </Stack>

            <DefaultSearch handleKeyEnter={handleKeyEnter} />

            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <CreatedAtSearch />
            </Stack>

            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <AppointmentAtSearch />
            </Stack>

            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <OpAssignedSearch />
                <SourceSearch />
            </Stack>

            <StatusSearch />

            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <ShopsSearch />
                <ItalyDbSearch />
            </Stack>

            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <ShopIds handleKeyEnter={handleKeyEnter} />
            </Stack>

            <IconButton
                variant="text"
                sx={{
                    m: '10px 10px 20px 0',
                    borderRadius: "4px",
                    bgcolor: 'primary.main',
                    color: 'white',
                    fontSize: '15px',
                    ':hover': {
                        bgcolor: 'secondary.main',
                        color: 'white',
                    },
                }}
                edge="start" color="inherit" aria-label="close" onClick={handleSearch}>
                Search <SearchIcon sx={{ marginLeft: 1 }} />
            </IconButton>

            {['c.membrini@udibox.it', 'op7@specialistidelludito.it', 'web@udibox.it'].includes(loggedEmail) && (
                total < 2000 ? (
                    <>
                        <IconButton
                            variant="text"
                            sx={{
                                m: '10px 10px 20px 0',
                                borderRadius: "4px",
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontSize: '15px',
                                ':hover': {
                                    bgcolor: 'secondary.main',
                                    color: 'white',
                                },
                            }}
                            edge="start" color="inherit" aria-label="close" onClick={handleExportLeads}>
                            Export <FileDownloadIcon sx={{ marginLeft: 1 }} />
                        </IconButton>
                        <IconButton
                            variant="text"
                            sx={{
                                m: '10px 0 20px 0',
                                borderRadius: "4px",
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontSize: '15px',
                                ':hover': {
                                    bgcolor: 'secondary.main',
                                    color: 'white',
                                },
                            }}
                            edge="start" color="inherit" aria-label="close" onClick={() => handleCopyIds()}>
                            Copy IDS <FileCopyIcon sx={{ marginLeft: 1 }} />
                        </IconButton>
                        <p>Total Leads: {total}</p>
                    </>
                ) : (
                    <>
                        <IconButton
                            disbaled
                            variant="text"
                            sx={{
                                m: '0px 0 10px 0',
                                borderRadius: "4px",
                                bgcolor: 'error.main',
                                color: 'white',
                                fontSize: '15px',
                                ':hover': {
                                    bgcolor: 'danger.main',
                                    color: 'white',
                                },
                            }}
                            edge="start" color="inherit" aria-label="close">
                            Export <FileDownloadIcon sx={{ marginLeft: 1 }} />
                        </IconButton>
                        <p>Total Leads: {total} export limit: 2000</p>
                    </>
                )
            )}
        </>
    )
}

export default FormLeadsSearch
