import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const shopSlice = createSlice({
    name: 'shops',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchShops.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchShops.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchShops.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

//export const { } = shopSlice.actions;

export const fetchShops = createAsyncThunk('fetch/shops', async (_, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/shops');
        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default shopSlice.reducer;
