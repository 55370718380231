import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid } from '@mui/material';
import DropdownsSettingsTransfers from '../../components/Modals/Transfers/DropdownsSettingsTransfers';
import TableSettingsTransfer from '../../components/Modals/Transfers/TableSettingsTransfer';
import NavbarRight from '../../components/NavbarRight';
import { setCurrentModal } from '../../redux/slices/modalSlice';
import { setCountdownSeconds } from '../../redux/slices/managerSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';
import OperatorsCheckboxTransfers from './Transfers/OperatorsCheckboxTransfers';
import { setRefreshTransfers, setTransfersParams } from '../../redux/slices/transfersSlices';

const ModalTransfers = () => {

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.manager.isLoading);
    const { loggedEmail } = useSelector((state) => state.auth);
    const { currentModal } = useSelector((state) => state.modal);
    const { opIdFrom, opIdTo } = useSelector((state) => state.transfers.params);
    const refreshTransfers = useSelector((state) => state.transfers.refreshTransfers);
    const transfersParams = useSelector((state) => state.transfers.params);

    const handleClose = () => {
        dispatch(setCurrentModal(''));
        dispatch(setCountdownSeconds(30));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
    };

    const handleClickRefreshTransfers = () => {
        dispatch(setRefreshTransfers(!refreshTransfers))
    };

    useEffect(() => {
        const filteredOpIdTo = opIdTo.filter(id => id !== opIdFrom);
        if (filteredOpIdTo.length > 0) {
            dispatch(setTransfersParams({ ...transfersParams, opIdTo: filteredOpIdTo }));
        }
    }, [opIdFrom, currentModal]);

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }} style={{ background: '#1a73e8', marginRight: 0 }}>

                <Toolbar>

                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            {!loggedEmail.includes('@udibox.it') &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            }

                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Transfers</Button>
                            </Badge>

                            <IconButton
                                variant="text" color="inherit" onClick={handleClickRefreshTransfers}>
                                <RefreshIcon />
                            </IconButton>

                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NavbarRight />
                            </Box>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            {!isLoading ? (
                <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                    <div className='row mt-2'>
                        <div className='col-12 mt-2'>
                            <OperatorsCheckboxTransfers />
                            <DropdownsSettingsTransfers />
                        </div>
                        <div className='col-12 mt-4'>
                            <TableSettingsTransfer />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                </div>
            )}

        </Dialog>
    );
}

export default ModalTransfers