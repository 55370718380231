import React from 'react'
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const BadgeCustomerJoy = ({ isCustomerJoy, user_action, user_answer, lead_status_1 }) => {

    return (
        <>

            {(isCustomerJoy && !user_action && !user_answer) &&
                <Tooltip title="Token in corso non modificare" description=" descr" arrow>
                    <Badge color="primary">
                        <IconButton
                            disabled
                            style={{ backgroundColor: 'var(--orange)', color: 'white', border: '1px solid #fff' }}>
                            <img src="assets/icons/call-center-icon.svg" className="call-center-icon" alt="Call Center Icon" />
                        </IconButton>
                    </Badge>
                </Tooltip>
            }

            {user_action === 1 && (lead_status_1?.id === 21) &&
                <Tooltip title="Da Richiamare" arrow>
                    <Badge color="primary">
                        <IconButton
                            disabled
                            style={{ backgroundColor: 'var(--green)', color: 'white', border: '1px solid #fff' }}>
                            <img src="assets/icons/call-center-icon.svg" className="call-center-icon" alt="Call Center Icon" />
                        </IconButton>
                    </Badge>
                </Tooltip>
            }

            {user_action === 1 && (lead_status_1?.id === 22) &&
                <Tooltip title="Solo Aperto" arrow>
                    <Badge color="primary">
                        <IconButton
                            disabled
                            style={{ backgroundColor: 'var(--primary)', color: 'white', border: '1px solid #fff' }}>
                            <img src="assets/icons/call-center-icon.svg" className="call-center-icon" alt="Call Center Icon" />
                        </IconButton>
                    </Badge>
                </Tooltip>
            }

            {user_action === 1 && lead_status_1?.id === 3 &&
                <Tooltip title={`Non interessato - ${user_answer}`} arrow>
                    <Badge color="primary">
                        <IconButton
                            disabled
                            style={{ backgroundColor: 'var(--red)', color: 'white', border: '1px solid #fff' }}>
                            <img src="assets/icons/call-center-icon.svg" className="call-center-icon" />
                        </IconButton>
                    </Badge>
                </Tooltip>
            }
        </>
    )
}

export default BadgeCustomerJoy
