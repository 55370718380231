import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { setLeadSearchParams } from '../../../../redux/slices/leadSearchSlice';

const SourceSearch = () => {

    const dispatch = useDispatch();
    const leadSearchParams = useSelector((state) => state.leadSearch.params);
    const sources = useSelector((state) => state.sources.data);

    return (
        <Autocomplete
            fullWidth
            id="source"
            options={sources}
            getOptionLabel={(option) => option.source || leadSearchParams.source}
            value={leadSearchParams.source || null}
            isOptionEqualToValue={(option, value) => option.source === value}
            onChange={(event, option) => {
                if (option) {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, source: option.source }));
                }
                else {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, source: null }));
                }
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField fullWidth {...params} label="Source" />}
        />
    )
}

export default SourceSearch
