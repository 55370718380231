import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';

const BadgeStatusGroup = () => {

    const manager = useSelector((state) => state.manager.data);

    useEffect(() => {

        // BADGE SCROLLER
        const slider = document.querySelector('.parent');
        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function () {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);

    }, [])

    const badges = manager.badges_status_group ? Object.entries(manager?.badges_status_group).map(([key, val]) =>
        <span key={key}>
            {key !== 'fc_in_attesa_nvc_tmp_fake' &&
                <Badge color="secondary" badgeContent={val} style={{ marginTop: 11, marginRight: 20 }} max={9999}>
                    <Button disabled variant="contained" style={{ textTransform: 'lowercase', color: 'black', fontSize: 10 }}>{key}</Button>
                </Badge>
            }
        </span>

    ) : [];

    return (
        <div className="mt-2" id="badge-status-group">
            <div className="parent">
                <div className="child">
                    {badges}
                </div>
            </div>
        </div>
    )

}

export default BadgeStatusGroup