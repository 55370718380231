import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import actionBarTwoSlice from "./slices/actionBarTwoSlice";
import authSlice from "./slices/authSlice";
import distanceMatrixSlice from "./slices/distanceMatrixSlice";

import dialerSlice from "./slices/dialerSlice";
import historyLeadSlice from "./slices/historyLeadSlice";
import italyDbByZipcodeSlice from "./slices/italyDbByZipcodeSlice";
import logNotesByLeadIdSlice from "./slices/logNotesByLeadIdSlice";
import logNotesByCustomerPhoneSlice from "./slices/logNotesByCustomerPhoneSlice";
import logRecordingsSlice from "./slices/logRecordingsSlice";
import managerSlice from "./slices/managerSlice";
import modalSlice from "./slices/modalSlice";
import nearestShopsSlice from "./slices/nearestShopsSlice";
import problemsSlice from "./slices/problemsSlice";
import sduAppointmentsSlice from "./slices/sduAppointmentsSlice";
import settingsPresetsSlice from "./slices/settingsPresetsSlice";
import sourceSlice from "./slices/sourceSlice";
import statusSlice from "./slices/statusSlice";
import dialerNavbarSlice from "./slices/dialerNavbarSlice";
import workToDoSlice from "./slices/workToDoSlice";
import dashboardOpSlice from "./slices/dashboardOpSlice";
import dashboardOpDetailSlice from "./slices/dashboardOpDetailSlice";
import leadSearchSlice from "./slices/leadSearchSlice";
import shopSlice from "./slices/shopSlice";
import italyDbSlice from "./slices/italyDbSlice";
import addNewLeadSlice from "./slices/addNewLeadSlice";
import logNotesSearchSlice from "./slices/logNotesSearchSlice";
import transfersSlices from "./slices/transfersSlices";
import logReleasesSlice from "./slices/logReleasesSlice";
import snackbarSlices from "./slices/snackbarSlices";
import logActionsSearchSlice from "./slices/logActionsSearchSlice";
import logActionStoreSlice from "./slices/logActionStoreSlice";
import remarketingSlice from "./slices/remarketingSlice";
import remarketingByLeadsSlice from "./slices/remarketingByLeadsSlice";
import logCustomerJoyTokenSlice from "./slices/logCustomerJoyTokenSlice";
import logCustomerJoyUserSlice from "./slices/logCustomerJoyUserSlice";
import customerJoyTokenSlice from "./slices/customerJoyTokenSlice";

const persistConfig = {
  key: 'root',
  storage,
};

const allReducer = combineReducers({
  actionBarTwo: actionBarTwoSlice,
  addNewLead: addNewLeadSlice,
  auth: authSlice,
  customerJoyToken: customerJoyTokenSlice,
  dashboardOp: dashboardOpSlice,
  dashboardOpDetail: dashboardOpDetailSlice,
  distanceMatrix: distanceMatrixSlice,
  dialer: dialerSlice,
  dialerNavbar: dialerNavbarSlice,
  historyLead: historyLeadSlice,
  italyDb: italyDbSlice,
  italyDbByZipcode: italyDbByZipcodeSlice,
  leadSearch: leadSearchSlice,
  logActionsSearch: logActionsSearchSlice,
  logActionStore: logActionStoreSlice,
  logCustomerJoyToken: logCustomerJoyTokenSlice,
  logCustomerJoyUser: logCustomerJoyUserSlice,
  logNotesSearch: logNotesSearchSlice,
  logNotesByLeadId: logNotesByLeadIdSlice,
  logNotesByCustomerPhone: logNotesByCustomerPhoneSlice,
  logRecordings: logRecordingsSlice,
  logReleases: logReleasesSlice,
  manager: managerSlice,
  modal: modalSlice,
  nearestShops: nearestShopsSlice,
  problems: problemsSlice,
  remarketing: remarketingSlice,
  remarketingByLeads: remarketingByLeadsSlice,
  sduAppointments: sduAppointmentsSlice,
  shops: shopSlice,
  settingsPresets: settingsPresetsSlice,
  snackbar: snackbarSlices,
  sources: sourceSlice,
  status: statusSlice,
  transfers: transfersSlices,
  workToDo: workToDoSlice
});

const persistRed = persistReducer(persistConfig, allReducer);

const store = configureStore({
  reducer: persistRed,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 512 },
    serializableCheck: false,
  })
});

export default store;
export const persistor = persistStore(store);
export const purgeData = async () => {
  await persistor.purge();
};
