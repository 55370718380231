import React from 'react';
import { useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { Box, Grid } from '@mui/material';
import NavbarRight from '../NavbarRight';

const BadgeOpSettings = () => {
    const settingsPresets = useSelector((state) => state.settingsPresets);
    const { loggedId, loggedName, loggedOpName } = useSelector((state) => state.auth);
    const manager = useSelector((state) => state.manager.data);
    const opSettings = manager.op_settings && manager.op_settings[loggedId - 1];
    const { id, start_working_time, end_working_time, status, calendar_at_expired, lead_gen, pbx, pbx_ciao, pbx_h360, forward, queue_list } = opSettings || {};

    return (
        <div className="mt-20">
            <Grid container spacing={0}>
                <Grid item xs={10}>
                    {loggedName && loggedName.includes('Operatore') && (
                        <Box>
                            <Badge
                                color="secondary"
                                badgeContent={loggedId === 7 ? manager.totals - 1 : manager.totals}
                                style={{ marginRight: 20 }}
                                max={9999}
                                showZero
                            >
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>OP{id}</Button>
                            </Badge>

                            <Badge color={status ? 'success' : 'error'} variant="dot" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>{loggedOpName}</Button>
                            </Badge>

                            <Badge badgeContent={0} style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>
                                    {start_working_time && end_working_time ? `${start_working_time.slice(0, 2)}/${end_working_time.slice(0, 2)}` : ''}
                                </Button>
                            </Badge>

                            <Badge badgeContent={0} style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>
                                    {Object.entries(settingsPresets).map(function (key) {
                                        if (key[1].includes(queue_list) && queue_list !== "rmkt") {
                                            return key[0];
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {queue_list === "rmkt" && "rmkt"}
                                </Button>
                            </Badge>

                            <Badge color={calendar_at_expired ? 'success' : 'error'} variant="dot" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>CALENDAR AT EXPIRED</Button>
                            </Badge>

                            <Badge color={lead_gen ? 'success' : 'error'} variant="dot" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>LEAD GEN</Button>
                            </Badge>

                            <Badge color={pbx ? 'success' : 'error'} variant="dot" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>PBX SDU</Button>
                            </Badge>

                            <Badge color={pbx_ciao ? 'success' : 'error'} variant="dot" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>PBX CIAO</Button>
                            </Badge>

                            <Badge color={pbx_h360 ? 'success' : 'error'} variant="dot" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>PBX H360</Button>
                            </Badge>

                            <Badge color={forward ? 'success' : 'error'} variant="dot" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ color: 'black', fontSize: 10 }}>FWD</Button>
                            </Badge>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <NavbarRight />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default BadgeOpSettings;
