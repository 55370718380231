import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { setLogActionsSearchParams, setRefreshLogActionsSearch } from '../../../../redux/slices/logActionsSearchSlice';

const CreatedAtSearch = () => {

    const dispatch = useDispatch();
    const logActionsSearchParams = useSelector((state) => state.logActionsSearch.params);
    const refreshLogActionsSearch = useSelector((state) => state.logActionsSearch.refreshLogActionsSearch);

    return (
        <Stack spacing={2} direction="row" style={{ marginTop: 10, marginBottom: 10 }}>
            <LocalizationProvider
                id="created_at_from"
                dateAdapter={AdapterDayjs}
                adapterLocale="it">
                <DesktopDatePicker
                    label="Created At from"
                    value={logActionsSearchParams.created_at_from ? dayjs(logActionsSearchParams.created_at_from, 'YYYY-MM-DD') : null}
                    onChange={(newValue) => {
                        dispatch(setLogActionsSearchParams({ ...logActionsSearchParams, created_at_from: dayjs(newValue).format('YYYY-MM-DD') }));
                    }}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>

            <LocalizationProvider
                id="created_at_to"
                dateAdapter={AdapterDayjs}
                adapterLocale="it">
                <DesktopDatePicker
                    label="Created At to"
                    value={logActionsSearchParams.created_at_to ? dayjs(logActionsSearchParams.created_at_to, 'YYYY-MM-DD') : null}
                    onChange={(newValue) => {
                        dispatch(setLogActionsSearchParams({ ...logActionsSearchParams, created_at_to: dayjs(newValue).format('YYYY-MM-DD') }));
                        dispatch(setRefreshLogActionsSearch(!refreshLogActionsSearch))
                    }}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>
        </Stack>
    )
}

export default CreatedAtSearch