import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { setLeadSearchParams } from '../../../../redux/slices/leadSearchSlice';

const AppointmentAtSearch = () => {

    const dispatch = useDispatch();
    const leadSearchParams = useSelector((state) => state.leadSearch.params);

    return (
        <Stack spacing={2} direction="row">
            <LocalizationProvider
                id="appointment_at_from"
                dateAdapter={AdapterDayjs}
                adapterLocale="it">
                <DesktopDatePicker
                    label="Appointment At from"
                    value={leadSearchParams.appointment_at_from ? dayjs(leadSearchParams.appointment_at_from, 'YYYY-MM-DD') : null}
                    onChange={(newValue) => {
                        dispatch(setLeadSearchParams({ ...leadSearchParams, appointment_at_from: dayjs(newValue).format('YYYY-MM-DD') }));
                    }}
                    inputFormat="DD/MM/YYYY"
                    inputProps={{ readOnly: true }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>

            <LocalizationProvider
                id="appointment_at_to"
                dateAdapter={AdapterDayjs}
                adapterLocale="it">
                <DesktopDatePicker
                    label="Appointment At to"
                    value={leadSearchParams.appointment_at_to ? dayjs(leadSearchParams.appointment_at_to, 'YYYY-MM-DD') : null}
                    onChange={(newValue) => {
                        dispatch(setLeadSearchParams({ ...leadSearchParams, appointment_at_to: dayjs(newValue).format('YYYY-MM-DD') }));
                    }}
                    inputFormat="DD/MM/YYYY"
                    inputProps={{ readOnly: true }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                />
            </LocalizationProvider>
        </Stack>
    )
}

export default AppointmentAtSearch