import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const customerJoyTokenSlice = createSlice({
    name: 'customerJoyToken',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {
        setCustomerJoyToken: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(generateCustomerJoyToken.pending, (state) => {
                state.isError = false;
            })
            .addCase(generateCustomerJoyToken.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(generateCustomerJoyToken.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setCustomerJoyToken } = customerJoyTokenSlice.actions;

export const generateCustomerJoyToken = createAsyncThunk('generateToken/customerJoy', async (_, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;
        const currentLeadId = state.manager.currentLeadId

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/log-customer-joy-tokens/generate/${currentLeadId}`);
        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default customerJoyTokenSlice.reducer;
