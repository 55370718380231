import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';

const initialParams = {
    op_master: '',
    type_box: '',
    type_leads: '',
    date_from: '', 
    date_to: '',
    source: '',
    type_date: '',
    type_sort: ''
}

const dashboardOpDetailSlice = createSlice({
    name: 'dashboardOpDetail',
    initialState: {
        data: [],
        openDashboardDetail: false,
        params: initialParams,
        isError: false
    },
    reducers: {
        setDashboardOpDetail: (state, action) => {
            state.data = action.payload;
        },
        setDashboardOpDetailParams: (state, action) => {
            state.params = action.payload;
        },
        setResetDashboardOpDetailParams: (state) => {
            state.params = initialParams;
        },
        setOpenDashboardDetail: (state, action) => {
            state.openDashboardDetail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardOpDetail.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchDashboardOpDetail.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchDashboardOpDetail.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setDashboardOpDetail, setDashboardOpDetailParams, setResetDashboardOpDetailParams, setOpenDashboardDetail } = dashboardOpDetailSlice.actions;

export const fetchDashboardOpDetail = createAsyncThunk('fetch/dashboardOpDetail', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true))

        const state = getState();
        const { date_from, date_to, type_source, type_date, type_sort } = state.dashboardOp.params;
        const { op_master, type_box, type_leads } = state.dashboardOpDetail.params;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/dashboard/detail', {
            params: {
                op_master,
                date_from,
                date_to,
                source: type_source,
                type_box,
                type_leads,
                type_date,
                type_sort
            }
        });

        if (response.status === 200) {
            dispatch(setIsLoading(false))
            return response.data;

        } else {
            throw new Error('Error loading api');
        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default dashboardOpDetailSlice.reducer;
