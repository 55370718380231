export function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
    document.getElementById("snackbar_copied_to_clipboard").style.display = "block";
    setTimeout(() => {
        document.getElementById("snackbar_copied_to_clipboard").style.display = "none";
    }, 500);
}

export async function pasteFromClipboard() {
    try {
        const clipboardText = await navigator.clipboard.readText();
        return clipboardText.trim();
    } catch (error) {
        console.error('Errore nella lettura degli appunti:', error);
        throw error; // Puoi scegliere di gestire l'errore qui o propagarlo all'esterno
    }
}

export function copyTable(tableClass) {

    const elTable = document.querySelector(tableClass);

    let range, sel;

    if (document.createRange && window.getSelection) {

        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();

        try {
            range.selectNodeContents(elTable);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(elTable);
            sel.addRange(range);
        }

        document.execCommand('copy');
    }

    sel.removeAllRanges();

    document.getElementById("snackbar_copied_to_clipboard").style.display = "block";
    setTimeout(() => {
        document.getElementById("snackbar_copied_to_clipboard").style.display = "none";
    }, 500);

}

export function calendarAtEnabled() {
    var calendarAtEnabled = {};
    calendarAtEnabled['status1'] = [1, 2, 17, 19, 21, 22, 24, 28, 41];
    calendarAtEnabled['status2'] = [51, 53, 55, 56, 58, 65];
    calendarAtEnabled['status3'] = [101, 102, 103];
    calendarAtEnabled['status4'] = [152, 153, 157, 183, 189, 188];
    return calendarAtEnabled;
}

export function getCurrentTime() {
    // Get the current time
    var currentDate = new Date();

    // Round the minutes up to the nearest 5 minutes
    var roundedMinutes = Math.ceil(currentDate.getMinutes() / 5) * 5;

    // Set the rounded minutes and reset seconds and milliseconds to zero
    currentDate.setMinutes(roundedMinutes);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    // Format the time as "hh:mm"
    var formattedTime =
        (currentDate.getHours() < 10 ? '0' : '') + currentDate.getHours() + ':' +
        (currentDate.getMinutes() < 10 ? '0' : '') + currentDate.getMinutes();

    // Return the formatted time
    return formattedTime;
}

export function getCurrentDay() {
    var today = new Date();
    return today.getDay();
}
