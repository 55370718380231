import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import FormNewLead from '../../Modals/AddNewLead/FormNewLead';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CommentIcon from '@mui/icons-material/Comment';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { setCountdownSeconds, setCurrentLeadId, setIsLoadingSmall } from '../../../redux/slices/managerSlice';
import { setQueryPage, setRefreshLeadSearch } from '../../../redux/slices/leadSearchSlice';
import { setCurrentModal, setCurrentTabEditLead } from '../../../redux/slices/modalSlice';
import { setAddNewLead } from '../../../redux/slices/addNewLeadSlice';
import { storeLogAction } from '../../../redux/slices/logActionStoreSlice';
import BadgeCustomerJoy from '../../Manager/BadgeCustomerJoy';

const TableLeadsSearch = () => {

    const dispatch = useDispatch();
    const leadSearch = useSelector((state) => state.leadSearch.data);
    const { last_page } = useSelector((state) => state.leadSearch.data);
    const { page } = useSelector((state) => state.leadSearch.params);
    const addNewLead = useSelector((state) => state.addNewLead.params);
    const isLoadingSmall = useSelector((state) => state.manager.isLoadingSmall);
    const { loggedEmail } = useSelector((state) => state.auth);
    const { refreshLeadSearch, ecommerceNoteToggle } = useSelector((state) => state.leadSearch);
    const { currentModal } = useSelector((state) => state.modal);
    const logCustomerJoyTokens = useSelector((state) => state.logCustomerJoyToken.data.data);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        dispatch(setCountdownSeconds(30));
        setOpen(false);
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
    }

    const handleClickLeadEdit = async (leads) => {
        dispatch(setCurrentLeadId(leads.id));
        dispatch(setCurrentModal('modalLeadEdit'));
        dispatch(setCurrentTabEditLead(0))
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/editLead',
                value: leads.id,
                input_type: 'button'
            })
        );
    }

    const handleClickCopy = async (id, shop_id, shop_info_id, source, customer_name, customer_phone, customer_zipcode, customer_email, business_name, shop_address) => {

        handleOpen();

        dispatch(
            setAddNewLead({
                ...addNewLead,
                id: id,
                created_at: dayjs().format('DD/MM/YYYY HH:mm'),
                op_assigned: localStorage.getItem('op_id'),
                shop_id: shop_id,
                shop_info_id: shop_info_id,
                source: source,
                customer_name: customer_name,
                customer_phone: customer_phone,
                customer_zipcode: customer_zipcode,
                customer_email: customer_email,
                privacy_policy: true,
                business_name: business_name,
                shop_address: shop_address
            })
        );

    }

    const goToPage = (event, value) => {
        dispatch(setIsLoadingSmall(true))
        dispatch(setQueryPage(value));
        dispatch(setRefreshLeadSearch(!refreshLeadSearch));
    }

    const list = Array.isArray(leadSearch.data) && leadSearch.total > 0 ? leadSearch.data.map((leads) => {

        const { id, shop_id, shop_info_id, op_assigned, customer_name, customer_phone, customer_zipcode, customer_email, source, lead_status_1, lead_status_2, lead_status_3, lead_status_4, appointment_at, calendar_at, created_at, disabled, rmkt, user_action, user_answer, ecommerce_order_note } = leads;

        const isCustomerJoy = logCustomerJoyTokens && logCustomerJoyTokens.some(token => token.lead_id === id);

        return (
            <tr key={id}>

                <td>

                    <BadgeCustomerJoy isCustomerJoy={isCustomerJoy} user_action={user_action} user_answer={user_answer} lead_status_1={lead_status_1} />

                    {(!loggedEmail.includes('@udibox.it')) &&
                        <IconButton
                            variant="text"
                            sx={{
                                ml: 1,
                                borderRadius: "4px",
                                bgcolor: 'primary.main',
                                color: 'white',
                                ':hover': {
                                    bgcolor: 'secondary.main',
                                    color: 'white',
                                },
                            }}
                            edge="start" color="inherit" aria-label="close" onClick={() => handleClickLeadEdit(leads)}>
                            <EditIcon />
                        </IconButton>
                    }

                    {leads.log_notes_by_lead_id.length > 0 &&
                        <HtmlTooltip
                            placement="right"
                            title={
                                <>
                                    {leads.log_notes_by_lead_id.map((singleNote, index) => {
                                        return (
                                            <ul key={index} className="list-unstyled">
                                                <li><strong>{singleNote.created_at}</strong> (<strong>OP{singleNote.op_logged}</strong>) {singleNote.note}</li>
                                            </ul>
                                        );
                                    })
                                    }
                                </>
                            }
                        >
                            <IconButton
                                variant="text"
                                sx={{
                                    ml: 1,
                                    borderRadius: "4px",
                                    bgcolor: 'primary.main',
                                    color: 'white',
                                    ':hover': {
                                        bgcolor: 'secondary.main',
                                        color: 'white',
                                    },
                                }}
                                edge="start" color="inherit" aria-label="close">
                                <Badge
                                    badgeContent={leads.log_notes_by_lead_id.length} color="secondary">
                                    <CommentIcon />
                                </Badge>
                            </IconButton>
                        </HtmlTooltip>
                    }

                    {(!loggedEmail.includes('@udibox.it')) &&
                        <IconButton
                            variant="text"
                            sx={{
                                ml: 2,
                                borderRadius: "4px",
                                bgcolor: 'primary.main',
                                color: 'white',
                                ':hover': {
                                    bgcolor: 'secondary.main',
                                    color: 'white',
                                },
                            }}
                            edge="start" color="inherit" aria-label="close" onClick={() => handleClickCopy(id, shop_id, shop_info_id, source, customer_name, customer_phone, customer_zipcode, customer_email, leads.shop?.business_name, leads.shop_info?.shop_address)}>
                            <FileCopyIcon />
                        </IconButton>
                    }
                </td>
                <td>{op_assigned}</td>
                <td>
                    {rmkt > 0 &&
                        <CheckIcon />
                    }
                </td>
                <td>{id}</td>
                <td>{source}</td>
                <td>{customer_name}</td>
                <td>{customer_phone}</td>
                <td>{customer_zipcode}</td>
                <td>{customer_email}</td>
                <td>
                    <ul className='list-unstyled m-0'>
                        <li>{lead_status_1?.name}</li>
                        {lead_status_2 &&
                            <li>{lead_status_2?.name}</li>
                        }
                        {lead_status_3 &&
                            <li>{lead_status_3?.name}</li>
                        }
                        {lead_status_4 &&
                            <li>{lead_status_4?.name}</li>
                        }
                    </ul>
                </td>
                <td>
                    {leads.shop?.business_name &&
                        <ul className="list-unstyled m-0">
                            <li>
                                <Badge color="secondary" style={{ marginRight: 5 }}>
                                    <Button disabled variant="contained" style={{ backgroundColor: '#1a73e8', color: 'white', padding: 0 }}>{shop_id}</Button>
                                </Badge>
                                <Badge color="primary">
                                    <Button disabled variant="contained" style={{ backgroundColor: '#1a73e8', color: 'white', padding: 0 }}>{shop_info_id}</Button>
                                </Badge>
                            </li>
                            <li className="mt-2">{leads.shop?.business_name}</li>
                            <li>{leads.shop_info?.shop_address}</li>
                            <li>{leads.shop_info?.shop_province}</li>
                            {leads.shop_info?.shop_fraction &&
                                <li>{leads.shop_info?.shop_fraction}</li>
                            }
                        </ul>
                    }
                </td>
                <td>{created_at}</td>
                <td>{appointment_at}</td>
                <td>{calendar_at}</td>
                {ecommerceNoteToggle &&
                    <td className='align-left' dangerouslySetInnerHTML={{ __html: ecommerce_order_note }}></td>
                }
                {disabled ? (
                    <td><div className='p-3 bg-danger status-light-center'></div></td>
                ) : (
                    <td></td>
                )}
            </tr >
        )
    }) : <tr>
        <td colSpan="16" className="text-center">No Results found</td>
    </tr>

    return (
        <>
            <div className="table-responsive datatable-custom table-fix-head card" id="table-dialer">
                <div className="table-responsive text-nowrap">
                    <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                        <thead>
                            <tr>
                                <th colSpan="16" className="mt-2 mb-2">
                                    {!isLoadingSmall ? (
                                        <h5 className="text-center text-white mb-0 pb-2">LEADS ({leadSearch.total || 0})</h5>
                                    ) : (
                                        <h5 className="text-center text-white mb-0 pb-2">LEADS</h5>
                                    )}
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th>OP ASSIGNED</th>
                                <th>RMKT</th>
                                <th>ID</th>
                                <th>SOURCE</th>
                                <th>NAME/SURNAME</th>
                                <th>PHONE</th>
                                <th>ZIPCODE</th>
                                <th>EMAIL</th>
                                <th>STATUS</th>
                                <th>SHOP</th>
                                <th>CREATED AT</th>
                                <th>APPOINTMENT AT</th>
                                <th>CALENDAR AT</th>
                                {ecommerceNoteToggle &&
                                    <th>ECOMMERCE ORDER NOTE</th>
                                }
                                <th>DISABLED</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {!isLoadingSmall ? (
                                list
                            ) : (
                                <tr>
                                    <td colSpan="16"><CircularProgress /></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {leadSearch.total > 0 &&
                <>
                    <div className="row mt-4 mb-5">
                        <div className="col-12 d-flex justify-content-center">
                            {!isLoadingSmall &&
                                <Stack spacing={2}>
                                    <Pagination count={last_page} defaultPage={page} onChange={goToPage} color="primary" />
                                </Stack>
                            }
                        </div>
                    </div>
                </>
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <FormNewLead />
                </Box>
            </Modal>

        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 800,
        width: 800,
        height: 400,
        overflowY: 'scroll',
        fontSize: '1em',
        fontWeight: 'normal',
        border: '1px solid #dadde9',
    },
}));

export default TableLeadsSearch
