import React from 'react'
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const OpSettings = ({ workToDoType }) => {

    const { loggedId } = useSelector((state) => state.auth);
    const manager = useSelector((state) => state.manager.data);
    const settingsPresets = useSelector((state) => state.settingsPresets);

    let opSettingsFilter = "";

    if (workToDoType === 'ALL') {
        opSettingsFilter = manager.op_settings;
    }
    else if (workToDoType === 'ONLINE') {
        opSettingsFilter = manager.op_settings.filter(op_settings => op_settings.status === true);
    }
    else if (workToDoType === 'OFFLINE') {
        opSettingsFilter = manager.op_settings.filter(op_settings => op_settings.status === false);
    }
    else if (workToDoType === 'ECOMMERCE') {
        opSettingsFilter = manager.op_settings.filter(op_settings => [5, 9].includes(op_settings.id));
    }
    else if (workToDoType === 'YOU') {
        opSettingsFilter = manager.op_settings.filter(op_settings => op_settings.id === loggedId);
    }

    const list = Array.isArray(opSettingsFilter) ? opSettingsFilter.map((settings) => {

        const { id, last_activity, current_name, current_phone, op_name, start_working_time, end_working_time, calendar_at_expired, lead_gen, priority_lead_gen, pbx, priority_pbx, pbx_ciao, priority_pbx_ciao, pbx_h360, priority_pbx_h360, forward, total_leads, priority_forward, presets, queue_list, acr_error } = settings;

        return (

            <tr key={id} className={`${parseInt(localStorage.getItem('op_id')) === id ? "table-success table-bold" : ""}`}>
                <td>{id}</td>
                <td>{op_name.split(' ')[0]} <br /> {op_name.split(' ')[1]}</td>
                <td>{dayjs(last_activity).format('DD/MM/YYYY')}<br />{dayjs(last_activity).format('HH:mm:ss')}</td>
                <td title={current_name}>{current_phone}</td>
                <td>{start_working_time.slice(0, -6)}/{end_working_time.slice(0, -6)}</td>
                <td>{acr_error <= 30 ? (
                    <div className='p-3 bg-success status-light-center'></div>
                ) : (
                    <div className='p-3 bg-danger status-light-center'>{acr_error > 999 ? '999+' : acr_error}</div>
                )}
                </td>
                <td>{calendar_at_expired ? (
                    <div className='p-3 bg-success status-light-center'></div>
                ) : (
                    <div className='p-3 bg-danger status-light-center'></div>
                )}
                </td>
                <td>{lead_gen ? (
                    <div className='p-3 bg-success status-light-center'>{priority_lead_gen}</div>
                ) : (
                    <div className='p-3 bg-danger status-light-center'>{priority_lead_gen}</div>
                )}
                </td>
                <td>{pbx ? (
                    <div className='p-3 bg-success status-light-center'>{priority_pbx}</div>
                ) : (
                    <div className='p-3 bg-danger status-light-center'>{priority_pbx}</div>
                )}
                </td>
                <td>{pbx_ciao ? (
                    <div className='p-3 bg-success status-light-center'>{priority_pbx_ciao}</div>
                ) : (
                    <div className='p-3 bg-danger status-light-center'>{priority_pbx_ciao}</div>
                )}
                </td>
                <td>{pbx_h360 ? (
                    <div className='p-3 bg-success status-light-center'>{priority_pbx_h360}</div>
                ) : (
                    <div className='p-3 bg-danger status-light-center'>{priority_pbx_h360}</div>
                )}
                </td>
                <td>{forward ? (
                    <div className='p-3 bg-success status-light-center'>{priority_forward}</div>
                ) : (
                    <div className='p-3 bg-danger status-light-center'>{priority_forward}</div>
                )}
                </td>
                {<td>{Object.entries(settingsPresets).map(function (key) {
                    if (key[1].includes(queue_list) && queue_list !== "rmkt") {
                        return key[0].slice(0, 3);
                    }
                    else {
                        return null;
                    }
                })}
                    {queue_list === "rmkt" &&
                        "rmkt"
                    }
                </td>}
                <td style={{ color: '#1a73e8', fontWeight: 'bold' }}>{total_leads}</td>
                <td style={{ borderLeft: '2px solid #1a73e8' }}>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_in_attesa_nv_tmp')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_in_attesa_nv_ciao_tmp')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_in_attesa_nv_h360_tmp')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_in_attesa_inoltro_tmp')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td style={{ borderLeft: '2px solid #1a73e8' }}>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_in_attesa_default')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_in_sospeso')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_da_richiamare')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_inviato_whatsapp')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_inviata_email')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_inviato_sms')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_vendita_ecommerce')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td style={{ borderLeft: '2px solid #1a73e8' }}>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_apt_preso')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('cc_apt_preso_in_att_di_conferma')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('cc_apt_preso_spostato')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('cc_apt_preso_da_richiamare')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('cc_apt_preso_da_spostare')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('cc_vendita_da_confermare')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('cc_vendita_confermata')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td style={{ borderLeft: '2px solid #1a73e8' }}>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('cc_apt_preso_confermato')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('pai_apt_eseguito')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('pai_apt_non_eseguito')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('pai_apt_esito_in_att')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td style={{ borderLeft: '2px solid #1a73e8' }}>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('ls_prova_aperta')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('ls_decisione_in_corso')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('ls_pratica_asl_avviata')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('ls_apt_da_rifissare')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('ls_prova_prolungata')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('ls_contestato')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td style={{ borderLeft: '2px solid #1a73e8' }}>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('fc_ex_distante')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
                <td>{Object.entries(JSON.parse(presets)).map(function (key) {
                    if (key[0].includes('rmkt')) {
                        return key[1];
                    } else {
                        return null;
                    }
                })}
                </td>
            </tr>
        )
    }) : <tr>
        <td colSpan="9" className="text-center">No results found</td>
    </tr>

    return (
        <div className="table-responsive datatable-custom table-fix-head card" id="table-work-to-do">
            <div className="table-responsive text-nowrap">
                <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                    <thead>
                        <tr>
                            <th colSpan="13" className="mt-2 mb-2 table-header" style={{ borderLeft: 0 }}>
                                <h5 className="text-center text-white mb-0">OP</h5>
                            </th>
                            <th colSpan="1" className="mt-2 mb-2 table-header">
                            </th>
                            <th colSpan="4" className="mt-2 mb-2 table-header" style={{ borderLeft: '2px solid #1a73e8' }}>
                                <h5 className="text-center text-white mb-0">PBX</h5>
                            </th>
                            <th colSpan="7" className="mt-2 mb-2 table-header" style={{ borderLeft: '2px solid #1a73e8' }}>
                                <h5 className="text-center text-white mb-0">FC</h5>
                            </th>
                            <th colSpan="7" className="mt-2 mb-2 table-header" style={{ borderLeft: '2px solid #1a73e8' }}>
                                <h5 className="text-center text-white mb-0">CC</h5>
                            </th>
                            <th colSpan="4" className="mt-2 mb-2 table-header" style={{ borderLeft: '2px solid #1a73e8' }}>
                                <h5 className="text-center text-white mb-0">PAI</h5>
                            </th>
                            <th colSpan="6" className="mt-2 mb-2 table-header" style={{ borderLeft: '2px solid #1a73e8' }}>
                                <h5 className="text-center text-white mb-0">LS</h5>
                            </th>
                            <th colSpan="2" className="mt-2 mb-2 table-header" style={{ borderLeft: '2px solid #1a73e8' }}>
                                <h5 className="text-center text-white mb-0"> </h5>
                            </th>
                        </tr>
                        <tr>
                            <th className="table-rotate"><div>OP ID</div></th>
                            <th className="table-rotate"><div>OP NAME</div></th>
                            <th className="table-rotate"><div>LAST ACTIVITY</div></th>
                            <th className="table-rotate"><div>OUTBOUND CALL</div></th>
                            <th className="table-rotate"><div>WORKING TIME</div></th>
                            <th className="table-rotate"><div>ACR</div></th>
                            <th className="table-rotate"><div>CALENDAR AT EXPIRED</div></th>
                            <th className="table-rotate"><div>LEAD GEN</div></th>
                            <th className="table-rotate"><div>PBX SDU</div></th>
                            <th className="table-rotate"><div>PBX CIAO</div></th>
                            <th className="table-rotate"><div>PBX H360</div></th>
                            <th className="table-rotate"><div>FWD</div></th>
                            <th className="table-rotate"><div>PRESET</div></th>
                            <th className="table-rotate"><div>TOTALS</div></th>
                            <th className="table-rotate" style={{ borderLeft: '2px solid #1a73e8' }}><div>PBX SDU</div></th>
                            <th className="table-rotate"><div>PBX CIAO</div></th>
                            <th className="table-rotate"><div>PBX H360</div></th>
                            <th className="table-rotate"><div>FWD</div></th>
                            <th className="table-rotate" style={{ borderLeft: '2px solid #1a73e8' }}><div>IN ATTESA</div></th>
                            <th className="table-rotate"><div>IN SOSPESO</div></th>
                            <th className="table-rotate"><div>DA RICHIAMARE</div></th>
                            <th className="table-rotate"><div>INVIATO WHATSAPP</div></th>
                            <th className="table-rotate"><div>INVIATA EMAIL</div></th>
                            <th className="table-rotate"><div>INVIATO SMS</div></th>
                            <th className="table-rotate"><div>VENDITA ECOMMERCE</div></th>
                            <th className="table-rotate" style={{ borderLeft: '2px solid #1a73e8' }}><div>APT PRESO</div></th>
                            <th className="table-rotate"><div>APT IN ATT. CONF</div></th>
                            <th className="table-rotate"><div>APT SPOSTATO</div></th>
                            <th className="table-rotate"><div>DA RICHIAMARE</div></th>
                            <th className="table-rotate"><div>APT DA SPOSTARE</div></th>
                            <th className="table-rotate"><div>VENDITA DA CONF.</div></th>
                            <th className="table-rotate"><div>VENDITA CONFERMATA</div></th>
                            <th className="table-rotate" style={{ borderLeft: '2px solid #1a73e8' }}><div>APT CONFERMATO</div></th>
                            <th className="table-rotate"><div>APT ESEGUITO</div></th>
                            <th className="table-rotate"><div>APT NON ESEGUITO</div></th>
                            <th className="table-rotate"><div>APT ESITO IN ATTESA</div></th>
                            <th className="table-rotate" style={{ borderLeft: '2px solid #1a73e8' }}><div>PROVA APERTA</div></th>
                            <th className="table-rotate"><div>DEC. IN CORSO</div></th>
                            <th className="table-rotate"><div>PRATICA ASL AVV.</div></th>
                            <th className="table-rotate"><div>DA RIFISSARE</div></th>
                            <th className="table-rotate"><div>PROVE PROLUNGATA</div></th>
                            <th className="table-rotate"><div>CONTESTATO</div></th>
                            <th className="table-rotate" style={{ borderLeft: '2px solid #1a73e8' }}><div>EX DISTANTE</div></th>
                            <th className="table-rotate"><div>RMKT</div></th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {list}
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default OpSettings