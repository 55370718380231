import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import Layout from './components/Manager/Layout';
import Login from './components/Pages/Login';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
dayjs.locale('it');

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'development') {
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route path="/" element={<Layout />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route path="/" element={<Layout />} />
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}
