import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const logReleasesSlice = createSlice({
    name: 'logReleases',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogReleases.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchLogReleases.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchLogReleases.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const fetchLogReleases = createAsyncThunk('fetch/logReleases', async (_, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/log-releases');

        if (response.status === 200) {

            return response.data;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default logReleasesSlice.reducer;
