import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { getCurrentDay, getCurrentTime } from '../../helpers';

const statusSlice = createSlice({
    name: 'status',
    initialState: {
        data: [],
        lastStatusId: '',
        lastStatusName: '',
        message: '',
        isError: false
    },
    reducers: {
        setLastStatusId: (state, action) => {
            state.lastStatusId = action.payload;
        },
        setLastStatusName: (state, action) => {
            state.lastStatusName = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatus.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchStatus.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchStatus.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setLastStatusId, setLastStatusName, setMessage } = statusSlice.actions;

export const fetchStatus = createAsyncThunk('fetch/status', async (_, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/status');
        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export const getScriptText = createAsyncThunk('get/scriptText', async (_, { getState, dispatch }) => {

    let script_customer_joy;
    let contact_info;
    let script_text_business_name;
    let script_text_pbx;

    const state = getState();
    const dialer = state.dialer.data;
    const source = state.dialer.data.lead_sources?.source.toLowerCase() || '';

    switch (true) {
        case source.includes('ciao'):
            script_text_business_name = "Ciao Hearing"
            script_text_pbx = "800 900 755"
            break;
        case source.includes('h360'):
            script_text_business_name = "Hearing 360"
            script_text_pbx = "800 919 770"
            break;
        case source.includes('rs'):
            script_text_business_name = "ReSound"
            script_text_pbx = "800 95 00 78"
            break;
        case source.includes('be'):
            script_text_business_name = "Beltone"
            script_text_pbx = "800 95 00 78"
            break;
        case source.includes('fbc'):
            script_text_business_name = dialer.shop?.business_name
            script_text_pbx = "800 95 00 78"
            break;
        case source.includes('udp'):
            script_text_business_name = "Udiup"
            script_text_pbx = "800 95 00 78"
            break;
        default:
            script_text_business_name = "Specialisti dell'udito"
            script_text_pbx = "800 95 00 78"
            break;
    }

    const loggedOpName = state.auth.loggedOpName.split(' ')[0];
    const loggedId  = state.auth.loggedId;
    const opSettings = state.manager.data.op_settings;
    const filteredOpSettings = opSettings.filter(setting => setting.id === loggedId);
    const opPhone = filteredOpSettings[0].op_phone;
    const customerJoyToken = state.customerJoyToken.data.token;
    const shop_instructions = dialer.shop_info?.shop_instructions;
    const shop_name = `${dialer.shop?.business_name} ${dialer.shop_info?.shop_address} - ${dialer.shop_info?.shop_province} (${dialer.shop_info?.shop_code}) ${shop_instructions || ""}`;
    const final_text = `Le consigliamo di salvare il nostro numero in rubrica ${script_text_pbx} per eventuali future comunicazioni. Lo Staff di ${script_text_business_name}`;
    const sms_review_link = `https://www.specialistidelludito.it/reviews/shops/create/${dialer.id}?token=${dialer.token}`;
    const customer_name = dialer.customer_name === 'Sconosciuto' ? dialer.customer_name.replace('Sconosciuto', '') : " " + dialer.customer_name;

    // NUMERO VERDE INFO
    if (source.includes('nv')) {
        contact_info = 'alla chiamata al numero verde ricevuta';
    } else {
        contact_info = 'al modulo che ha compilato online';
    }

    // 1 IN ATTESA
    if (getCurrentDay() === 5) {
        script_customer_joy = `Servizio Clienti *${script_text_business_name}* %0a%0aSalve${customer_name}, stiamo cercando di metterci in contatto con lei in riferimento ${contact_info}.%0a%0aLa ricontatteremo Lunedì.%0a%0aTuttavia, se preferisce, può indicarci il giorno e l'orario più adatto tramite il seguente indirizzo: ${customerJoyToken}.%0a%0aCordiali saluti`;
    } else {
        script_customer_joy = `Servizio Clienti *${script_text_business_name}* %0a%0aSalve${customer_name}, stiamo cercando di metterci in contatto con lei in riferimento ${contact_info}.%0a%0aLa ricontatteremo domani alle ore ${getCurrentTime()}.%0a%0aTuttavia, se preferisce, può indicarci il giorno e l'orario più adatto tramite il seguente indirizzo: ${customerJoyToken}.%0a%0aCordiali saluti`;
    }

    // 2 IN SOSPESO > LIMIT ATTEMPTS > CIAO 
    const script_sospeso_ciao = `Salve sono ${loggedOpName} di CIAO Hearing. Se è ancora interessata agli Apparecchi Acustici CIAO ad € 1.499,00 (n. 2 Apparecchi Acustici Ricaricabili) mi contatti al Numero Verde 800900755 oppure al ${opPhone}.`;

    // 2 IN SOSPESO > LIMIT ATTEMPTS
    const script_sospeso = `Buongiorno${customer_name}, abbiamo provato a contattarla dopo la sua richiesta, mi può indicare quando ricontattarla?. Lo Staff di ${script_text_business_name}`;

    // 5 NUMERO INESISTENTE - 11 NUMERO NON CORRISPONDENTE ANAGRAFICA   
    const script_inesistente = `Salve${customer_name}, La stiamo contattando sul numero di cellulare da lei fornito senza successo. La preghiamo di fornirci il numero corretto per fissarle un appuntamento nel nostro centro. Lo Staff di ${script_text_business_name}`;

    // 17 APPUNTAMENTO PRESO + 51 APPUNTAMENTO CONFERMATO + 53 APPUNTAMENTO IN ATTESA DI CONFERMA
    const script_app_preso = `Salve${customer_name}, Le ricordiamo il suo appuntamento del giorno ${dialer.appointment_at}, presso il centro: ${shop_name}. ${final_text}`;

    // 19 APPUNTAMENTO PRESO A DOMICILIO
    const script_app_preso_domicilio = `Salve${customer_name}, le ricordiamo il suo appuntamento presso il suo domicilio del giorno ${dialer.appointment_at}, con gli audioprotesisti del centro: ${dialer.shop?.business_name}. ${final_text}`;

    // 42 VENDITA ECOMMERCE
    const script_vendita_ecommerce = `Salve${customer_name}, sono ${loggedOpName}, La contatto dal servizio cortesia clienti in merito al suo ordine di prodotti per Apparecchi Acustici. Mi faccia sapere la sua opinione sul nostro servizio e non esiti a contattarmi a questo numero per qualsiasi informazione aggiuntiva. Saluti`;

    // 55 APPUNTAMENTO SPOSTATO
    const script_app_spostato = `Salve${customer_name}, il suo appuntamento è stato spostato al giorno ${dialer.appointment_at}, presso il centro: ${shop_name}. ${final_text}`;

    // 101 APPUNTAMENTO ESEGUITO
    const script_app_eseguito = `Salve${customer_name}, per offrire un miglior servizio può dedicarci un minuto per condividere la sua esperienza presso il centro acustico? Lasciate la vostra valutazione cliccando il seguente link ${sms_review_link}`;

    switch (true) {

        case (dialer.lead_status_1?.id === 1 || dialer.lead_status_1?.id === 20):
            dispatch(setMessage(script_customer_joy));
            break;

        case (dialer.lead_status_1?.id === 2 && dialer.lead_sources.source.includes('CIAO')):
            dispatch(setMessage(script_sospeso_ciao));
            break;

        case (dialer.lead_status_1?.id === 2 && !dialer.lead_sources.source.includes('CIAO')):
            dispatch(setMessage(script_sospeso));
            break;

        case dialer.lead_status_1?.id === 5:
        case dialer.lead_status_1?.id === 11:
            dispatch(setMessage(script_inesistente));
            break;

        case (dialer.lead_status_1?.id === 17 && dialer.lead_status_2?.id === undefined):
            dispatch(setMessage(script_app_preso));
            break;

        case (dialer.lead_status_1?.id === 17 && dialer.lead_status_2?.id === 51 && dialer.lead_status_3?.id === undefined):
        case (dialer.lead_status_1?.id === 17 && dialer.lead_status_2?.id === 53 && dialer.lead_status_3?.id === undefined):
            dispatch(setMessage(script_app_preso));
            break;

        case (dialer.lead_status_1?.id === 17 && dialer.lead_status_2?.id === 55 && dialer.lead_status_3?.id === undefined):
            dispatch(setMessage(script_app_spostato));
            break;

        case (dialer.lead_status_1?.id === 19 && dialer.lead_status_2?.id === undefined):
            dispatch(setMessage(script_app_preso_domicilio));
            break;

        case (dialer.lead_status_1?.id === 19 && dialer.lead_status_2?.id === 51 && dialer.lead_status_3?.id === undefined):
        case (dialer.lead_status_1?.id === 19 && dialer.lead_status_2?.id === 53 && dialer.lead_status_3?.id === undefined):
        case (dialer.lead_status_1?.id === 19 && dialer.lead_status_2?.id === 55 && dialer.lead_status_3?.id === undefined):
            dispatch(setMessage(script_app_preso_domicilio));
            break;

        case dialer.lead_status_1?.id === 42:
            dispatch(setMessage(script_vendita_ecommerce));
            break;

        case dialer.lead_status_3?.id === 101 && dialer.lead_status_4?.id >= 150:
            dispatch(setMessage(script_app_eseguito));
            break;

        default:
            dispatch(setMessage(""));
            break;

    }

});

export const getLastStatus = createAsyncThunk('get/lastStatus', async (_, { getState, dispatch }) => {

    const state = getState();
    const dialer = state.dialer.data;

    if (dialer.lead_status_4?.id) {
        dispatch(setLastStatusId(dialer.lead_status_4.id))
        dispatch(setLastStatusName(dialer.lead_status_4.name))
    }
    else if (dialer.lead_status_3?.id) {
        dispatch(setLastStatusId(dialer.lead_status_3.id))
        dispatch(setLastStatusName(dialer.lead_status_3.name))
    }
    else if (dialer.lead_status_2?.id) {
        dispatch(setLastStatusId(dialer.lead_status_2.id))
        dispatch(setLastStatusName(dialer.lead_status_2.name))
    }
    else if (dialer.lead_status_1?.id) {
        dispatch(setLastStatusId(dialer.lead_status_1.id))
        dispatch(setLastStatusName(dialer.lead_status_1.name))
    }

});

export default statusSlice.reducer;
