import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material'
import { setGroupByLeadId, setLogCustomerJoySearchParams, setQueryPageCustomerJoyUsers, setRefreshLogCustomerJoyUser } from '../../../../redux/slices/logCustomerJoyUserSlice';
import { setQueryPageCustomerJoyToken, setRefreshLogCustomerJoyToken } from '../../../../redux/slices/logCustomerJoyTokenSlice';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const DefaultSearch = () => {

    const dispatch = useDispatch();
    const logCustomerJoySearchParams = useSelector((state) => state.logCustomerJoyUser.params);
    const { refreshLogCustomerJoyUser } = useSelector((state) => state.logCustomerJoyUser);
    const { refreshLogCustomerJoyToken } = useSelector((state) => state.logCustomerJoyToken);
    const status = useSelector((state) => state.status.data);
    const sources = useSelector((state) => state.sources.data);

    const handleSwitchGroupByLeadId= () => {
        dispatch(setGroupByLeadId(!logCustomerJoySearchParams.group_by_lead));
        dispatch(setRefreshLogCustomerJoyUser(!refreshLogCustomerJoyUser));
    }

    const handleKeyEnter = (event) => {
        if (event.key === 'Enter') {
            dispatch(setQueryPageCustomerJoyUsers(1));
            dispatch(setQueryPageCustomerJoyToken(1));
            dispatch(setRefreshLogCustomerJoyUser(!refreshLogCustomerJoyUser));
            dispatch(setRefreshLogCustomerJoyToken(!refreshLogCustomerJoyToken));
        }
    }

    return (
        <>
            <TextField
                sx={{ marginTop: 0 }}
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogCustomerJoySearchParams({ ...logCustomerJoySearchParams, lead_id: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Lead ID" id="lead_id" value={logCustomerJoySearchParams?.lead_id || ""}
            />

            <Autocomplete
                fullWidth
                sx={{ marginTop: 1 }}
                id="source"
                options={sources}
                getOptionLabel={(option) => option.source}
                value={sources.find(option => option.source === logCustomerJoySearchParams.source) || null}
                onChange={(event, option) => {
                    if (option) {
                        dispatch(setLogCustomerJoySearchParams({ ...logCustomerJoySearchParams, source: option.source }));
                    }
                    else {
                        dispatch(setLogCustomerJoySearchParams({ ...logCustomerJoySearchParams, source: null }));
                    }

                    dispatch(setRefreshLogCustomerJoyUser(!refreshLogCustomerJoyUser));
                    dispatch(setRefreshLogCustomerJoyToken(!refreshLogCustomerJoyToken));

                }}
                renderInput={(params) => <TextField fullWidth {...params} label="Source" />}
            />

            <TextField
                sx={{ marginTop: 2 }}
                type='text'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogCustomerJoySearchParams({ ...logCustomerJoySearchParams, customer_name: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Customer Name" id="customer_name" value={logCustomerJoySearchParams?.customer_name || ""}
            />

            <TextField
                sx={{ marginTop: 1 }}
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogCustomerJoySearchParams({ ...logCustomerJoySearchParams, customer_phone: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Customer Phone" id="customer_phone" value={logCustomerJoySearchParams?.customer_phone || ""}
            />


            {/******** STATUS 1 DROPDOWN ************/}
            <Autocomplete
                id="status1_id"
                options={status.status1}
                getOptionLabel={(option) => option.name}
                value={status.status1.find(option => option.id === logCustomerJoySearchParams.status1_id) || null}
                onChange={(event, option) => {

                    if (option) {
                        dispatch(setLogCustomerJoySearchParams({ ...logCustomerJoySearchParams, status1_id: option.id }));
                    } else {
                        dispatch(setLogCustomerJoySearchParams({ ...logCustomerJoySearchParams, status1_id: null }));
                    }

                    dispatch(setRefreshLogCustomerJoyUser(!refreshLogCustomerJoyUser));
                    dispatch(setRefreshLogCustomerJoyToken(!refreshLogCustomerJoyToken));

                }}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                renderInput={(params) => <TextField margin="normal" {...params} label="Status 1" />} />

            <TextField
                sx={{ marginTop: 1 }}
                type='text'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogCustomerJoySearchParams({ ...logCustomerJoySearchParams, user_answer: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="User Answer" id="user_answer" value={logCustomerJoySearchParams?.user_answer || ""}
            />

            <Stack spacing={2} direction="row" style={{ marginBottom: 10 }}>
                <FormControlLabel control={
                    <Switch
                        checked={logCustomerJoySearchParams.group_by_lead}
                        onChange={handleSwitchGroupByLeadId}
                        value={logCustomerJoySearchParams.group_by_lead}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Group By Lead ID" />
            </Stack>


        </>
    )
}

export default DefaultSearch