import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';

const initialParams = {
    page: 1,
    lead_id: "",
    source: "",
    customer_name: "",
    customer_phone: "",
    status1_id: "",
    user_answer: "",
    group_by_lead: false
};

const logCustomerJoyUserSlice = createSlice({
    name: 'logCustomerJoyUser',
    initialState: {
        data: [],
        params: initialParams,
        refreshLogCustomerJoyUser: false,
        isError: false
    },
    reducers: {
        setLogCustomerJoyUser: (state, action) => {
            state.data = action.payload;
        },
        setLogCustomerJoySearchParams: (state, action) => {
            state.params = action.payload;
        },
        setRefreshLogCustomerJoyUser: (state, action) => {
            state.refreshLogCustomerJoyUser = action.payload;
        },
        setResetCustomerJoyUserParams: (state) => {
            state.params = initialParams;
        },
        setQueryPageCustomerJoyUsers: (state, action) => {
            state.params.page = action.payload;
        },
        setGroupByLeadId: (state, action) => {
            state.params.group_by_lead = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogCustomerJoyUser .pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchLogCustomerJoyUser .fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchLogCustomerJoyUser .rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setLogCustomerJoyUser, setLogCustomerJoySearchParams, setRefreshLogCustomerJoyUser, setResetCustomerJoyUserParams, setQueryPageCustomerJoyUsers, setGroupByLeadId } = logCustomerJoyUserSlice.actions;

export const fetchLogCustomerJoyUser  = createAsyncThunk('fetch/customerJoy/user', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true));

        const state = getState();
        const token = state.auth.token;

        const { page, lead_id, source, customer_name, customer_phone, status1_id, user_answer, group_by_lead } = state.logCustomerJoyUser.params;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/log-customer-joy-users', {
            params: {
                page,
                lead_id,
                source,
                customer_name,
                customer_phone,
                status1_id,
                user_answer,
                group_by_lead 
            }
        });

        if (response.status === 200) {

            dispatch(setIsLoading(false));
            return response.data;

        } else {

            throw new Error('Error updating');

        }


    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default logCustomerJoyUserSlice.reducer;
