import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const problemsSlice = createSlice({
    name: 'problems',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProblems.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchProblems.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchProblems.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

//export const { } = problemsSlice.actions;

export const fetchProblems = createAsyncThunk('fetch/problems', async (_, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/problems');
        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default problemsSlice.reducer;
