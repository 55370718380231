import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { copyToClipboard } from '../../../helpers';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { setIsLoading } from '../../../redux/slices/managerSlice';
import { setQueryPageCustomerJoyUsers, setRefreshLogCustomerJoyUser } from '../../../redux/slices/logCustomerJoyUserSlice';

const LogCustomerJoyUsers = () => {

    const dispatch = useDispatch();
    const { refreshLogCustomerJoyUser } = useSelector((state) => state.logCustomerJoyUser);
    const logCustomerJoyUsers = useSelector((state) => state.logCustomerJoyUser.data.data);
    const { last_page, total } = useSelector((state) => state.logCustomerJoyUser.data);
    const { page } = useSelector((state) => state.logCustomerJoyUser.params);

    const goToPage = (event, value) => {
        dispatch(setIsLoading(true))
        dispatch(setQueryPageCustomerJoyUsers(value));
        dispatch(setRefreshLogCustomerJoyUser(!refreshLogCustomerJoyUser));
    }

    const list = total > 0 ? logCustomerJoyUsers.map((log) => {

        const { id: log_id, current_step, calendar_at, created_at, user_answer } = log;
        const { id: lead_id, source, customer_name, customer_phone } = log.leads;
        const { name: status1_id } = log.lead_status_1 || [];

        return (
            <tr key={log_id}>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(log_id) }}>{log_id}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(lead_id) }}>{lead_id}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(source) }}>{source}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_name) }}>{customer_name}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_phone) }}>{customer_phone}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(status1_id) }}>
                    <ul className='list-unstyled m-0'>
                        <li>{status1_id}</li>
                        {calendar_at &&
                            <li>{calendar_at}</li>
                        }
                    </ul>
                </td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(current_step) }}>{current_step}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(user_answer) }}>{user_answer}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(created_at) }}>{created_at}</td>
            </tr>
        )

    }) : <tr>
        <td colSpan="15" className="text-center">No Results found</td>
    </tr>

    return (
        <>
            <div className="table-responsive datatable-custom table-fix-head card" id="table-log-customer-joy">
                <div className="table-responsive text-nowrap">
                    <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                        <thead>
                            <tr>
                                <th colSpan="12" className="mt-2 mb-2">
                                    <h5 className="text-center text-white mb-0 pb-2">BY USERS ({total || 0})</h5>
                                </th>
                            </tr>
                            <tr>
                                <th>ID</th>
                                <th>LEAD ID</th>
                                <th>SOURCE</th>
                                <th>CUSTOMER NAME</th>
                                <th>CUSTOMER PHONE</th>
                                <th>STATUS</th>
                                <th>CURRENT STEP</th>
                                <th>USER ANSWER</th>
                                <th>CREATED AT</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {list}
                        </tbody>
                    </table>
                </div>
            </div>

            {total > 0 &&
                <>
                    <div className="row mt-4 mb-5">
                        <div className="col-12 d-flex justify-content-center">
                            <Stack spacing={2}>
                                <Pagination count={last_page} defaultPage={page} onChange={goToPage} color="primary" />
                            </Stack>
                        </div>
                    </div>
                </>
            }

        </>

    )

}

export default LogCustomerJoyUsers