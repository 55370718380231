import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import dayjs from 'dayjs';
import { setIsLoading } from './managerSlice';

const initialParams = {
    date_from: dayjs().startOf('month').format('YYYY-MM-DD'),
    date_to: dayjs().format('YYYY-MM-DD'),
    type_source: 'ALL',
    type_date: 'created_at',
    type_sort: 'sort_by_rate'
}

const dashboardOpSlice = createSlice({
    name: 'dashboardOp',
    initialState: {
        data: [],
        refreshDashboardOp: false,
        params: initialParams,
        isError: false
    },
    reducers: {
        setDashboardOp: (state, action) => {
            state.data = action.payload;
        },
        setDashboardOpParams: (state, action) => {
            state.params = action.payload;
        },
        setRefreshDashboardOp: (state, action) => {
            state.refreshDashboardOp = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardOp.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchDashboardOp.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchDashboardOp.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setDashboardOp, setDashboardOpParams, setRefreshDashboardOp } = dashboardOpSlice.actions;

export const fetchDashboardOp = createAsyncThunk('fetch/dashboardOp', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true))

        const state = getState();
        const { date_from, date_to, type_source, type_date, type_sort } = state.dashboardOp.params;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/dashboard', {
            params: {
                date_from,
                date_to,
                source: type_source,
                type_date,
                type_sort
            }
        });

        if (response.status === 200) {
            dispatch(setIsLoading(false))
            return response.data;

        } else {
            throw new Error('Error loading api');
        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default dashboardOpSlice.reducer;
