import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';
import { setIsLoading } from './managerSlice';
import { setNoteForm } from './actionBarTwoSlice';

const initialParams = {
    id: "",
    created_at: dayjs().format('DD/MM/YYYY HH:mm'),
    op_assigned: "",
    shop_id: "",
    shop_info_id: "",
    source: "",
    customer_name: "",
    customer_phone: "",
    customer_zipcode: "",
    customer_email: "",
    business_name: "",
    shop_address: ""
};

const addNewLeadSlice = createSlice({
    name: 'addNewLead',
    initialState: {
        data: [],
        params: initialParams,
        isError: false, 
    },
    reducers: {
        setAddNewLead: (state, action) => {
            state.params = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(storeNewLead.pending, (state) => {
                state.isError = false;
            })
            .addCase(storeNewLead.fulfilled, (state) => {
                state.data = [];
                state.params = initialParams;
                state.isError = false;
            })
            .addCase(storeNewLead.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setAddNewLead } = addNewLeadSlice.actions;

export const storeNewLead = createAsyncThunk('store/newLead', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true));

        const state = getState();
        const { created_at, shop_id, shop_info_id, source, customer_name, customer_phone, customer_zipcode, customer_email } = state.addNewLead.params;
        const loggedId = state.auth.loggedId;

        const response = await axios.post(`https://www.specialistidelludito.it/api/leads/store`,
            {
                shop_id: shop_id || null,
                shop_info_id: shop_info_id || null,
                created_at: dayjs(created_at, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
                op_assigned: loggedId,
                source,
                customer_name,
                customer_phone,
                customer_zipcode,
                customer_email: customer_email || '',
                privacy_policy: true
            });

        if (response.status === 200) {

            dispatch(setNoteForm(""));
            dispatch(setIsLoading(false));
            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;

    }

});

export default addNewLeadSlice.reducer;
