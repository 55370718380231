import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const logActionStoreSlice = createSlice({
    name: 'logActionStore',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {
       
    },
    extraReducers: (builder) => {
        builder
            .addCase(storeLogAction.pending, (state) => {
                state.isError = false;
            })
            .addCase(storeLogAction.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(storeLogAction.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

//export const { } = logActionStoreSlice.actions;

export const storeLogAction = createAsyncThunk('store/logAction', async ({ type, section, value, input_type }, { getState }) => {
    try {
        const state = getState();
        const token = state.auth.token;
        const { loggedId, loggedName } = state.auth;

        if (loggedName.includes('Operatore')) {
            if (token) {
                instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }

            const response = await instance.post(`/log-actions/store`, {
                op_logged: loggedId,
                type,
                section,
                value,
                input_type,
            });

            if (response.status === 200) {
                return; 
            } else {
                throw new Error('Error updating');
            }
        }
    } catch (error) {
        console.error('Error getting data:', error);
        throw error;
    }
});

export default logActionStoreSlice.reducer;
