import React from 'react';
import { useSelector } from 'react-redux';

const SduAppointmentsTable = () => {

  const sduAppointments = useSelector((state) => state.sduAppointments.data);

  const list = sduAppointments.map((sdu_appointments) => {

    const { id, customer_name, lead_status_1, lead_status_2, lead_status_3, lead_status_4, appointment_at } = sdu_appointments;

    return (
      <tr key={id}>
        <td>{id}</td>
        <td>{customer_name}</td>
        <td>
          <ul className='list-unstyled m-0'>
            <li>{lead_status_1?.name}</li>
            <li>{lead_status_2?.name}</li>
            <li>{lead_status_3?.name}</li>
            <li>{lead_status_4?.name}</li>
          </ul>
        </td>
        <td>{appointment_at}</td>
      </tr>
    )
  })

  return (
    <div className="table-responsive datatable-custom table-fix-head card" id="table-sdu-appointments">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="8" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">SDU APPOINTMENTS</h5>
              </th>
            </tr>
            <tr>
              <th>ID</th>
              <th>NAME/SURNAME</th>
              <th>STATUS</th>
              <th>APPOINTMENT AT</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {sduAppointments && sduAppointments?.length > 0 ? (
              list
            ) : (
              <tr className="text-primary card-subtitle mb-2">
                <td colSpan="4" >No Results</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )

}

export default SduAppointmentsTable
