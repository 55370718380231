import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { setDialer } from '../../../../../redux/slices/dialerSlice';

const ProblemsDropdown = () => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const problems = useSelector((state) => state.problems.data);

    return (
        <Autocomplete
            id="problem"
            options={problems}
            getOptionLabel={(option) => option.problem || dialer.lead_problems.problem}
            value={dialer.lead_problems?.problem ?? null}
            isOptionEqualToValue={(option, value) => option.problem === value}
            onChange={(event, option) => {
                if (option) {
                    dispatch(setDialer({ ...dialer, lead_problems: option }));
                } else {
                    dispatch(setDialer({ ...dialer, lead_problems: null }));
                }
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        {option.problem}
                    </li>
                );
            }}
            renderInput={(params) =>
                <TextField margin="normal" {...params} label="Problem" />} />
    )
}

export default ProblemsDropdown