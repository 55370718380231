import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';

const workToDoSlice = createSlice({
    name: 'workToDo',
    initialState: {
        data: [],
        workToDoType: 'ONLINE',
        isError: false,
        refreshWorkToDo: false,
    },
    reducers: {
        setRefreshWorkToDo: (state, action) => {
            state.refreshWorkToDo = action.payload;
        },
        setWorkToDoType: (state, action) => {
            state.workToDoType = action.payload;
        },
    },
});

export const { setRefreshWorkToDo, setWorkToDoType } = workToDoSlice.actions;

export const fetchWorkToDo = createAsyncThunk('fetch/workToDo', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true));

        const state = getState();
        const token = state.auth.token;
        const opSettings = state.manager.data.op_settings;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/op/1?calendar_status=off&paginate=off');

        if (response.status === 200) {

            opSettings.forEach(async(item) => {
                await instance.get(`/op/${item.id}`);
            })

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;

    } finally {

        dispatch(setIsLoading(false));

    }

});

export default workToDoSlice.reducer;
