import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { setTransfersParams, updateTransfers } from '../../../redux/slices/transfersSlices';
import { storeLogAction } from '../../../redux/slices/logActionStoreSlice';

const DropdownsSettingsTransfers = () => {

    const dispatch = useDispatch();
    const transfers = useSelector((state) => state.transfers.data);
    const manager = useSelector((state) => state.manager.data);
    const transfersParams = useSelector((state) => state.transfers.params);
    const { opIdFrom, opIdTo, opNameFrom, opStatusGroup } = useSelector((state) => state.transfers.params);
    const { currentModal } = useSelector((state) => state.modal);
    const statusGroupList = transfers.badges_status_group ? Object.entries(transfers.badges_status_group) : [];

    const handleTransfer = () => {
        dispatch(updateTransfers());
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/transfers',
                value: JSON.stringify(transfersParams),
                input_type: 'button'
            })
        );
    }

    return (

        <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>

            <Autocomplete
                fullWidth
                id="opIdFrom"
                options={manager.op_settings}
                getOptionLabel={(option) => option.op_name || opNameFrom}
                value={opNameFrom || null}
                isOptionEqualToValue={(option) => option.op_name === opNameFrom}
                getOptionDisabled={(option) => option.op_name === opNameFrom}
                onChange={(event, option) => {
                    if (option) {
                        dispatch(
                            setTransfersParams(
                                {
                                    ...transfersParams,
                                    opIdFrom: option.id,
                                    opNameFrom: option.op_name,
                                    opStatusGroup: null
                                })
                        );
                    }
                    else {
                        dispatch(
                            setTransfersParams(
                                {
                                    ...transfersParams,
                                    opIdFrom: '',
                                    opNameFrom: '',
                                    opStatusGroup: null
                                }));
                    }
                }}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.status === true ? <div className='p-3 bg-success status-light'></div> : <div className='p-3 bg-danger status-light'></div>} <div style={{ marginLeft: 10 }}>OP{option.id} - {option.op_name}</div>
                        </li>
                    );
                }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField fullWidth {...params} label="Transfer from" />}
            />

            {opIdFrom &&
                <Autocomplete
                    fullWidth
                    id="opStatusGroup"
                    options={statusGroupList}
                    getOptionLabel={(option) => option[0] || []}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setTransfersParams({ ...transfersParams, opStatusGroup: option[0] }));
                        }
                        else {
                            dispatch(setTransfersParams({ ...transfersParams, opStatusGroup: null }));
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option[0]} ({option[1]})
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status Group" />}
                />
            }

            {opIdFrom && opIdTo && opIdTo.length > 0 &&
                <Button variant="contained" onClick={() => handleTransfer(opIdFrom, opIdTo, opStatusGroup)}>Transfer</Button>
            }

        </Stack>

    )
}

export default DropdownsSettingsTransfers