import React from 'react';
import { useSelector } from 'react-redux';

const ItalyDbByCustomerZipcodeTable = () => {

    const italyDbByZipcode = useSelector((state) => state.italyDbByZipcode.data);
    const dialer = useSelector((state) => state.dialer.data);

    const list = Array.isArray(italyDbByZipcode) ? italyDbByZipcode.map((item) => {

        const { id, cap, comune, provincia, provinciasigla, regione } = item;

        return (
            <tr key={id}>
                <td className='text-center'>{cap}</td>
                <td className='text-center'>{comune}</td>
                <td className='text-center'>{provincia}</td>
                <td className='text-center'>{provinciasigla}</td>
                <td className='text-center'>{regione}</td>
            </tr>
        )

    }) : <tr>
        <td colSpan="8" className="text-center">Zipcode not found</td>
    </tr>

    return (
        <div className="table-responsive datatable-custom table-fix-head card" id="table-italy-db">
            <div className="table-responsive text-nowrap">
                <table className="table table-hover table table-borderless table-striped table-text-left table-align-middle">
                    <thead>
                        <tr>
                            <th className='text-center'>ZIPCODE</th>
                            <th className='text-center'>MUNICIPALITY</th>
                            <th className='text-center'>PROVINCE</th>
                            <th className='text-center'>SIGLA</th>
                            <th className='text-center'>REGION</th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {dialer.customer_zipcode > 0 ? (
                            list
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">Zipcode not found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default ItalyDbByCustomerZipcodeTable