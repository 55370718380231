import { createSlice } from '@reduxjs/toolkit';

const settingsPresetsSlice = createSlice({
    name: 'settingsPresets',
    initialState: {
        ordinary: 'cc_apt_preso_da_spostare,fc_in_attesa_nv_ciao_tmp,fc_in_attesa_nv_h360_tmp,fc_in_attesa_nvc_tmp,fc_in_attesa_assistenza_tmp,cc_vendita_da_confermare,fc_vendita_ecommerce,fc_in_attesa_inoltro_tmp,fc_in_attesa_nv_tmp,fc_in_attesa_iniziativa,fc_in_attesa_default,fc_apt_preso,cc_apt_preso_spostato,cc_apt_preso_in_att_di_conferma,fc_in_sospeso,fc_inviato_whatsapp,fc_inviata_email,fc_inviato_sms,fc_da_richiamare,cc_apt_preso_da_richiamare,ls_apt_da_rifissare,ls_contestato,pai_apt_non_eseguito,cc_apt_preso_confermato,pai_apt_esito_in_att,pai_apt_eseguito,ls_decisione_in_corso,ls_prova_aperta,ls_prova_prolungata,ls_pratica_asl_avviata,fc_ex_distante,rmkt',
        cc: 'cc_apt_preso_da_spostare,fc_in_attesa_nv_ciao_tmp,fc_in_attesa_nv_h360_tmp,fc_in_attesa_nvc_tmp,fc_in_attesa_assistenza_tmp,cc_vendita_da_confermare,fc_vendita_ecommerce,fc_apt_preso,cc_apt_preso_spostato,cc_apt_preso_in_att_di_conferma,fc_in_attesa_inoltro_tmp,fc_in_attesa_nv_tmp,fc_in_attesa_iniziativa,fc_in_attesa_default,fc_in_sospeso,fc_inviato_whatsapp,fc_inviata_email,fc_inviato_sms,fc_da_richiamare,cc_apt_preso_da_richiamare,ls_apt_da_rifissare,ls_contestato,pai_apt_non_eseguito,cc_apt_preso_confermato,pai_apt_esito_in_att,pai_apt_eseguito,ls_decisione_in_corso,ls_prova_aperta,ls_prova_prolungata,ls_pratica_asl_avviata,fc_ex_distante,rmkt',
        pai: 'cc_apt_preso_da_spostare,fc_in_attesa_nv_ciao_tmp,fc_in_attesa_nv_h360_tmp,fc_in_attesa_nvc_tmp,fc_in_attesa_assistenza_tmp,cc_vendita_da_confermare,fc_vendita_ecommerce,fc_in_attesa_inoltro_tmp,fc_in_attesa_nv_tmp,fc_in_attesa_iniziativa,fc_in_attesa_default,fc_apt_preso,cc_apt_preso_spostato,cc_apt_preso_in_att_di_conferma,pai_apt_non_eseguito,cc_apt_preso_confermato,pai_apt_esito_in_att,fc_in_sospeso,fc_inviato_whatsapp,fc_inviata_email,fc_inviato_sms,fc_da_richiamare,cc_apt_preso_da_richiamare,ls_apt_da_rifissare,ls_contestato,pai_apt_eseguito,ls_decisione_in_corso,ls_prova_aperta,ls_prova_prolungata,ls_pratica_asl_avviata,fc_ex_distante,rmkt',
        ee: 'cc_apt_preso_da_spostare,fc_in_attesa_nv_ciao_tmp,fc_in_attesa_nv_h360_tmp,fc_in_attesa_nvc_tmp,fc_in_attesa_assistenza_tmp,cc_vendita_da_confermare,fc_vendita_ecommerce,fc_in_attesa_inoltro_tmp,fc_in_attesa_nv_tmp,fc_in_attesa_iniziativa,fc_in_attesa_default,fc_apt_preso,cc_apt_preso_spostato,cc_apt_preso_in_att_di_conferma,fc_in_sospeso,fc_inviato_whatsapp,fc_inviata_email,fc_inviato_sms,fc_da_richiamare,cc_apt_preso_da_richiamare,ls_apt_da_rifissare,ls_contestato,pai_apt_non_eseguito,cc_apt_preso_confermato,pai_apt_esito_in_att,fc_ex_distante,rmkt',
        rmkt: 'rmkt',
        nvc: 'fc_in_attesa_nvc_tmp,fc_in_attesa_assistenza_tmp,fc_in_attesa_nvc_tmp_fake'
    },
    reducers: {

    },
});

//export const { } = settingsPresetsSlice.actions;

export default settingsPresetsSlice.reducer;
