import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { calendarAtEnabled } from '../../../../../helpers';
import { setDialer } from '../../../../../redux/slices/dialerSlice';

const CalendarAtPicker = () => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);

    function isSunday(date) {
        return date.day() === 0;
    }
    
    return (
        <>
            {((calendarAtEnabled().status1.includes(dialer.lead_status_1?.id) && dialer.lead_status_2?.id === undefined) || 
              (calendarAtEnabled().status2.includes(dialer.lead_status_2?.id) && dialer.lead_status_3?.id === undefined) || 
              (calendarAtEnabled().status3.includes(dialer.lead_status_3?.id) && dialer.lead_status_4?.id === undefined) || 
              (calendarAtEnabled().status4.includes(dialer.lead_status_4?.id))) &&
                <LocalizationProvider
                    id="calendar_at"
                    dateAdapter={AdapterDayjs}
                    adapterLocale="it">
                    <MobileDateTimePicker
                        label="Calendar At"
                        shouldDisableDate={isSunday}
                        value={dialer.calendar_at !== null ? dayjs(dialer.calendar_at, 'DD/MM/YYYY HH:mm') : null}
                        onChange={(newValue) => dispatch(setDialer({ ...dialer, calendar_at: dayjs(newValue).format('DD/MM/YYYY HH:mm') }))}
                        inputFormat="DD/MM/YYYY HH:mm"
                        minutesStep={5}
                        ampm={false}
                    />
                </LocalizationProvider>
            }
        </>
    )
}

export default CalendarAtPicker