import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { copyToClipboard } from '../../../helpers';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { deleteCustomerJoyToken, setQueryPageCustomerJoyToken, setRefreshLogCustomerJoyToken } from '../../../redux/slices/logCustomerJoyTokenSlice';
import { setIsLoading } from '../../../redux/slices/managerSlice';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const LogCustomerJoyTokens = () => {

    const dispatch = useDispatch();
    const logCustomerJoyTokens = useSelector((state) => state.logCustomerJoyToken.data.data);
    const { last_page, total } = useSelector((state) => state.logCustomerJoyToken.data);
    const { refreshLogCustomerJoyToken } = useSelector((state) => state.logCustomerJoyToken);
    const { page } = useSelector((state) => state.logCustomerJoyToken.params);

    const goToPage = (event, value) => {
        dispatch(setIsLoading(true))
        dispatch(setQueryPageCustomerJoyToken(value));
        dispatch(setRefreshLogCustomerJoyToken(!refreshLogCustomerJoyToken));
    }

    const handleClickDeleteCustomerJoyToken = (log_id) => {
        dispatch(deleteCustomerJoyToken(log_id))
        dispatch(setRefreshLogCustomerJoyToken(!refreshLogCustomerJoyToken));
    }

    const list = total > 0 ? logCustomerJoyTokens.map((log) => {

        const { id: log_id, expired_at, created_at } = log;
        const { id: lead_id, op_assigned, source, customer_name, customer_phone, calendar_at } = log.leads;
        const { name } = log.leads.lead_status_1 || [];

        return (
            <tr key={log_id}>
                <td>
                    <IconButton
                        variant="text"
                        sx={{
                            ml: 0,
                            borderRadius: "4px",
                            bgcolor: 'danger.main',
                            color: 'white',
                            ':hover': {
                                bgcolor: 'danger_secondary.main',
                                color: 'white',
                            },
                        }}
                        edge="end" color="inherit" aria-label="close" onClick={() => handleClickDeleteCustomerJoyToken(log_id)}>
                        <DeleteIcon />
                    </IconButton>
                </td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(log_id) }}>{log_id}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(lead_id) }}>{lead_id}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(op_assigned) }}>{op_assigned}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(source) }}>{source}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_name) }}>{customer_name}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_phone) }}>{customer_phone}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(name) }}>{name}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(expired_at) }}>{expired_at}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(calendar_at) }}>{calendar_at}</td>
                <td className='copy-clipboard' onClick={() => { copyToClipboard(created_at) }}>{created_at}</td>

            </tr>
        )

    }) : <tr>
        <td colSpan="15" className="text-center">No Results found</td>
    </tr>

    return (
        <>

            <div className="table-responsive datatable-custom table-fix-head card" id="table-log-customer-joy">
                <div className="table-responsive text-nowrap">
                    <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                        <thead>
                            <tr>
                                <th colSpan="12" className="mt-2 mb-2">
                                    <h5 className="text-center text-white mb-0 pb-2">TOKENS  ({total || 0})</h5>
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                <th>ID</th>
                                <th>LEAD ID</th>
                                <th>OP ASSIGNED</th>
                                <th>SOURCE</th>
                                <th>CUSTOMER NAME</th>
                                <th>CUSTOMER PHONE</th>
                                <th>STATUS</th>
                                <th>EXPIRED AT</th>
                                <th>CALENDAR AT</th>
                                <th>CREATED AT</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {list}
                        </tbody>
                    </table>
                </div>
            </div>

            {total > 0 &&
                <>
                    <div className="row mt-4 mb-5">
                        <div className="col-12 d-flex justify-content-center">
                            <Stack spacing={2}>
                                <Pagination count={last_page} defaultPage={page} onChange={goToPage} color="primary" />
                            </Stack>
                        </div>
                    </div>
                </>
            }
        </>

    )
}

export default LogCustomerJoyTokens