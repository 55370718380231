import React from 'react';
import { useSelector } from 'react-redux';
import { copyToClipboard } from '../../../../../helpers.js'
import EditIcon from '@mui/icons-material/Edit';

const ShopInfo = () => {

    const dialer = useSelector((state) => state.dialer.data);

    const { id, shop_home_visit, shop_lead_gen, shop_branch, shop_address, shop_province, shop_fraction, shop_address_shipment, shop_email_1, shop_email_2, shop_referent_1, shop_referent_2, shop_phone_1, shop_phone_2, shop_phone_3, shop_cell_phone_1, shop_cell_phone_2, shop_cell_phone_3 } = dialer.shop_info || {};

    return (
        <div className="table-responsive datatable-custom table-fix-head card" id="table-shop-info">
            <div className="table-responsive text-nowrap">
                <table className="table table-hover table table-borderless table-striped table-text-left table-align-middle">
                    <thead>
                        <tr>
                            <th colSpan="8" className="mt-2 mb-2">
                                <div className='d-flex'>
                                    <a style={{ marginRight: 10 }} href={`https://www.specialistidelludito.it/backend/shops/addresses/edit/${id}`} target="_blank" rel="noopener noreferrer">
                                        <EditIcon />
                                    </a>
                                    <h5 className="text-center text-white mt-1 mb-0 pb-2">SHOP INFO </h5>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {dialer.shop_info ? (
                            <>
                                <tr>
                                    <td>ID</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(id) }}>{id}</td>
                                </tr>
                                <tr>
                                    <td>VAT NUMBER</td>
                                    <td className='copy-clipboard'><span onClick={() => { copyToClipboard(dialer.shop?.business_vat) }}> {dialer.shop?.business_vat}</span></td>
                                </tr>
                                <tr>
                                    <td>HOME VISIT</td>
                                    <td>{shop_home_visit === 1 ? 'YES' : 'NO'}</td>
                                </tr>
                                <tr>
                                    <td>LEAD GEN</td>
                                    <td>
                                        {shop_lead_gen ? (
                                            <div className='p-3 w-2 bg-success status-light' style={{ width: 20 }}></div>
                                        ) : (
                                            <div className='p-3 w-2 bg-danger status-light' style={{ width: 20 }}></div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>PREMIUM</td>
                                    <td>
                                        {dialer.shop?.premium_subscription ? (
                                            <div className='p-3 w-2 bg-success status-light' style={{ width: 20 }}></div>
                                        ) : (
                                            <div className='p-3 w-2 bg-danger status-light' style={{ width: 20 }}></div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>CREDITS {dialer.shop.apt_credits}</td>
                                    <td>
                                        {dialer.apt_price === "0" ? (
                                            <div className='p-3 w-2 bg-success status-light' style={{ width: 20 }}></div>
                                        ) : (
                                            <>
                                                <div className='p-3 w-2 bg-danger status-light' style={{ width: 20 }}></div>
                                            </>
                                        )}
                                    </td>
                                </tr>
                                {dialer.apt_price !== "0" &&
                                    <>
                                        <tr>
                                            <td>APT PRICE</td>
                                            <td>{dialer.shop.apt_price}</td>
                                        </tr>
                                        <tr>
                                            <td>APT CARDS</td>
                                            <td>{dialer.shop.apt_cards}</td>
                                        </tr>
                                    </>
                                }
                                <tr>
                                    <td>BRANCH</td>
                                    <td>
                                        {shop_branch ? (
                                            <div className='p-3 w-2 bg-success status-light' style={{ width: 20 }}></div>
                                        ) : (
                                            <div className='p-3 w-2 bg-danger status-light' style={{ width: 20 }}></div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>ADDRESS</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_address) }}>{shop_address}</td>
                                </tr>
                                <tr>
                                    <td>PROVINCE</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_province) }}>{shop_province}</td>
                                </tr>
                                <tr>
                                    <td>FRACTION</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_fraction) }}>{shop_fraction}</td>
                                </tr>
                                <tr>
                                    <td>SHIPMENT ADDRESS</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_address_shipment) }}>{shop_address_shipment}</td>
                                </tr>
                                <tr>
                                    <td>EMAIL 1</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_email_1) }}>{shop_email_1}</td>
                                </tr>
                                <tr>
                                    <td>EMAIL 2</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_email_2) }}>{shop_email_2}</td>
                                </tr>
                                <tr>
                                    <td>REFERENT 1</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_referent_1) }}>{shop_referent_1}</td>
                                </tr>
                                <tr>
                                    <td>REFERENT 2</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(shop_referent_2) }}>{shop_referent_2}</td>
                                </tr>
                                <tr>
                                    <td>PHONE 1</td>
                                    <td><a href={`callto:${shop_phone_1}`}>{shop_phone_1}</a></td>
                                </tr>
                                <tr>
                                    <td>PHONE 2</td>
                                    <td><a href={`callto:${shop_phone_2}`}>{shop_phone_2}</a></td>
                                </tr>
                                <tr>
                                    <td>PHONE 3</td>
                                    <td><a href={`callto:${shop_phone_3}`}>{shop_phone_3}</a></td>
                                </tr>
                                <tr>
                                    <td>MOBILE PHONE 1</td>
                                    <td><a href={`callto:${shop_cell_phone_1}`}>{shop_cell_phone_1}</a></td>
                                </tr>
                                <tr>
                                    <td>MOBILE PHONE 2</td>
                                    <td><a href={`callto:${shop_cell_phone_2}`}>{shop_cell_phone_2}</a></td>
                                </tr>
                                <tr>
                                    <td>MOBILE PHONE 3</td>
                                    <td><a href={`callto:${shop_cell_phone_3}`}>{shop_cell_phone_3}</a></td>
                                </tr>
                                <tr>
                                    <td>BRANDS</td>
                                    <td className='copy-clipboard' onClick={() => { copyToClipboard(dialer.shop.business_brands) }}>{dialer.shop.business_brands}</td>
                                </tr>
                            </>
                        ) : (
                            <tr className="text-primary card-subtitle mb-2">
                                <td colSpan="3" >No Results</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    )


}

export default ShopInfo
