import { createSlice } from '@reduxjs/toolkit';

const snackbarSlices = createSlice({
    name: 'snackbar',
    initialState: {
        is_lead_unworked: false,
        is_saved_lead_success: false,
        is_saved_lead_error: false,
        is_saved_note_success: false,
        is_saved_note_error: false,
        is_shop_email_success: false,
        is_shop_email_error: false,
        is_lead_email_success: false,
        is_lead_email_error: false,
        is_order_email_success: false,
        is_order_email_error: false,
        is_lead_sms_success: false,
        is_lead_sms_error: false
    },
    reducers: {
        setSnackbar: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { setSnackbar } = snackbarSlices.actions;

export default snackbarSlices.reducer;
