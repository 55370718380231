import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
    return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
            <CircularProgress />
        </div>
    )
}

export default Loading