import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { setCurrentTabEditLead } from '../../../../../redux/slices/modalSlice';
import { storeLogAction } from '../../../../../redux/slices/logActionStoreSlice';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabsNav = () => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const { currentModal, currentTabEditLead } = useSelector((state) => state.modal);

    const handleOnChangeTab = (event, newValue) => {
        dispatch(setCurrentTabEditLead(newValue));
    }
    
    const handleGoToTab = (value) => {
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/tabs/' + value,
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    }

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
            {currentTabEditLead !== 5 &&
                <div className="d-flex justify-content-center">
                    <Tabs value={currentTabEditLead} onChange={handleOnChangeTab} aria-label="basic tabs example">
                        <Tab label="Lead" {...a11yProps(0)} onClick={() => handleGoToTab('lead')} />
                        <Tab label="Shop Associate" {...a11yProps(1)} onClick={() => handleGoToTab('shopAssociate')} />
                        <Tab label="Appointment/Shop Info" {...a11yProps(2)} onClick={() => handleGoToTab('appointment')} />
                        <Tab label="Log Notes" {...a11yProps(3)} onClick={() => handleGoToTab('logNotes')} />
                        <Tab label="Log Recordings" {...a11yProps(4)} onClick={() => handleGoToTab('logRecordings')} />
                        <Tab style={{ display: 'none' }} label="Note" {...a11yProps(5)} />
                    </Tabs>
                </div>
            }
        </Box>
    )
}

export default TabsNav