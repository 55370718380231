import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { setDialer } from '../../../../../redux/slices/dialerSlice';

const StatusDropdown = () => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const status = useSelector((state) => state.status.data);

    const setLeadRevenue = () => {

        const source_alias = "price_" + dialer.lead_sources.source.toLowerCase().replace(/\s/g, "_");

        if (dialer.lead_sources.price >= 0 && dialer.lead_status_3?.id === 101 && !dialer.disputed) {
            dispatch(setDialer({ ...dialer, revenue: dialer.lead_sources.price }))
        }
        else if (dialer.lead_sources.price < 0 && dialer.lead_status_3?.id === 101 && !dialer.disputed) {
            dispatch(setDialer({ ...dialer, revenue: dialer.shop[source_alias] }))
        }
        else {
            dispatch(setDialer({ ...dialer, revenue: 0 }))
        }
    }

    useEffect(() => {
        setLeadRevenue();
    }, [dialer.lead_sources.source, dialer.lead_sources.price, dialer.lead_status_1, dialer.lead_status_2, dialer.lead_status_3, dialer.lead_status_4, dialer.disputed]);

    return (
        <>
            {/******** STATUS 1 DROPDOWN ************/}
            <Autocomplete
                disableClearable
                id="status1_id"
                options={status.status1}
                getOptionLabel={(option) => option.name || dialer.lead_status_1.name}
                value={dialer.lead_status_1?.name ?? null}
                isOptionEqualToValue={(option, value) => option.name === value}
                onChange={(event, option) => {

                    if (option) {

                        dispatch(
                            setDialer(
                                {
                                    ...dialer,
                                    lead_status_1: option,
                                    lead_status_2: null,
                                    lead_status_3: null,
                                    lead_status_4: null,
                                    appointment_at: null,
                                    calendar_at: null
                                })
                        );

                    } else {
                        dispatch(setDialer({ ...dialer, lead_status_1: null }));
                    }

                }}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                renderInput={(params) => <TextField required margin="normal" {...params} label="Status 1" />} />

            {/******** STATUS 2 DROPDOWN ************/}
            {(dialer.lead_status_1?.id === 17 || dialer.lead_status_1?.id === 19 || dialer.lead_status_1?.id === 42) &&
                <Autocomplete
                    id="status2_id"
                    options={status.status2}
                    getOptionLabel={(option) => option.name || dialer.lead_status_2.name}
                    value={dialer.lead_status_2?.name ?? null}
                    isOptionEqualToValue={(option, value) => option.name === value}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(
                                setDialer(
                                    {
                                        ...dialer,
                                        lead_status_2: option,
                                        lead_status_3: null,
                                        lead_status_4: null,
                                        calendar_at: null
                                    })
                            );
                        } else {
                            dispatch(setDialer({ ...dialer, lead_status_2: null }))
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField margin="normal" {...params} label="Status 2" />} />
            }

            {/******** STATUS 3 DROPDOWN ************/}
            {(dialer.lead_status_2?.id === 51 || dialer.lead_status_2?.id === 55) &&
                <Autocomplete
                    id="status3_id"
                    options={status.status3}
                    getOptionLabel={(option) => option.name || dialer.lead_status_3.name}
                    value={dialer.lead_status_3?.name ?? null}
                    isOptionEqualToValue={(option, value) => option.name === value}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(
                                setDialer(
                                    {
                                        ...dialer,
                                        lead_status_3: option,
                                        lead_status_4: null,
                                        calendar_at: null,
                                        disabled: false
                                    })
                            );
                        } else {
                            dispatch(setDialer({ ...dialer, lead_status_3: null }));
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField margin="normal" {...params} label="Status 3" />} />
            }

            {/******** STATUS 4 DROPDOWN ************/}
            {dialer.lead_status_3?.id &&
                <Autocomplete
                    id="status4_id"
                    options={status.status4}
                    getOptionLabel={(option) => option.name || dialer.lead_status_4.name}
                    value={dialer.lead_status_4?.name ?? null}
                    isOptionEqualToValue={(option, value) => option.name === value}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(
                                setDialer(
                                    {
                                        ...dialer,
                                        lead_status_4: option,
                                        calendar_at: null
                                    })
                            );
                        } else {
                            dispatch(setDialer({ ...dialer, lead_status_4: null }));
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField required margin="normal" {...params} label="Status 4" />} />
            }


            {/******** DEVICE REVENUE ************/}

            {([151, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 190, 191].includes(dialer.lead_status_4?.id)) &&
                <TextField
                    fullWidth
                    id="device_revenue"
                    label="Device revenue"
                    margin="normal"
                    value={dialer.device_revenue}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    onChange={event => dispatch(setDialer({ ...dialer, device_revenue: event.target.value }))}
                />
            }

            {dialer.lead_status_3?.id === 101 &&
                <TextField
                    fullWidth
                    id="revenue"
                    label="Lead revenue"
                    margin="normal"
                    value={dialer.revenue}
                    InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                />
            }

            {/******** DISPUTED TOGGLE ************/}
            {(dialer.lead_status_4?.id === 188) &&
                <div className='toggle-custom'>
                    <FormControlLabel control={<Switch id="disputed" value={dialer.disputed ? true : false} checked={dialer.disputed ? true : false} onChange={event => dispatch(setDialer({ ...dialer, disputed: event.target.checked }))} />} label="disputed" labelPlacement="end" />
                </div>
            }

        </>
    )
}

export default StatusDropdown