import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';

const logNotesByCustomerPhoneSlice = createSlice({
    name: 'logNotesByCustomerPhone',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLogNotesByCustomerPhone.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchLogNotesByCustomerPhone.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchLogNotesByCustomerPhone.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

//export const { } = logNotesByCustomerPhonelice.actions;

export const fetchLogNotesByCustomerPhone = createAsyncThunk('fetch/logNotesByCustomerPhone', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true));

        const state = getState();
        const currentLeadId = getState().manager.currentLeadId
        const customerPhone = getState().dialer.data.customer_phone;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/log-notes`, {
            params: {
                lead_id: currentLeadId,
                customer_phone: customerPhone,
            }
        });

        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;

    } finally {
        dispatch(setIsLoading(false));
    }


});


export default logNotesByCustomerPhoneSlice.reducer;
