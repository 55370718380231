import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setSnackbar } from './snackbarSlices';

const managerSlice = createSlice({
    name: 'manager',
    initialState: {
        data: [],
        countdownSeconds: 30,
        currentLeadId: "",
        isHistoryLead: false,
        isLoading: true,
        isLoadingSmall: false,
        dialerListQueryPage: 1,
        refreshManager: false,
        isError: false
    },
    reducers: {
        setCurrentLeadId: (state, action) => {
            state.currentLeadId = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingSmall: (state, action) => {
            state.isLoadingSmall = action.payload;
        },
        setIsHistoryLead: (state, action) => {
            state.isHistoryLead = action.payload;
        },
        setDialerListQueryPage: (state, action) => {
            state.dialerListQueryPage = action.payload;
        },
        setStatus: (state, action) => {
            const { dataitemIndex, newValue } = action.payload;
            state.data.op_settings[dataitemIndex].status = newValue;
        },
        setWorkingTime: (state, action) => {
            const { dataitemIndex, newStartWorkingDate, newEndWorkingDate } = action.payload;
            if (dataitemIndex !== -1) {
                const selectedItem = state.data.op_settings[dataitemIndex];
                selectedItem.start_working_time = newStartWorkingDate;
                selectedItem.end_working_time = newEndWorkingDate;
            }
        },
        setAssigns: (state, action) => {
            const { dataitemIndex, propertyName, newValue } = action.payload;
            if (dataitemIndex !== -1) {
                const selectedItem = state.data.op_settings[dataitemIndex];
                selectedItem[propertyName] = newValue;
            }
        },
        setCalendar: (state, action) => {
            const { dataitemIndex, newValue } = action.payload;
            state.data.op_settings[dataitemIndex].calendar = newValue;
        },
        setPresets: (state, action) => {
            const { dataitemIndex, newValue } = action.payload;
            state.data.op_settings[dataitemIndex].queue_list = newValue;
        },
        setRefreshManager: (state, action) => {
            state.refreshManager = action.payload;
        },
        setCountdownSeconds: (state, action) => {
            state.countdownSeconds = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchManager.pending, (state) => {
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(fetchManager.fulfilled, (state, action) => {
                state.data = action.payload;
                state.currentLeadId = state.data.dialer_list.data[0].id;
                state.isError = false;
                state.isLoading = false;
            })
            .addCase(fetchManager.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
                state.isLoading = false;
            })
    },
});

export const { setCurrentLeadId, setIsLoading, setIsLoadingSmall, setIsHistoryLead, setDialerListQueryPage, setStatus, setWorkingTime, setAssigns, setCalendar, setPresets, setRefreshManager, setCountdownSeconds } = managerSlice.actions;

export const fetchManager = createAsyncThunk('fetch/manager', async (_, { getState, dispatch }) => {

    try {

        const state = getState();
        const { currentLeadId, dialerListQueryPage } = state.manager;
        const { loggedId, token } = state.auth;
        const snackbar = state.snackbar;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/op/${loggedId || 1}`, {
            params: {
                page: dialerListQueryPage,
            }
        });

        if (response.data.dialer_list.data[0].id === currentLeadId) {
            dispatch(setSnackbar({ ...snackbar, is_lead_unworked: true }))
        }

        if (response.status === 200) {

            return response.data;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;

    } finally {

        const { loggedId } =  getState().auth;

        await instance.put(`/last-activity`, { op_id: loggedId });

    }

});

export const updateSettings = createAsyncThunk('update/manager/settings', async ({ dataitemIndex, id, property }, { getState }) => {

    try {

        const state = getState();
        const { token } = state.auth;
        const value = state.manager.data.op_settings[dataitemIndex][property];

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/settings/assigns/update/${id}`,
            {
                property,
                value
            });

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;
    }

});

export const updatePriorityByProperty = createAsyncThunk('update/manager/settings/resetPriority', async (property, { getState }) => {

    try {

        const state = getState();
        const { token } = state.auth;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/settings/assigns/reset-priority-by-property`,
            {
                property
            });

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;
    }

});

export const updatePriorityAll = createAsyncThunk('update/manager/settings/resetPriorityAll', async (property, { getState }) => {

    try {

        const state = getState();
        const { token } = state.auth;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put('/settings/assigns/reset-priority-all');

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;
    }

});

export default managerSlice.reducer;
