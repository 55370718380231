import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import { setLogNotesSearchParams, setQueryPage, setRefreshLogNotesSearch } from '../../../../redux/slices/logNotesSearchSlice';

const DefaultSearch = () => {

    const dispatch = useDispatch();
    const logNotesSearchParams = useSelector((state) => state.logNotesSearch.params);
    const { refreshLogNotesSearch } = useSelector((state) => state.logNotesSearch);

    const handleKeyEnter = (event) => {
        if (event.key === 'Enter') {
            dispatch(setRefreshLogNotesSearch(!refreshLogNotesSearch));
            dispatch(setQueryPage(1));
        }
    }

    return (
        <>
            <TextField
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, id: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="ID" id="id" value={logNotesSearchParams.id}
            />

            <TextField
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, lead_id: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Lead ID" id="lead_id" value={logNotesSearchParams.lead_id}
            />

            <TextField
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, customer_name: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Customer Name" id="customer_name" value={logNotesSearchParams.customer_name}
            />

            <TextField
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, customer_phone: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Customer Phone" id="customer_phone" value={logNotesSearchParams.customer_phone}
            />

            <TextField
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLogNotesSearchParams({ ...logNotesSearchParams, note: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Note" id="note" value={logNotesSearchParams.note}
            />

        </>
    )
}

export default DefaultSearch