import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Alert from '@mui/material/Alert';
import { setDialer } from '../../../../../redux/slices/dialerSlice';

const AppointmentAtPicker = () => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);

    function isSunday(date) {
        return date.day() === 0;
    }

    const isPast = () => {
        const now = dayjs();
        const appointmentAt = dayjs(dialer.appointment_at, 'DD/MM/YYYY HH:mm');
        const isAppointmentExpired = now.isAfter(appointmentAt);
        return isAppointmentExpired ? <Alert sx={{ marginTop: 1 }} severity="error">APPOINTMENT DATE HAS EXPIRED!</Alert> : '';
    }

    return (
        <>
            {(dialer.lead_status_1.id === 17 || dialer.lead_status_1.id === 19) &&
                <>
                    <LocalizationProvider
                        id="appointment_at"
                        dateAdapter={AdapterDayjs}
                        adapterLocale="it">
                        <MobileDateTimePicker
                            label="Appointment At"
                            shouldDisableDate={isSunday}
                            value={dayjs(dialer.appointment_at, 'DD/MM/YYYY HH:mm')}
                            onChange={(newValue) => dispatch(setDialer({ ...dialer, appointment_at: dayjs(newValue).format('DD/MM/YYYY HH:mm') }))}
                            inputFormat="DD/MM/YYYY HH:mm"
                            minutesStep={5}
                            ampm={false}
                            style={{ width: '100%' }}
                        />
                    </LocalizationProvider>


                    {isPast()}

                </>
            }
        </>
    )
}

export default AppointmentAtPicker