import React from 'react';
import { useSelector } from 'react-redux';

const ShopOpeningTimeTable = () => {

  const dialer = useSelector((state) => state.dialer.data);

  const list = dialer.shop_info?.shop_availabilities?.map((shop_availabilities) => {

    const { id, day, start_am, end_am, start_pm, end_pm } = shop_availabilities;

    let day_name;

    switch (day) {
      case "1":
        day_name = 'Lunedì';
        break;
      case "2":
        day_name = 'Martedì';
        break;
      case "3":
        day_name = 'Mercoledì';
        break;
      case "4":
        day_name = 'Giovedì';
        break;
      case "5":
        day_name = 'Venerdì';
        break;
      case "6":
        day_name = 'Sabato';
        break;
      default:
        day_name = '';
        break;
    }

    return (
      <tr key={id}>
        <td>{day_name}</td>
        <td>{start_am && start_am !== '00:00:00' ? start_am.slice(0, -3) : ''} {end_am && end_am !== '00:00:00' ? end_am.slice(0, -3) : ''}</td>
        <td>{start_am && start_pm !== '00:00:00' ? start_pm.slice(0, -3) : ''} {end_pm && end_pm !== '00:00:00' ? end_pm.slice(0, -3) : ''}</td>
      </tr>
    )
  })

  return (
    <div className="table-responsive datatable-custom table-fix-head card" id="table-shop-opening-time">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="8" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">SHOP OPENING TIME</h5>
              </th>
            </tr>
            <tr>
              <th>DAY</th>
              <th>AM</th>
              <th>PM</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {dialer.shop_info?.shop_availabilities?.length > 0 ? (
              list
            ) : (
              <tr className="text-primary card-subtitle mb-2">
                <td colSpan="3">No Results</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )


}

export default ShopOpeningTimeTable
