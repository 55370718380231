import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import ListIcon from '@mui/icons-material/List';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import { setCurrentModal } from '../../redux/slices/modalSlice';
import { setIsHistoryLead, setDialerListQueryPage, setRefreshManager, setCountdownSeconds } from '../../redux/slices/managerSlice';
import Menu from '@mui/material/Menu';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { calendarAtEnabled } from '../../helpers';
import { updateDialerPostponeCalendar, updateDialerTransferLead, updateDialerTransferLeadToOp7 } from '../../redux/slices/dialerNavbarSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';

const DialerNavbar = () => {

  const dispatch = useDispatch();
  const manager = useSelector((state) => state.manager.data);
  const dialer = useSelector((state) => state.dialer.data);
  const historyLead = useSelector((state) => state.historyLead.data);
  const { currentModal } = useSelector((state) => state.modal);
  const { isHistoryLead, isLoading, refreshManager, countdownSeconds } = useSelector((state) => state.manager);
  const { loggedId } = useSelector((state) => state.auth);

  // MENU ACTIONS
  const [anchorElCalendarMenu, setAnchorElCalendarMenu] = useState(null);
  const openCalendarMenu = Boolean(anchorElCalendarMenu);

  // REFRESH DIALER
  const handleClickRefreshDialer = () => {
    dispatch(setRefreshManager(!refreshManager));
    dispatch(setDialerListQueryPage(1))
    dispatch(
      storeLogAction({
        type: "manager",
        section: 'manager/dialer/menu/refreshDialer',
        value: JSON.stringify(dialer),
        input_type: 'button'
      })
    );
  }

  // OPEN EDIT MODAL
  const handleClickModalLeadEdit = (value) => {
    dispatch(setCurrentModal(value))
    dispatch(
      storeLogAction({
        type: "manager",
        section: 'manager/dialer/menu/editLead',
        value: dialer.id,
        input_type: 'button'
      })
    );
  }

  // SHOW DIALER LIST
  const handleClickIsDialerList = () => {
    dispatch(setIsHistoryLead(false))
    dispatch(
      storeLogAction({
        type: "manager",
        section: 'manager/dialer/menu/dialerList',
        value: JSON.stringify(dialer),
        input_type: 'button'
      })
    );
  }

  // SHOW HISTORY LEAD
  const handleClickIsHistoryLead = () => {
    dispatch(setIsHistoryLead(true))
    dispatch(
      storeLogAction({
        type: "manager",
        section: 'manager/dialer/menu/historyLeadList',
        value: JSON.stringify(dialer),
        input_type: 'button'
      })
    );
  }

  // MENU OPEN
  const handleClickCalendarMenu = (event) => {
    setAnchorElCalendarMenu(event.currentTarget);
  };
  const handleCloseCalendarMenu = () => {
    setAnchorElCalendarMenu(null);
  }

  // MENU ACTIONS
  const handleClickTransferLead = async () => {
    dispatch(updateDialerTransferLead());
    dispatch(setRefreshManager(!refreshManager))
    dispatch(
      storeLogAction({
        type: "trasnferLead",
        section: 'manager/dialer/menu',
        value: JSON.stringify(dialer),
        input_type: 'button'
      })
    );
  };

  const handleClickTransferLeadToOP7 = async () => {
    dispatch(updateDialerTransferLeadToOp7());
    dispatch(setRefreshManager(!refreshManager))
    dispatch(
      storeLogAction({
        type: "transferLeadToOp7",
        section: 'manager/dialer/menu',
        value: JSON.stringify(dialer),
        input_type: 'button'
      })
    );
  };


  const handleClickPostponeCalendar = async (e) => {
    let addMinutes = e.target.getAttribute('data-minutes');
    let now = new Date();
    let roundData = roundToNearest15(now);
    roundData.setMinutes(roundData.getMinutes() + parseInt(addMinutes));
    dispatch(updateDialerPostponeCalendar(getFormattedDate(roundData)));
    dispatch(setRefreshManager(!refreshManager))
    dispatch(
      storeLogAction({
        type: "postponeCalendar",
        section: 'manager/dialer/menu',
        value: JSON.stringify(dialer) + " min:" + addMinutes,
        input_type: 'button'
      })
    );
  }

  const roundToNearest15 = (date) => {
    const minutes = 15;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.round(date.getTime() / ms) * ms);
  }

  const getFormattedDate = (date) => {
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + (date.getDate())).slice(-2);
    var year = date.getFullYear();
    var hour = ("0" + (date.getHours())).slice(-2);
    var min = ("0" + (date.getMinutes())).slice(-2);
    var seg = ("0" + (date.getSeconds())).slice(-2);
    return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + seg;
  }

  const getOnlineOpCount = () => {

    let count = 0;

    manager.op_settings.map(function (op) {
      if (op.status === true) {
        count = count + 1;
      }
      return true;
    })

    return count;

  }

  const countdown = () => {

    const timer = setTimeout(function () {
      if (countdownSeconds > 0) {
        dispatch(setCountdownSeconds(countdownSeconds - 1));
      } else {
        dispatch(setRefreshManager(!refreshManager))
        dispatch(setCountdownSeconds(30));
      }
    }, 1000)

    return () => {
      clearTimeout(timer)
    }

  }

  useEffect(() => {
    if (!isLoading && loggedId === 7 && currentModal === "") {
      countdown();
    }
  }, [isLoading, countdownSeconds]);

  return (

    <div id="dialer-navbar-wrapper">
      <h5 className="card-header-title d-flex justify-content-center mt-3 pd-0 mb-1 text-white">DIALER</h5>
      <div className="mt-0 mb-3 d-flex justify-content-center demo-inline-spacing" id="dialer-navbar">

        {/* REFRESH DIALER */}
        <IconButton
          className='dialer-button'
          onClick={handleClickRefreshDialer}>
          <RefreshIcon fontSize="small" />
        </IconButton>

        {/* EDIT LEAD */}
        {(manager && manager.totals > 1) &&
          <IconButton
            className='dialer-button'
            onClick={() => handleClickModalLeadEdit('modalLeadEdit')}>
            <EditIcon fontSize="small" />
          </IconButton>
        }

        {/* SHOW DIALER LIST */}
        <IconButton
          className='dialer-button'
          onClick={handleClickIsDialerList}>
          <ListIcon fontSize="small" />
        </IconButton>

        {/* SHOW HISTORY LEAD LIST */}
        {((historyLead && historyLead.length > 1 && manager && manager.totals > 1)) &&
          <IconButton
            className={'dialer-button-secondary ' + (isHistoryLead ? 'active' : '')}
            onClick={handleClickIsHistoryLead}>
            <RestoreIcon fontSize="small" />
          </IconButton>
        }

        {/* CALENDAR MENU */}
        {manager && manager.totals > 0 &&
          <>
            <IconButton
              className='dialer-button'
              aria-label="more"
              id="long-button-actions"
              aria-controls={openCalendarMenu ? 'long-menu-actions' : undefined}
              aria-expanded={openCalendarMenu ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClickCalendarMenu}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>

            <Menu
              id="long-menu-actions"
              MenuListProps={{
                'aria-labelledby': 'long-button-actions',
              }}
              anchorEl={anchorElCalendarMenu}
              open={openCalendarMenu}
              onClose={handleCloseCalendarMenu}
              PaperProps={{
                style: {
                  maxHeight: 1000,
                  width: '20ch'
                },
              }}
            >

              <MenuItem disabled style={{ backgroundColor: '#0047a5', opacity: 1, color: '#fff' }}>Actions</MenuItem>
              {getOnlineOpCount() > 1 &&
                <MenuItem onClick={handleClickTransferLead}>Transfer Lead</MenuItem>
              }
              <MenuItem onClick={handleClickTransferLeadToOP7}>Transfer <ArrowRightAltIcon /> OP7</MenuItem>
              {((calendarAtEnabled().status1.includes(dialer?.lead_status_1?.id) && dialer?.lead_status_2?.id === undefined) ||
                (calendarAtEnabled().status2.includes(dialer?.lead_status_2?.id) && dialer?.lead_status_3?.id === undefined) ||
                (calendarAtEnabled().status3.includes(dialer?.lead_status_3?.id) && dialer?.lead_status_4?.id === undefined) ||
                calendarAtEnabled().status4.includes(dialer?.lead_status_4?.id)
              ) &&
                <div>
                  <MenuItem disabled style={{ backgroundColor: '#0047a5', opacity: 1, color: '#fff' }}>Calendar</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="10">10 Minutes</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="30">30 Minutes</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="60">1 Hour</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="120">2 Hours</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="1440">Tomorrow</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="2880">In Two Days</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="10080">1 Week</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="44640">1 Month</MenuItem>
                  <MenuItem onClick={handleClickPostponeCalendar} data-minutes="267840">6 Months</MenuItem>
                </div>
              }
            </Menu>
          </>
        }

        {loggedId === 7 &&
          <Box sx={{ pt: 1 }}>
            <Typography sx={{ marginLeft: '10px', pt: 1 }}>
              {countdownSeconds}
            </Typography>
          </Box>
        }

      </div>
    </div>

  )
}

export default DialerNavbar