import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import { setSnackbar } from '../redux/slices/snackbarSlices';

const SnackBar = () => {

    const dispatch = useDispatch();
    const { currentModal, currentTabEditLead } = useSelector((state) => state.modal);
    const { loggedId } = useSelector((state) => state.auth);
    const dialer = useSelector((state) => state.dialer.data);
    const log_notes_in_sospeso_count = useSelector((state) => state.logNotesByLeadId.data.log_notes_in_sospeso_count);
    const currentOp = useSelector((state) => {
        const opSettings = state.manager.data.op_settings;
        if (opSettings && opSettings.length > loggedId - 1) {
            return opSettings[loggedId - 1];
        }
        return "";
    });
    const snackbar = useSelector((state) => state.snackbar);

    return (
        <>

            <Stack spacing={2} sx={{ width: '100%' }}>

                {/* NEW VERSION */}
                <Snackbar id="snackbar_new_app_version" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} open={true}>
                    <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                        NUOVA VERSIONE DISPONIBILE. AGGIORNARE APP SHIFT + F5
                    </Alert>
                </Snackbar>


                {/* COPIED */}
                <Snackbar id="snackbar_copied_to_clipboard" anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={true}>
                    <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                        COPIED
                    </Alert>
                </Snackbar>

                {/* CAPS LOCK ERROR */}
                <ReactIsCapsLockActive>
                    {active =>
                        <>
                            {active ?
                                <Stack spacing={2} sx={{ width: '100%' }}>
                                    <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} style={{ marginBottom: 120 }}>
                                        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                                            CAPS LOCK
                                        </Alert>
                                    </Snackbar>
                                </Stack> :
                                ""
                            }
                        </>
                    }
                </ReactIsCapsLockActive>

                {/* ATTEMPTS LIMIT REACHED! IN_SOSPESO AFTER 3 NOTE WITH 'IN SOSPESO' */}
                {currentTabEditLead === 5 && dialer.lead_status_1?.id === 2 && log_notes_in_sospeso_count === 3 &&
                    <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} style={{ marginBottom: 60 }}>
                        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                            ATTEMPTS LIMIT REACHED!
                        </Alert>
                    </Snackbar>
                }

                {/* ACR ERROR */}
                {currentOp && currentOp.acr_error >= 30 &&
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                                ACR ERROR {currentOp.acr_error} min.
                            </Alert>
                        </Snackbar>
                    </Stack>
                }


                {/* SNACKBAR SUCCESS / ERRORS */}

                <Stack spacing={2} sx={{ width: '100%' }}>

                    {snackbar.is_lead_unworked && (loggedId !== "" && loggedId !== "7" && currentModal === "") &&
                        <Snackbar autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_lead_unworked} onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_unworked: false }))}>
                            <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_unworked: false }))} severity="error" variant="filled" sx={{ width: '100%' }}>
                                LEAD UNWORKED!
                            </Alert>
                        </Snackbar>
                    }

                    <Snackbar autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_saved_lead_success} onClose={() => dispatch(setSnackbar({ ...snackbar, is_saved_lead_success: false }))} >
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_saved_lead_success: false }))} severity="success" variant="filled" sx={{ width: '100%' }}>
                            LEAD SAVED SUCCESS
                        </Alert>
                    </Snackbar>

                    <Snackbar autoHideDuration={120000} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_saved_lead_error} onClose={() => dispatch(setSnackbar({ ...snackbar, is_saved_lead_error: false }))}>
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_saved_lead_error: false }))} severity="error" variant="filled" sx={{ width: '100%' }}>
                            LEAD SAVED ERROR
                        </Alert>
                    </Snackbar>

                    <Snackbar autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_saved_note_success} onClose={() => dispatch(setSnackbar({ ...snackbar, is_saved_note_success: false }))} >
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_saved_note_success: false }))} severity="success" variant="filled" sx={{ width: '100%' }}>
                            NOTE SAVED SUCCESS
                        </Alert>
                    </Snackbar>

                    <Snackbar autoHideDuration={120000} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_saved_note_error} onClose={() => dispatch(setSnackbar({ ...snackbar, is_saved_note_error: false }))}>
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_saved_note_error: false }))} severity="error" variant="filled" sx={{ width: '100%' }}>
                            NOTE SAVED ERROR
                        </Alert>
                    </Snackbar>

                    <Snackbar autoHideDuration={2000} style={{ marginLeft: 275 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_shop_email_success} onClose={() => dispatch(setSnackbar({ ...snackbar, is_shop_email_success: false }))} >
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_shop_email_success: false }))} severity="success" variant="filled" sx={{ width: '100%' }}>
                            SHOP EMAIL SUCCESS
                        </Alert>
                    </Snackbar>

                    <Snackbar autoHideDuration={120000} style={{ marginLeft: 275 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_shop_email_error} onClose={() => dispatch(setSnackbar({ ...snackbar, is_shop_email_error: false }))}>
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_shop_email_error: false }))} severity="error" variant="filled" sx={{ width: '100%' }}>
                            SHOP EMAIL ERROR
                        </Alert>
                    </Snackbar>


                    <Snackbar autoHideDuration={2000} style={{ marginLeft: 275 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_lead_email_success} onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_email_success: false }))} >
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_email_success: false }))} severity="success" variant="filled" sx={{ width: '100%' }}>
                            LEAD EMAIL SUCCESS
                        </Alert>
                    </Snackbar>

                    <Snackbar autoHideDuration={120000} style={{ marginLeft: 275 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_lead_email_error} onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_email_error: false }))}>
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_email_error: false }))} severity="error" variant="filled" sx={{ width: '100%' }}>
                            LEAD EMAIL ERROR
                        </Alert>
                    </Snackbar>

                    <Snackbar autoHideDuration={2000} style={{ marginLeft: 275 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_order_email_success} onClose={() => dispatch(setSnackbar({ ...snackbar, is_order_email_success: false }))} >
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_order_email_success: false }))} severity="success" variant="filled" sx={{ width: '100%' }}>
                            ORDER EMAIL SUCCESS
                        </Alert>
                    </Snackbar>

                    <Snackbar autoHideDuration={120000} style={{ marginLeft: 275 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_order_email_error} onClose={() => dispatch(setSnackbar({ ...snackbar, is_order_email_error: false }))}>
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_order_email_error: false }))} severity="error" variant="filled" sx={{ width: '100%' }}>
                            ORDER EMAIL ERROR
                        </Alert>
                    </Snackbar>


                    <Snackbar autoHideDuration={2000} style={{ marginLeft: 275 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_lead_sms_success} onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_sms_success: false }))} >
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_sms_success: false }))} severity="success" variant="filled" sx={{ width: '100%' }}>
                            LEAD SMS SUCCESS
                        </Alert>
                    </Snackbar>


                    <Snackbar autoHideDuration={120000} style={{ marginLeft: 275 }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbar.is_lead_sms_error} onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_sms_error: false }))}>
                        <Alert onClose={() => dispatch(setSnackbar({ ...snackbar, is_lead_sms_error: false }))} severity="error" variant="filled" sx={{ width: '100%' }}>
                            LEAD SMS ERROR
                        </Alert>
                    </Snackbar>

                </Stack>

            </Stack >

        </>
    )

}

export default SnackBar
