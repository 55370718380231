import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Radio } from '@mui/material';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { setAssigns, setPresets, setRefreshManager, setStatus, setWorkingTime, updatePriorityAll, updatePriorityByProperty, updateSettings } from '../../../redux/slices/managerSlice';
import { storeLogAction } from '../../../redux/slices/logActionStoreSlice';

const TableSettingsAssigns = () => {

    const dispatch = useDispatch();
    const settingsPresets = useSelector((state) => state.settingsPresets);
    const manager = useSelector((state) => state.manager.data);
    const { refreshManager } = useSelector((state) => state.manager);
    const { currentModal } = useSelector((state) => state.modal);

    const handleChangeStatus = (event, id) => {
        const dataitemIndex = manager.op_settings.findIndex(x => x.id === id);
        const selectedItem = manager.op_settings[dataitemIndex].status;
        const newValue = !selectedItem;
        dispatch(setStatus({ dataitemIndex, newValue }));
        dispatch(updateSettings({ dataitemIndex, id, property: 'status' }));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/operator/status',
                value: "op" + (dataitemIndex + 1) + " " + newValue,
                input_type: 'button'
            })
        );
    }

    const handleChangeWorkingTime = (event, id) => {
        const dataitemIndex = manager.op_settings.findIndex(x => x.id === id);
        const newStartWorkingDate = event.target.value.split(',')[0];
        const newEndWorkingDate = event.target.value.split(',')[1];
        dispatch(setWorkingTime({ dataitemIndex, newStartWorkingDate, newEndWorkingDate }));
        dispatch(updateSettings({ dataitemIndex, id, property: 'start_working_time' }));
        dispatch(updateSettings({ dataitemIndex, id, property: 'end_working_time' }));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/operator/workingTime',
                value: "op" + (dataitemIndex + 1) + " " + newStartWorkingDate + " " + newEndWorkingDate,
                input_type: 'button'
            })
        );
    }

    const handleChangeAssigns = (event, id, propertyName) => {
        const dataitemIndex = manager.op_settings.findIndex(x => x.id === id);
        const newValue = event.target.checked;
        dispatch(setAssigns({ dataitemIndex, propertyName, newValue }));
        dispatch(updateSettings({ dataitemIndex, id, property: propertyName }));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/assigns/' + propertyName,
                value: "op" + (dataitemIndex + 1) + " " + newValue,
                input_type: 'button'
            })
        );
    }

    const handleChangePresets = (event, id, property) => {
        const dataitemIndex = manager.op_settings.findIndex(x => x.id === id);
        const newValue = event.target.value;
        dispatch(setPresets({ dataitemIndex, newValue }));
        dispatch(updateSettings({ dataitemIndex, id, property: 'queue_list' }));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/assigns/presets',
                value: "op" + (dataitemIndex + 1) + " " + property,
                input_type: 'button'
            })
        );
    }

    const handleResetPriorityByProperty = (property) => {
        dispatch(updatePriorityByProperty(property));
        dispatch(setRefreshManager(!refreshManager));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/assigns/resetPriorityByProperty',
                value: property,
                input_type: 'button'
            })
        );
    }
    const handleResetPriorityAll = async (property) => {
        dispatch(updatePriorityAll(property));
        dispatch(setRefreshManager(!refreshManager))
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/assigns/resetPriorityAll',
                value: property,
                input_type: 'button'
            })
        );
    }

    const list = Array.isArray(manager.op_settings) ? manager.op_settings.map((op) => {

        const { id, op_name, status, start_working_time, end_working_time, queue_list,
            priority_lead_gen, priority_pbx, priority_pbx_ciao, priority_pbx_h360, priority_forward,
            lead_gen, pbx, pbx_ciao, pbx_h360, forward, calendar_at_expired } = op;

        return (
            <tr key={id} className={`${parseInt(localStorage.getItem('op_id')) === id ? "table-success table-bold" : ""}`}>
                <td>{id}</td>
                <td>{op_name}</td>
                <td>
                    {status ? (
                        <div className='p-3 bg-success status-light-center cursor-pointer' onClick={(event) => handleChangeStatus(event, id)}></div>
                    ) : (
                        <div className='p-3 bg-danger status-light-center cursor-pointer' onClick={(event) => handleChangeStatus(event, id)}></div>
                    )}
                </td>
                <td>
                    <Radio
                        data-id={id}
                        checked={start_working_time === '09:00:00' && end_working_time === '18:00:00'}
                        onChange={(event) => handleChangeWorkingTime(event, id)}
                        value="09:00:00,18:00:00"
                        name="radio-buttons"
                    />
                </td>
                <td>
                    <Radio
                        checked={start_working_time === '09:00:00' && end_working_time === '15:00:00'}
                        onChange={(event) => handleChangeWorkingTime(event, id)}
                        value="09:00:00,15:00:00"
                        name="radio-buttons"
                    />
                </td>
                <td>
                    <Radio
                        data-id={id}
                        checked={start_working_time === '13:00:00' && end_working_time === '19:00:00'}
                        onChange={(event) => handleChangeWorkingTime(event, id)}
                        value="13:00:00,19:00:00"
                        name="radio-buttons"
                    />
                </td>
                <td style={{ borderLeft: '2px solid #1a73e8' }}>
                    <Switch
                        checked={lead_gen}
                        onChange={(event) => handleChangeAssigns(event, id, 'lead_gen')}
                        value={lead_gen || ""}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name="lead_gen"
                    />
                    {priority_lead_gen}
                </td>
                <td>
                    <Switch
                        checked={pbx}
                        onChange={(event) => handleChangeAssigns(event, id, 'pbx')}
                        value={pbx || ""}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name="pbx"
                    />
                    {priority_pbx}
                </td>
                <td>
                    <Switch
                        checked={pbx_ciao}
                        onChange={(event) => handleChangeAssigns(event, id, 'pbx_ciao')}
                        value={pbx_ciao || ""}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name="pbx_ciao"
                    />
                    {priority_pbx_ciao}
                </td>
                <td>
                    <Switch
                        checked={pbx_h360}
                        onChange={(event) => handleChangeAssigns(event, id, 'pbx_h360')}
                        value={pbx_h360 || ""}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name="pbx_h360"
                    />
                    {priority_pbx_h360}
                </td>
                <td>
                    <Switch
                        checked={forward}
                        onChange={(event) => handleChangeAssigns(event, id, 'forward')}
                        value={forward || ""}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name="forward"
                    />
                    {priority_forward}
                </td>
                <td>
                    <Switch
                        checked={calendar_at_expired}
                        onChange={(event) => handleChangeAssigns(event, id, 'calendar_at_expired')}
                        value={calendar_at_expired || ""}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name="forward"
                    />
                </td>
                {/* ORDINARY */}
                <td style={{ borderLeft: '2px solid #1a73e8' }}>
                    <Radio
                        checked={queue_list === settingsPresets.ordinary}
                        onChange={(event) => handleChangePresets(event, id, 'ORDINARY')}
                        value={settingsPresets.ordinary}
                        name="radio-buttons"
                    />
                </td>
                {/* CC */}
                <td>
                    <Radio
                        checked={queue_list === settingsPresets.cc}
                        onChange={(event) => handleChangePresets(event, id, 'CC')}
                        value={settingsPresets.cc}
                        name="radio-buttons"
                    />
                </td>
                {/* PAI */}
                <td>
                    <Radio
                        checked={queue_list === settingsPresets.pai}
                        onChange={(event) => handleChangePresets(event, id, 'PAI')}
                        value={settingsPresets.pai}
                        name="radio-buttons"
                    />
                </td>
                {/* EE */}
                <td>
                    <Radio
                        checked={queue_list === settingsPresets.ee}
                        onChange={(event) => handleChangePresets(event, id, 'EE')}
                        value={settingsPresets.ee}
                        name="radio-buttons"
                    />
                </td>
                {/* RMKT */}
                <td>
                    <Radio
                        data-id={id}
                        checked={queue_list === settingsPresets.rmkt}
                        onChange={(event) => handleChangePresets(event, id, 'RMKT')}
                        value={settingsPresets.rmkt}
                        name="radio-buttons"
                    />
                </td>
            </tr >
        )
    }) : <tr>
        <td colSpan="8" className="text-center">No operators found</td>
    </tr>

    return (
        <div className="table-responsive datatable-custom table-fix-head card mb-5" id="table-settings-assigns">
            <div className="table-responsive text-nowrap">
                <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                    <thead>
                        <tr>
                            <th colSpan="6" className="mt-2 mb-2 table-header">
                                <h5 className="text-center text-white mb-0 pb-2">OPERATOR</h5>
                            </th>
                            <th colSpan="6" className="mt-2 mb-2 table-header" style={{ borderLeft: '2px solid #1a73e8' }}>
                                <h5 className="text-center text-white mb-0 pb-2">ASSIGNS</h5>
                            </th>
                            <th colSpan="5" className="mt-2 mb-2 table-header" style={{ borderLeft: '2px solid #1a73e8' }}>
                                <h5 className="text-center text-white mb-0 pb-2">PRESETS</h5>
                            </th>
                        </tr>
                        <tr>
                            <th className="table-rotate"><div>ID</div></th>
                            <th className="table-rotate"><div>OP NAME</div></th>
                            <th className="table-rotate"><div>STATUS</div></th>
                            <th className="table-rotate"><div>9:00/18:00</div></th>
                            <th className="table-rotate"><div>9:00/15:00</div></th>
                            <th className="table-rotate"><div>13:00/19:00</div></th>
                            <th className="table-rotate" style={{ borderLeft: '2px solid #1a73e8' }}><div>LEAD GEN</div></th>
                            <th className="table-rotate"><div>PBX SDU</div></th>
                            <th className="table-rotate"><div>PBX CIAO</div></th>
                            <th className="table-rotate"><div>PBX H360</div></th>
                            <th className="table-rotate"><div>FWD LEAD</div></th>
                            <th className="table-rotate"><div>CALENDAR AT EXPIRED</div></th>
                            <th className="table-rotate" style={{ borderLeft: '2px solid #1a73e8' }}><div>ORDINARY</div></th>
                            <th className="table-rotate"><div>CC</div></th>
                            <th className="table-rotate"><div>PAI</div></th>
                            <th className="table-rotate"><div>EE</div></th>
                            <th className="table-rotate"><div>RMKT</div></th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {list}
                        <tr>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}><Button variant="contained" onClick={() => handleResetPriorityByProperty('priority_lead_gen')}>Reset</Button></td>
                            <td style={{ border: 'none' }}><Button variant="contained" onClick={() => handleResetPriorityByProperty('priority_pbx')}>Reset</Button></td>
                            <td style={{ border: 'none' }}><Button variant="contained" onClick={() => handleResetPriorityByProperty('priority_pbx_ciao')}>Reset</Button></td>
                            <td style={{ border: 'none' }}><Button variant="contained" onClick={() => handleResetPriorityByProperty('priority_pbx_h360')}>Reset</Button></td>
                            <td style={{ border: 'none' }}><Button variant="contained" onClick={() => handleResetPriorityByProperty('priority_forward')}>Reset</Button></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                        </tr>
                        <tr>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }} colSpan={5}><Button variant="contained" onClick={() => handleResetPriorityAll()}>Reset All</Button></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                            <td style={{ border: 'none' }}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableSettingsAssigns