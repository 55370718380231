import React from 'react';
import { useSelector } from 'react-redux';

const NoteFromShop = () => {

    const dialer = useSelector((state) => state.dialer.data);

    return (
        <div className="table-responsive datatable-custom table-fix-head card mt-3" id="table-shop-instructions">
            <div className="table-responsive text-nowrap">
                <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                    <thead>
                        <tr>
                            <th colSpan="8" className="mt-2 mb-2">
                                <h5 className="text-center text-white mb-0 pb-2">NOTE FROM SHOP</h5>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {dialer.note_by_shop ? (
                            <>
                                <tr>
                                    <td>{dialer.note_by_shop}</td>
                                </tr>
                            </>
                        ) : (
                            <tr className="text-primary card-subtitle mb-2">
                                <td colSpan="3" >No Results</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )


}

export default NoteFromShop
