import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import NavbarRight from '../../components/NavbarRight';
import { setCurrentModal } from '../../redux/slices/modalSlice';
import { setCountdownSeconds } from '../../redux/slices/managerSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';
import { TextField } from '@mui/material'
import { setRemarketingByLeadsParams, storeRemarketingByLeads } from '../../redux/slices/remarketingByLeadsSlice';
//import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { pasteFromClipboard } from '../../helpers';
import OperatorsCheckboxTransfers from './Transfers/OperatorsCheckboxTransfers';

const ModalRemarketingByLeads = () => {

    const dispatch = useDispatch();
    const { loggedEmail } = useSelector((state) => state.auth);
    const isLoading = useSelector((state) => state.manager.isLoading);
    const { currentModal } = useSelector((state) => state.modal);
    const remarketingByLeadsParams = useSelector((state) => state.remarketingByLeads.params);
    const { opIdTo } = useSelector((state) => state.transfers.params);
    //const sources = useSelector((state) => state.sources.data);
    const inputRef = useRef(null);

    const removeFocus = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const handleClose = () => {
        dispatch(setCurrentModal(''));
        dispatch(setCountdownSeconds(30));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
        dispatch(
            setRemarketingByLeadsParams({
                lead_ids: "",
                op_assigned: "",
                source: "",
            })
        );
    };

    const handleSubmitForm = (event) => {

        event.preventDefault();

        const leadIds = remarketingByLeadsParams.lead_ids;

        const validFormat = /^[0-9]+(\s+[0-9]+)*$/;
        if (validFormat.test(leadIds)) {
            dispatch(storeRemarketingByLeads());
            cleanLeads()
        } else {
            alert("Copy and Paste Leads IDs in column from an Excel file");
        }
    }

    const pastClipBoardLeads = async () => {

        try {

            const clipboardText = await pasteFromClipboard();
            const clipboardTextSplit = clipboardText.split('\n'); // Dividi il testo usando il carattere di nuova riga
            const idArray = clipboardTextSplit.flatMap(line => line.split(' ')); // Dividi ogni riga in ID separati e unisci gli array risultanti
            const totalLeads = idArray.length;

            const validFormat = /^[0-9]+(\s+[0-9]+)*$/;

            if(totalLeads > 1000) {
                alert("Max 1000 Leads!");
                return false;
            }

            if (validFormat.test(clipboardText)) {
                removeFocus();
                dispatch(setRemarketingByLeadsParams({
                    ...remarketingByLeadsParams,
                    lead_ids: clipboardText
                }));
            } else {
                alert("Copy and Paste Leads IDs in column from an Excel file");
            }


        } catch (error) {
            console.error('Errore durante il recupero del testo dagli appunti:', error);
        }
    }

    const cleanLeads = () => {
        dispatch(setRemarketingByLeadsParams({
            ...remarketingByLeadsParams,
            lead_ids: ""
        }));
    }

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
        >

            <AppBar sx={{ position: 'relative' }} style={{ background: '#1a73e8', marginRight: 0 }}>

                <Toolbar>

                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            {!loggedEmail.includes('@udibox.it') &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            }

                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Remarketing By Leads</Button>
                            </Badge>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NavbarRight />
                            </Box>
                        </Grid>

                    </Grid>

                </Toolbar>
            </AppBar>

            {!isLoading ? (
                <div className="vh-100 content container-fluid" style={{ marginTop: 20, display: "block" }}>
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <form method="post" onSubmit={(event) => handleSubmitForm(event)}>

                                <OperatorsCheckboxTransfers />

                                <Stack spacing={2} direction="row" style={{ marginTop: 20, marginBottom: 10 }}>
                                    <TextField
                                        disabled
                                        inputRef={inputRef}
                                        required
                                        onChange={(event) => {
                                            dispatch(setRemarketingByLeadsParams(
                                                {
                                                    ...remarketingByLeadsParams,
                                                    lead_ids: event.target.value
                                                }))
                                        }}
                                        value={remarketingByLeadsParams.lead_ids}
                                        fullWidth
                                        id="lead_ids" label="Leads Ids (max 1000 leads)" multiline rows={8} />
                                </Stack>

                                <Stack spacing={2} direction="row" style={{ marginTop: 20, marginBottom: 10 }}>
                                    <Button variant="contained" onClick={() => pastClipBoardLeads()} style={{ marginBottom: 20 }}>PASTE</Button>
                                    <Button variant="contained" onClick={() => cleanLeads()} style={{ marginLeft: 20, marginBottom: 20 }}>CLEAN</Button>
                                </Stack>

                                <Stack spacing={2} direction="row" style={{ marginTop: 10, marginBottom: 10 }}>
                                    {opIdTo.length > 0 ? (
                                        <Button type="submit" variant="contained">ADD REMARKETING</Button>
                                    ) : (
                                        <Button disabled type="submit" variant="contained" style={{ backgroundColor: 'red', color: 'white' }}>Select Checkbox Operators!</Button>
                                    )}
                                </Stack>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                </div>
            )}

        </Dialog>
    );
}

export default ModalRemarketingByLeads