import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import instance from '../instance';
import { setIsLoading, setIsLoadingSmall } from './managerSlice';

const initialParams = {
    lead_id: "",
    op_assigned: "",
    customer_name: "",
    customer_phone: "",
    customer_zipcode: "",
    customer_email: "",
    ecommerce_order_id: "",
    ecommerce_order_note: "",
    created_at_from: "",
    created_at_to: "",
    appointment_at_from: "",
    appointment_at_to: "",
    status1_id: null,
    status2_id: null,
    status3_id: null,
    status4_id: null,
    business_id: null,
    shop_province: null,
    shop_id: null,
    shop_info_id: null,
};

const leadSearchSlice = createSlice({
    name: 'leadSearch',
    initialState: {
        data: [],
        params: initialParams,
        refreshLeadSearch: false,
        realtimeSearch: false,
        ecommerceNoteToggle: false,
        exportLeads: false,
        isError: false
    },
    reducers: {
        setLeadSearchParams: (state, action) => {
            state.params = action.payload;
        },
        setResetLeadSearchParams: (state) => {
            state.params = initialParams;
        },
        setRefreshLeadSearch: (state, action) => {
            state.refreshLeadSearch = action.payload;
        },
        setRealtimeSearch: (state, action) => {
            state.realtimeSearch = action.payload;
        },
        setEcommerceNoteToggle: (state, action) => {
            state.ecommerceNoteToggle = action.payload;
        },
        setQueryPage: (state, action) => {
            state.params.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeadSearch.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchLeadSearch.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchLeadSearch.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setLeadSearchParams, setRefreshLeadSearch, setRealtimeSearch, setEcommerceNoteToggle, setResetLeadSearchParams, setQueryPage } = leadSearchSlice.actions;

export const fetchLeadSearch = createAsyncThunk('fetch/leadSearch', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoadingSmall(true));

        const state = getState();
        const { page, lead_id, customer_name, customer_phone, customer_zipcode, customer_email, ecommerce_order_id, ecommerce_order_note, op_id, created_at_from, created_at_to, appointment_at_from, appointment_at_to, source, status1_id, status2_id, status3_id, status4_id, business_id, shop_province, shop_id, shop_info_id, rmkt } = getState().leadSearch.params;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/search/leads', {
            params: {
                page,
                lead_id,
                customer_name,
                customer_phone,
                customer_zipcode,
                customer_email,
                ecommerce_order_id,
                ecommerce_order_note,
                op_assigned: op_id,
                created_at_from,
                created_at_to,
                appointment_at_from,
                appointment_at_to,
                source,
                status1_id,
                status2_id,
                status3_id,
                status4_id,
                business_id,
                shop_province,
                shop_id,
                shop_info_id,
                rmkt
            }
        });

        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;

    } finally {
        dispatch(setIsLoadingSmall(false));
    }

});

export const exportLeads = createAsyncThunk('fetch/exportLeads', async (_, { getState, dispatch }) => {

    try {
        dispatch(setIsLoading(true));

        const state = getState();
        const { lead_id, customer_name, customer_phone, customer_zipcode, customer_email, ecommerce_order_id, ecommerce_order_note, op_id, created_at_from, created_at_to, appointment_at_from, appointment_at_to, source, status1_id, status2_id, status3_id, status4_id, business_id, shop_province, shop_id, shop_info_id } = state.leadSearch.params;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/search/leads/export', {
            params: {
                export_leads: true,
                lead_id,
                customer_name,
                customer_phone,
                customer_zipcode,
                customer_email,
                ecommerce_order_id,
                ecommerce_order_note,
                op_assigned: op_id,
                created_at_from,
                created_at_to,
                appointment_at_from,
                appointment_at_to,
                source,
                status1_id,
                status2_id,
                status3_id,
                status4_id,
                business_id,
                shop_province,
                shop_id,
                shop_info_id,
            },
            responseType: 'blob',
        });

        if (response.data) {
            const timestamp = new Date().toLocaleString().replace(/[/. ,:]/g, '_');
            const filename = `leads_export_${timestamp}.xlsx`;
            FileSaver.saveAs(response.data, filename);
        } else {
            throw new Error('Nessun dato ricevuto per l\'esportazione dei lead.');
        }
    } catch (error) {
        console.error('Errore durante l\'esportazione dei lead:', error);
        throw error;
    } finally {
        dispatch(setIsLoading(false));
    }
});

export default leadSearchSlice.reducer;
