import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, TextField } from '@mui/material';

import CreatedAtSearch from './Form/CreatedAtSearch';
import OpLoggedSearch from './Form/OpLoggedSearch';
import { setLogActionsSearchParams, setQueryPage, setRefreshLogActionsSearch } from '../../../redux/slices/logActionsSearchSlice';

const FormLogActionsSearch = () => {

    const dispatch = useDispatch();
    const logActionsSearchParams = useSelector((state) => state.logActionsSearch.params);
    const refreshLogActionsSearch = useSelector((state) => state.logActionsSearch.refreshLogActionsSearch);

    const handleKeyEnter = (event) => {
        if (event.key === 'Enter') {
            dispatch(setRefreshLogActionsSearch(!refreshLogActionsSearch))
            dispatch(setQueryPage(1))
        }
    }

    return (
        <div>
            <Stack spacing={4} direction="row">
                <OpLoggedSearch />
            </Stack>

            <Stack spacing={4} direction="row">
                <CreatedAtSearch />
            </Stack>

            <Stack spacing={4} direction="row" style={{ marginBottom: 10 }}>
                <TextField
                    type='text'
                    autoComplete="off"
                    onChange={(event) => {
                        dispatch(setLogActionsSearchParams({ ...logActionsSearchParams, type: event.target.value }));
                    }}
                    onKeyPress={handleKeyEnter}
                    margin="normal" fullWidth label="Type" value={logActionsSearchParams.type}
                />
            </Stack>

            <Stack spacing={4} direction="row">
                <TextField
                    type='text'
                    autoComplete="off"
                    onChange={(event) => {
                        dispatch(setLogActionsSearchParams({ ...logActionsSearchParams, value: event.target.value }));
                    }}
                    onKeyPress={handleKeyEnter}
                    margin="normal" fullWidth label="Value" value={logActionsSearchParams.value}
                />
            </Stack>


        </div>
    )
}

export default FormLogActionsSearch
