import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';
import { setAddNewLead, storeNewLead } from '../../../redux/slices/addNewLeadSlice';
import { storeLogAction } from '../../../redux/slices/logActionStoreSlice';

const FormNewLead = () => {

    const dispatch = useDispatch();
    const addNewLead = useSelector((state) => state.addNewLead.params);
    const sources = useSelector((state) => state.sources.data);
    const { currentModal } = useSelector((state) => state.modal);

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        dispatch(storeNewLead());
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/addNewLead',
                value: JSON.stringify(addNewLead),
                input_type: 'button'
            })
        );
    }

    return (
        <form method="post" onSubmit={handleSubmitForm}>
            <div className="row">
                <div className="col-12">
                    <LocalizationProvider
                        id="created_at"
                        dateAdapter={AdapterDayjs}
                        adapterLocale="it">
                        <MobileDateTimePicker
                            label="Created At"
                            disableFuture={true}
                            value={addNewLead.created_at !== null ? dayjs(addNewLead.created_at, 'DD/MM/YYYY HH:mm') : dayjs().format('DD/MM/YYYY HH:mm')}
                            onChange={(newValue) => setAddNewLead({ ...addNewLead, created_at: dayjs(newValue).format('DD/MM/YYYY HH:mm') })}
                            inputFormat="DD/MM/YYYY HH:mm"
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            ampm={false}
                        />
                    </LocalizationProvider>

                    <Autocomplete
                        required
                        disableClearable
                        id="source"
                        options={sources}
                        value={addNewLead.source || null}
                        getOptionLabel={(option) => option.source || addNewLead.source}
                        isOptionEqualToValue={(option, value) => option.source === value}
                        onChange={(event, option) => {
                            if (option) {
                                dispatch(
                                    setAddNewLead(
                                        {
                                            ...addNewLead,
                                            source: option.source
                                        })
                                );
                            } else {
                                dispatch(setAddNewLead({ ...addNewLead, source: "" }));
                            }
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.source}
                                </li>
                            );
                        }}
                        renderInput={(params) => <TextField required margin="normal" {...params} label="Source" />} />

                    {/******** CUSTOMER NAME ************/}
                    <TextField
                        required
                        autoComplete="off"
                        onChange={event => dispatch(setAddNewLead({ ...addNewLead, customer_name: event.target.value }))}
                        margin="normal" fullWidth label="Name/Surname" id="customer_name" value={addNewLead.customer_name || ""} />

                    {/******** CUSTOMER PHONE ************/}
                    <TextField
                        required
                        autoComplete="off"
                        onChange={event => dispatch(setAddNewLead({ ...addNewLead, customer_phone: event.target.value }))}
                        margin="normal" fullWidth label="Phone" id="customer_phone" value={addNewLead.customer_phone || ""} />

                    {/******** CUSTOMER ZIPCODE ************/}
                    <TextField
                        required
                        autoComplete="off"
                        onChange={event => dispatch(setAddNewLead({ ...addNewLead, customer_zipcode: event.target.value }))}
                        margin="normal" fullWidth label="Zipcode" id="customer_zipcode" value={addNewLead.customer_zipcode || ""} />

                    {/******** CUSTOMER EMAIL ************/}
                    <TextField
                        autoComplete="off"
                        onChange={event => dispatch(setAddNewLead({ ...addNewLead, customer_email: event.target.value }))}
                        margin="normal" fullWidth label="Email" id="customer_email" value={addNewLead.customer_email || ""} />

                    {addNewLead.business_name} {addNewLead.shop_address}

                    <TextField
                        autoComplete="off"
                        onChange={event => dispatch(
                            setAddNewLead(
                                {
                                    ...addNewLead,
                                    shop_id: event.target.value,
                                    business_name: '',
                                    shop_address: ''
                                })
                        )}
                        margin="normal" fullWidth label="IDC" id="shop_id" value={addNewLead.shop_id || ""} />

                    <TextField
                        autoComplete="off"
                        onChange={event => dispatch(
                            setAddNewLead(
                                {
                                    ...addNewLead,
                                    shop_info_id: event.target.value,
                                    business_name: '',
                                    shop_address: ''
                                })
                        )}
                        margin="normal" fullWidth label="IDR" id="shop_info_id" value={addNewLead.shop_info_id || ""} />

                    <Stack spacing={2} direction="row" style={{ marginTop: 10, marginBottom: 10 }}>
                        <Button type="submit" variant="contained">ADD LEAD</Button>
                    </Stack>

                </div>
            </div>
        </form>
    )
}

export default FormNewLead