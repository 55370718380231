import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import BackspaceIcon from '@mui/icons-material/Backspace';
import NavbarRight from '../../components/NavbarRight';
import { setCurrentModal } from '../../redux/slices/modalSlice';
import { setCountdownSeconds } from '../../redux/slices/managerSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';
import LogCustomerJoyTokens from './LogCustomerJoy/LogCustomerJoyTokens';
import LogCustomerJoyUsers from './LogCustomerJoy/LogCustomerJoyUsers';
import DefaultSearch from './LogCustomerJoy/Form/DefaultSearch';
import { setRefreshLogCustomerJoyUser, setResetCustomerJoyUserParams } from '../../redux/slices/logCustomerJoyUserSlice';
import { setRefreshLogCustomerJoyToken, setResetCustomerJoyTokenParams } from '../../redux/slices/logCustomerJoyTokenSlice';

const ModalLogCustomerJoy = () => {

    const dispatch = useDispatch();
    const { loggedEmail } = useSelector((state) => state.auth);
    const isLoading = useSelector((state) => state.manager.isLoading);
    const { currentModal } = useSelector((state) => state.modal);
    const { refreshLogCustomerJoyUser } = useSelector((state) => state.logCustomerJoyUser);
    const { refreshLogCustomerJoyToken } = useSelector((state) => state.logCustomerJoyToken);

    const handleClose = () => {
        dispatch(setCurrentModal(''));
        dispatch(setCountdownSeconds(30));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
    };

    const handleClickResetLogCustomerJoy = () => {
        dispatch(setRefreshLogCustomerJoyUser(!refreshLogCustomerJoyUser))
        dispatch(setRefreshLogCustomerJoyToken(!refreshLogCustomerJoyToken))
        dispatch(setResetCustomerJoyUserParams());
        dispatch(setResetCustomerJoyTokenParams());
    }

    const handleClickRefreshLogCustomerJoy = () => {
        dispatch(setRefreshLogCustomerJoyUser(!refreshLogCustomerJoyUser))
        dispatch(setRefreshLogCustomerJoyToken(!refreshLogCustomerJoyToken))
    };

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }} style={{ background: '#1a73e8', marginRight: 0 }}>

                <Toolbar>

                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            {!loggedEmail.includes('@udibox.it') &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            }

                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Log Customer Joy</Button>
                            </Badge>

                            <IconButton style={{ marginRight: 2 }}
                                variant="text"
                                edge="end" color="inherit" aria-label="close" onClick={handleClickResetLogCustomerJoy}>
                                <BackspaceIcon />
                            </IconButton>

                            <IconButton
                                variant="text"
                                edge="end" color="inherit" aria-label="close" onClick={handleClickRefreshLogCustomerJoy}>
                                <RefreshIcon />
                            </IconButton>

                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NavbarRight />
                            </Box>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            {!isLoading ? (
                <div className="vh-100 content container-fluid" style={{ marginTop: 20, display: "block" }}>
                    <div className='row mt-2'>
                        <div className='col-2'>
                            <DefaultSearch />
                        </div>
                        <div className='col-10'>
                            <div className='col-12'>
                                <LogCustomerJoyUsers />
                            </div>
                            <div className='col-12' style={{ marginTop: 20 }}>
                                <LogCustomerJoyTokens />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    <CircularProgress />
                </div>
            )}

        </Dialog>
    )
}

export default ModalLogCustomerJoy