import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { setLeadSearchParams } from '../../../../redux/slices/leadSearchSlice';

const StatusSearch = () => {

    const dispatch = useDispatch();
    const leadSearchParams = useSelector((state) => state.leadSearch.params);
    const status = useSelector((state) => state.status.data);

    return (
        <>
            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <Autocomplete
                    fullWidth
                    id="status1_id"
                    options={status.status1}
                    getOptionLabel={(option) => option.name}
                    value={status.status1.find(option => option.id === leadSearchParams.status1_id) || null}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setLeadSearchParams({ ...leadSearchParams, status1_id: option.id }));
                        }
                        else {
                            dispatch(setLeadSearchParams({ ...leadSearchParams, status1_id: "" }));
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status 1" />}
                />

                <Autocomplete
                    fullWidth
                    id="status2_id"
                    options={status.status2}
                    getOptionLabel={(option) => option.name}
                    value={status.status2.find(option => option.id === leadSearchParams.status2_id) || null}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setLeadSearchParams({ ...leadSearchParams, status2_id: option.id }));
                        }
                        else {
                            dispatch(setLeadSearchParams({ ...leadSearchParams, status2_id: "" }));
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status 2" />}
                />
            </Stack>

            <Stack spacing={2} direction="row" style={{ marginTop: 15, marginBottom: 10 }}>
                <Autocomplete
                    fullWidth
                    id="status3_id"
                    options={status.status3}
                    getOptionLabel={(option) => option.name}
                    value={status.status3.find(option => option.id === leadSearchParams.status3_id) || null}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setLeadSearchParams({ ...leadSearchParams, status3_id: option.id }));
                        }
                        else {
                            dispatch(setLeadSearchParams({ ...leadSearchParams, status3_id: "" }));
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status 3" />}
                />

                <Autocomplete
                    fullWidth
                    id="status4_id"
                    options={status.status4}
                    getOptionLabel={(option) => option.name}
                    value={status.status4.find(option => option.id === leadSearchParams.status4_id) || null}
                    onChange={(event, option) => {
                        if (option) {
                            dispatch(setLeadSearchParams({ ...leadSearchParams, status4_id: option.id }));
                        }
                        else {
                            dispatch(setLeadSearchParams({ ...leadSearchParams, status4_id: "" }));
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        );
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField fullWidth {...params} label="Status 4" />}
                />
            </Stack>
        </>
    )
}

export default StatusSearch