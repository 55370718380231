import React from 'react';
import { useSelector } from 'react-redux';

const ShopAvailability = () => {
    const dialer = useSelector((state) => state.dialer.data);

    const { shop_availability } = dialer.shop_info || {};

    return (
        <div className="table-responsive datatable-custom table-fix-head card mt-3" id="table-shop-availability">
            <div className="table-responsive text-nowrap">
                <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                    <thead>
                        <tr>
                            <th colSpan="8" className="mt-2 mb-2">
                                <h5 className="text-center text-white mb-0 pb-2">SHOP AVAILABILITY</h5>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {shop_availability ? (
                            <tr>
                                <td dangerouslySetInnerHTML={{__html: shop_availability.replace(/((\d[\d\-.]*){9,})/g, '<a href="tel://$1">$1</a>')}}></td>
                            </tr>
                        ) : (
                            <tr className="text-primary card-subtitle mb-2">
                                <td colSpan="3">Nessun risultato</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ShopAvailability;
