import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBarInfo from './Tabs/TabLead/AppBarInfo';
import TabsNav from './Tabs/TabLead/TabsNav';
import TabLead from './Tabs/TabLead';
import ActionsBarOne from './Tabs/ActionsBarOne';
import { updateDialer } from '../../../redux/slices/dialerSlice';
import { setCurrentTabEditLead } from '../../../redux/slices/modalSlice';
import TabNotes from './Tabs/TabNotes';
import ActionsBarTwo from './Tabs/ActionsBarTwo';
import TabShopAssociate from './Tabs/TabShopAssociate';
import LogRecordingsTable from './Tabs/TabLogNotes/LogRecordingsTable';
import TabAppointment from './Tabs/TabAppointment';
import { setDialer } from '../../../redux/slices/dialerSlice';
import { storeLogAction } from '../../../redux/slices/logActionStoreSlice';
import LogNotesByCustomerPhoneTable from './Tabs/TabLogNotes/LogNotesByCustomerPhoneTable';
import LogNotesByLeadIdTable from './Tabs/TabLogNotes/LogNotesByLeadIdTable';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="section">{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const BasicTabs = ({ handleClose }) => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const currentTabEditLead = useSelector((state) => state.modal.currentTabEditLead);
    const { currentModal } = useSelector((state) => state.modal);
    const loggedId = useSelector((state) => state.auth.loggedId);
    const manager = useSelector((state) => state.manager.data);
    const opSettings = manager.op_settings && manager.op_settings[loggedId - 1];
    const { start_working_time, end_working_time } = opSettings || {};

    const current_time = dayjs().format('HH:mm:ss');
    const isOutsideWorkingTime = current_time < start_working_time || current_time > end_working_time;

    const handleSubmitForm = (event) => {
        event.preventDefault();
        dispatch(updateDialer())
    }

    const handleGoToTabNote = (event) => {
        handleSubmitForm(event)
        dispatch(setCurrentTabEditLead(5))
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/tabs/noteForm',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    }

    const autoUpdateCalendarAt = () => {

        const convertCalendarAt = dayjs(dialer.calendar_at, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm');

        const excludeFromResetCalendar = [2, 21, 56, 58, 65];
        if ((!excludeFromResetCalendar.includes(dialer.lead_status_1?.id) &&
            (!excludeFromResetCalendar.includes(dialer.lead_status_2?.id))) &&
            (convertCalendarAt < dayjs(new Date()).format('YYYY-MM-DD HH:mm'))) {
            dispatch(setDialer({ ...dialer, calendar_at: null }))
        }

        const autoUpdateDateOneDay = [2, 21, 56, 103];
        const autoUpdateDateTwoDays = [22, 24, 28];

        if ((autoUpdateDateOneDay.includes(dialer.lead_status_1?.id) ||
            (autoUpdateDateOneDay.includes(dialer.lead_status_2?.id)) ||
            (autoUpdateDateOneDay.includes(dialer.lead_status_3?.id))) &&
            (convertCalendarAt < dayjs(new Date()).format('YYYY-MM-DD HH:mm') || dialer.calendar_at === null)) {
            dispatch(setDialer({ ...dialer, calendar_at: getRandomDate(1) }))
        }

        if ((autoUpdateDateTwoDays.includes(dialer.lead_status_1?.id) ||
            (autoUpdateDateTwoDays.includes(dialer.lead_status_2?.id))) &&
            (convertCalendarAt < dayjs(new Date()).format('YYYY-MM-DD HH:mm') || dialer.calendar_at === null)) {
            dispatch(setDialer({ ...dialer, calendar_at: getRandomDate(2) }))
        }

    }

    const getRandomDate = (addDay) => {

        const date = dayjs().add(addDay, 'day').format('DD/MM/YYYY');
        const hoursAm = ["09", "10", "11", "12", "13"];
        const hoursPm = ["14", "15", "16", "17", "18"];
        const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
        const randomHoursAm = hoursAm[Math.floor(Math.random() * hoursAm.length)];
        const randomHoursPm = hoursPm[Math.floor(Math.random() * hoursPm.length)];
        const randomMinutes = minutes[Math.floor(Math.random() * minutes.length)];

        const currentHour = new Date().getHours()
        let randomDate = date + " ";
        if (currentHour >= 9 && currentHour <= 13) {
            randomDate += randomHoursPm;
        } else {
            randomDate += randomHoursAm;
        }
        randomDate += ":" + randomMinutes;

        return randomDate;

    }

    useEffect(() => {
        autoUpdateCalendarAt();
    }, [currentTabEditLead, dialer.lead_status_1?.id, dialer.lead_status_2?.id, dialer.lead_status_3?.id])

    return (
        <form method="post" onSubmit={handleSubmitForm}>
            <Box sx={{ width: '100%' }}>
                <AppBarInfo handleClose={handleClose} />
                <div className='mb-10' style={({ marginTop: '100px', marginBottom: '100px' })}>
                    {dialer.op_assigned && loggedId !== dialer.op_assigned &&
                        <Stack>
                            <Alert sx={{ paddingTop: '10px', marginBottom: '0px', borderRadius: 0 }} variant="filled" severity="primary">Questo lead è assegnato a OP{dialer.op_assigned}</Alert>
                        </Stack>
                    }
                    {!dialer.op_assigned &&
                        <Stack>
                            <Alert sx={{ paddingTop: '10px', marginBottom: '0px', borderRadius: 0 }} variant="filled" severity="error">Questo lead non è assegnato.</Alert>
                        </Stack>
                    }
                    {isOutsideWorkingTime &&
                        <Stack>
                            <Alert sx={{ paddingTop: '10px', marginBottom: '20px', borderRadius: 0 }} variant="filled" severity="error">Il tuo orario lavorativo è {start_working_time} - {end_working_time}</Alert>
                        </Stack>
                    }

                    <TabsNav />
                    <TabPanel value={currentTabEditLead} index={0}>
                        <TabLead />
                    </TabPanel>
                    <TabPanel value={currentTabEditLead} index={1}>
                        <TabShopAssociate />
                    </TabPanel>
                    <TabPanel value={currentTabEditLead} index={2}>
                        <TabAppointment />
                    </TabPanel>
                    <TabPanel value={currentTabEditLead} index={3}>
                        <LogNotesByLeadIdTable />
                        <LogNotesByCustomerPhoneTable />
                    </TabPanel>
                    <TabPanel value={currentTabEditLead} index={4}>
                        <LogRecordingsTable />
                    </TabPanel>
                    <TabPanel value={currentTabEditLead} index={5}>
                        <TabNotes />
                    </TabPanel>
                </div>
                {currentTabEditLead < 5 ? (
                    <ActionsBarOne handleGoToTabNote={handleGoToTabNote}></ActionsBarOne>
                ) : (
                    <ActionsBarTwo />
                )}
            </Box>
        </form >
    );
}

export default BasicTabs;