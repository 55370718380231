import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid } from '@mui/material';
import DashboardBox from '../../components/Modals/Dashboard/DashboardBox';
import NavbarRight from '../../components/NavbarRight';
import { setCurrentModal } from '../../redux/slices/modalSlice';
import { setRefreshDashboardOp } from '../../redux/slices/dashboardOpSlice';
import { setCountdownSeconds } from '../../redux/slices/managerSlice';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';

const ModalDashboard = () => {

    const dispatch = useDispatch();
    const { loggedEmail } = useSelector((state) => state.auth);
    const dashboardOp = useSelector((state) => state.dashboardOp);
    const { currentModal } = useSelector((state) => state.modal);

    const handleClose = () => {
        dispatch(setCurrentModal(''));
        dispatch(setCountdownSeconds(30));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/close',
                value: null,
                input_type: 'button'
            })
        );
    };

    const handleClickRefreshDashboard = () => {
        dispatch(setRefreshDashboardOp(!dashboardOp.refreshDashboardOp))
    };

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }} style={{ background: '#1a73e8', marginRight: 0 }}>

                <Toolbar>

                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            {!loggedEmail.includes('@udibox.it') &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            }

                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black' }}>Dashboard</Button>
                            </Badge>

                            <IconButton
                                variant="text"
                                edge="end" color="inherit" aria-label="close" onClick={handleClickRefreshDashboard}>
                                <RefreshIcon />
                            </IconButton>

                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NavbarRight />
                            </Box>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            <div className="content container-fluid" style={{ marginTop: 20, display: "block" }}>
                <div className='col-12 mt-2'>
                    <DashboardBox />
                </div>
            </div>

        </Dialog>
    );
}

export default ModalDashboard