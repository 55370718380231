import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        currentModal: "",
        currentTabEditLead: 0,
        isError: false
    },
    reducers: {
        setCurrentModal: (state, action) => {
            state.currentModal = action.payload;
        },
        setCurrentTabEditLead: (state, action) => {
            state.currentTabEditLead = action.payload;
        },
    }
});

export const { setCurrentModal, setCurrentTabEditLead } = modalSlice.actions;

export default modalSlice.reducer;
