import React from 'react';
import CreatedAtSearch from './Form/CreatedAtSearch';
import OpLoggedSearch from './Form/OpLoggedSearch';
import DefaultSearch from './Form/DefaultSearch';
import StatusSearch from './Form/StatusSearch';

const FormLeadsSearch = () => {

    return (
        <div>

            <OpLoggedSearch />

            <DefaultSearch />

            <CreatedAtSearch />

            <StatusSearch />

        </div>
    )
}

export default FormLeadsSearch
