import React from 'react';
import { useSelector } from 'react-redux';
import { copyToClipboard } from '../../../../../helpers.js'

const EcommerceOrderTable = () => {

  const dialer = useSelector((state) => state.dialer.data);

  const ecommerce_order_note_strip_html = dialer.ecommerce_order_note.replace(/<[^>]*>?/gm, '');

  return (
    <div className="table-responsive datatable-custom table-fix-head card" id="table-sdu-ecommerce-order">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="8" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">ECOMMERCE ORDER</h5>
              </th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {dialer.ecommerce_order_note ? (
              <tr className="text-primary card-subtitle mb-2">
                <td className='copy-clipboard' dangerouslySetInnerHTML={{ __html: dialer.ecommerce_order_note }} style={{ textAlign:'left' }} onClick={() => {copyToClipboard(ecommerce_order_note_strip_html)}}></td>
              </tr>
            ) : (
            <tr className="text-primary card-subtitle mb-2">
              <td colSpan="4" >No Results</td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )

}

export default EcommerceOrderTable
