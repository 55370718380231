import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { setTransfersParams } from '../../../redux/slices/transfersSlices';

const TableSettingsTransfer = () => {

  const dispatch = useDispatch();

  const transfers = useSelector((state) => state.transfers.data);
  const transfersParams = useSelector((state) => state.transfers.params);

  const list = Array.isArray(transfers.dialer_list) ? transfers.dialer_list.map((leads) => {

    const { id, source, customer_name, status_1, status_2, status_3, status_4, appointment_at, calendar_at, status_group, rmkt } = leads;

    return (
      <tr key={id}>
        {(transfersParams.opIdFrom && (transfersParams.opStatusGroup === status_group || transfersParams.opStatusGroup === null)) &&
          <>
            <td>
              <div className="row div-table-center">
                <div className="col-4" style={{ marginRight: 0 }}>
                  {calendar_at &&
                    <span className="badge bg-secondary" style={{ width: 35, height: 30, paddingTop: 5 }}>
                      <CalendarMonthIcon sx={{ marginTop: -0.3, width: 20 }} />
                    </span>
                  }
                </div>
                <div className="col-4" style={{ marginRight: 0 }}>
                  {rmkt > 0 ? (
                    <span className="badge bg-secondary badge-table" style={{ height: 30, paddingTop: 10 }}>RMKT</span>
                  ) : (
                    <span className="badge bg-secondary badge-table" style={{ height: 30, paddingTop: 10 }}>{status_group.substring(0, 2)}</span>
                  )
                  }
                </div>
                <div className="col-4">
                  {status_1 === "IN ATTESA" &&
                    <span className="badge bg-secondary badge-table" style={{ height: 30, paddingTop: 10 }}>
                      NEW!
                    </span>
                  }
                  {source && source.toLowerCase().includes('ciao') &&
                    <span className="badge bg-secondary badge-table" style={{ height: 30, paddingTop: 10 }}>
                      CIAO
                    </span>
                  }
                  {source && source.toLowerCase().includes('h360') &&
                    <span className="badge bg-secondary badge-table" style={{ height: 30, paddingTop: 10 }}>
                      H360
                    </span>
                  }
                </div>
              </div>
            </td>
            <td>{source}</td>
            <td>{id}</td>
            <td>{customer_name}</td>
            <td>
              <ul className='list-unstyled m-0'>
                <li>{status_1}</li>
                {status_2 &&
                  <li>{status_2}</li>
                }
                {status_3 &&
                  <li>{status_3}</li>
                }
                {status_4 &&
                  <li>{status_4}</li>
                }
              </ul>
            </td>
            <td>{appointment_at}</td>
            <td>{calendar_at}</td>
          </>
        }
      </tr >
    )
  }) : <tr>
    <td colSpan="7" className="text-center">No results found</td>
  </tr>

  useEffect(() => {

    if (Array.isArray(transfers.dialer_list)) {
      const newLeadsIdsArray = transfers.dialer_list
        .filter((leads) => {
          return transfersParams.opIdFrom && (transfersParams.opStatusGroup === leads.status_group || transfersParams.opStatusGroup === null);
        })
        .map((leads) => leads.id);

      dispatch(setTransfersParams({ ...transfersParams, selectedLeads: newLeadsIdsArray }));
    }
    
  }, [transfersParams.opIdFrom, transfersParams.opStatusGroup, transfers.dialer_list]);

  return (
    <div className="table-responsive datatable-custom table-fix-head card" id="table-dialer">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="8" className="mt-2 mb-2">
                {transfersParams.opIdFrom &&
                  <h5 className="text-center text-white mb-0 pb-2">
                    <div className="d-flex justify-content-center">
                      <span style={{ marginTop: 15 }} >DIALER LIST</span>
                      <Badge color="secondary" badgeContent={transfers.totals} style={{ margin: '10px 0 0 10px' }} max={9999} showZero>
                        <Button disabled variant="contained" style={{ backgroundColor: 'white', color: 'black', fontSize: 10 }}>OP{transfersParams.opIdFrom}</Button>
                      </Badge>
                    </div>
                  </h5>
                }
              </th>
            </tr>
            <tr>
              <th></th>
              <th>SOURCE</th>
              <th>ID</th>
              <th>NAME/SURNAME</th>
              <th>STATUS</th>
              <th>APPOINTMENT AT</th>
              <th>CALENDAR AT</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {transfersParams.opIdFrom ? (
              list
            ) : (
              <tr className="text-primary card-subtitle mb-2">
                <td colSpan="8" >No Results</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

    </div>
  )

}


export default TableSettingsTransfer