import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const TableLogReleases = () => {

  const logReleases = useSelector((state) => state.logReleases.data);

  const list = logReleases.map((log) => {

    const { id, version, description, created_at } = log;

    return (
      <tr key={id}>
        <td>{version}</td>
        <td dangerouslySetInnerHTML={{ __html: description }} style={{ textAlign:'left' }}></td>
        <td>{dayjs(created_at).format('DD/MM/YYYY HH:mm')}</td>
      </tr>
    )
  })

  return (
    <div className="table-responsive datatable-custom table-fix-head card" id="table-log-releases">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th>VERSION</th>
              <th>DESCRIPTION</th>
              <th>CREATED AT</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {list}
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default TableLogReleases