import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';

const initialParams = {
    opIdFrom: '',
    opIdTo: [],
    opNameFrom: '',
    opStatusGroup: null,
    selectedLeads: [],
    refreshTransfers: false,
    isError: false
}

const transfersSlices = createSlice({
    name: 'transfers',
    initialState: {
        data: [],
        params: initialParams
    },
    reducers: {
        setTransfersParams: (state, action) => {
            state.params = action.payload;
        },
        setOpIdTo: (state, action) => {
            const newId = action.payload;
            // Crea una nuova copia dell'oggetto params e assicurati che opIdTo sia un array
            state.params = {
                ...state.params,
                opIdTo: Array.isArray(state.params.opIdTo) ? [...state.params.opIdTo] : [],
            };
            // Se l'ID è già presente nell'array, rimuovilo, altrimenti aggiungilo
            if (state.params.opIdTo.includes(newId)) {
                state.params.opIdTo = state.params.opIdTo.filter(id => id !== newId) || [];
            } else {
                state.params.opIdTo = [...state.params.opIdTo, newId];
            }
        },
        setRefreshTransfers: (state, action) => {
            state.refreshTransfers = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransfers.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchTransfers.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchTransfers.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
            .addCase(updateTransfers.pending, (state) => {
                state.isError = false;
            })
            .addCase(updateTransfers.fulfilled, (state) => {
                state.params.opStatusGroup = null;
                state.isError = false;
            })
            .addCase(updateTransfers.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setTransfersParams, setOpIdTo, setRefreshTransfers } = transfersSlices.actions;

export const fetchTransfers = createAsyncThunk('fetch/transfers', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true));

        const state = getState();
        const token = state.auth.token;
        const opIdFrom = state.transfers.params.opIdFrom;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/op/${opIdFrom || 1}?calendar_status=off&paginate=off`);

        if (response.status === 200) {

            dispatch(setIsLoading(false));
            return response.data;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;

    }

});

export const updateTransfers = createAsyncThunk('update/transfers', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true));

        const state = getState();
        const { token } = state.auth;
        const { opIdFrom, opIdTo, selectedLeads } = state.transfers.params;
        const refreshTransfers = state.transfers.refreshTransfers;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/settings/transfers/update`, {
            op_id_from: opIdFrom,
            op_id_to: opIdTo,
            selected_leads: selectedLeads
        });

        if (response.status === 200) {

            dispatch(setRefreshTransfers(!refreshTransfers));
            return response.data;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;

    }

});


export default transfersSlices.reducer;
