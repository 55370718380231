import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';
import { setCurrentTabEditLead } from '../../../../redux/slices/modalSlice';
import { sendLeadWhatsapp, sendLeadEmail, sendShopEmail, setActionBarTwoPressed, storeLogNotes, sendLeadWhatsappCustomerJoy, sendOrderEmail, sendLeadSmsAppointment, updateNoteShop, sendLeadSmsReview, sendLeadSmsCustomerJoy } from '../../../../redux/slices/actionBarTwoSlice';
import { setDialer } from '../../../../redux/slices/dialerSlice';
import { storeLogAction } from '../../../../redux/slices/logActionStoreSlice';
import { generateCustomerJoyToken, setCustomerJoyToken } from '../../../../redux/slices/customerJoyTokenSlice';

const ActionsBarTwo = () => {

    const dispatch = useDispatch();
    const logNotesByLeadId = useSelector((state) => state.logNotesByLeadId.data);
    const { lastStatusId } = useSelector((state) => state.status);
    const { currentModal } = useSelector((state) => state.modal);
    const noteForm = useSelector((state) => state.actionBarTwo.noteForm);
    const dialer = useSelector((state) => state.dialer.data);
    const customerJoyToken = useSelector((state) => state.customerJoyToken.data?.token);

    const save = 'save';
    const saveNoteEmail = 'saveNoteEmail';
    const saveNoteWhatsapp = 'saveNoteWhatsapp';
    const saveNoteWhatsappCustomerJoy = 'saveNoteWhatsappCustomerJoy';
    const saveNoteWhatsappLimitAttempts = 'saveNoteWhatsappLimitAttempts';
    const saveNoteSms = 'saveNoteSms';
    const saveNoteSmsCustomerJoy = 'saveNoteSmsCustomerJoy';
    const saveNoteSmsLimitAttempts = 'saveNoteSmsLimitAttempts';
    const saveNoteSmsReview = 'saveNoteSmsReview';
    const saveShopEmail = 'saveShopEmail';
    const saveShopEmailLeadWhatsapp = 'saveShopEmailLeadWhatsapp';
    const saveOrderWhatsapp = 'saveOrderWhatsapp';
    const saveOrderEmail = 'saveOrderEmail';
    const saveOrderEmailAdministration = 'saveOrderEmailAdministration';

    const handleClickBack = () => {
        dispatch(setCurrentTabEditLead(0));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/actionBarTwo/Back',
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );
    };

    const handleClickCreateTokenCustomerJoy = () => {
        dispatch(generateCustomerJoyToken());
    }

    const isPast = () => {
        const now = dayjs();
        const appointmentAt = dayjs(dialer.appointment_at, 'DD/MM/YYYY HH:mm');
        const isAppointmentExpired = now.isAfter(appointmentAt);
        return isAppointmentExpired ? true : false;
    }

    const handleSaveNote = (value) => {

        dispatch(setCustomerJoyToken({
            token: "",
            success: false,
        }));

        dispatch(setActionBarTwoPressed(value));
        if (noteForm) {
            dispatch(storeLogNotes());
        }
        dispatch(updateNoteShop());
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/actionBarTwo/' + value,
                value: JSON.stringify(dialer),
                input_type: 'button'
            })
        );

        switch (value) {

            case save:
                dispatch(setCurrentTabEditLead(0));
                break;

            case saveNoteEmail:

                dispatch(setCurrentTabEditLead(0));
                dispatch(sendLeadEmail());
                break;

            case saveNoteWhatsapp:

                dispatch(setCurrentTabEditLead(0));
                dispatch(sendLeadWhatsapp());

                break;

            case saveNoteWhatsappCustomerJoy:

                dispatch(setCurrentTabEditLead(0));
                dispatch(sendLeadWhatsappCustomerJoy())

                break;

            case saveNoteWhatsappLimitAttempts:

                dispatch(setCurrentTabEditLead(2));
                dispatch(sendLeadWhatsapp());

                dispatch(
                    setDialer(
                        {
                            ...dialer,
                            status1_id: 22,
                            lead_status_1: {
                                ...dialer.lead_status_1,
                                id: 22,
                                name: 'INVIATO WHATSAPP'
                            }
                        }
                    )
                );

                break;

            case saveNoteSms:

                dispatch(setCurrentTabEditLead(0));
                dispatch(sendLeadSmsAppointment());

                break;

            case saveNoteSmsCustomerJoy:

                dispatch(setCurrentTabEditLead(0));
                dispatch(sendLeadSmsCustomerJoy())

                break;

            case saveNoteSmsLimitAttempts:

                dispatch(
                    setDialer(
                        {
                            ...dialer,
                            status1_id: 28,
                            lead_status_1: {
                                ...dialer.lead_status_1,
                                id: 28,
                                name: 'INVIATO SMS'
                            }
                        }
                    )
                );

                break;

            case saveNoteSmsReview:

                dispatch(sendLeadSmsReview());
                dispatch(setCurrentTabEditLead(0));

                break;

            case saveShopEmail:

                dispatch(sendShopEmail());
                dispatch(setCurrentTabEditLead(0));

                break;

            case saveShopEmailLeadWhatsapp:

                dispatch(sendShopEmail());
                dispatch(sendLeadWhatsapp());
                dispatch(setCurrentTabEditLead(0));

                break;

            case saveOrderWhatsapp:

                dispatch(sendLeadWhatsapp());
                dispatch(setCurrentTabEditLead(0));

                break;

            case saveOrderEmail:

                dispatch(sendOrderEmail('SAVE_NOTE_+_ORDER_EMAIL'))

                break;

            case saveOrderEmailAdministration:

                dispatch(sendOrderEmail('SAVE_NOTE_+_ORDER_EMAIL_ADMINISTRATION'))

                break;

            default:

                console.log('NO_ACTIONS CALLED!');

        }
    }



    return (
        <div className='fixed-bottom bg-white p-4 d-flex justify-content-center'>
            <Stack spacing={2} direction="row">
                { /* BACK */}
                <Button onClick={handleClickBack} variant="outlined" startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
                { /* SAVE */}
                <Button type="submit" variant="contained" onClick={() => handleSaveNote(save)}>Save</Button>
                { /* CUSTOMER JOY */}
                {(lastStatusId === 1 || lastStatusId === 20) &&
                    <>
                        {customerJoyToken ? (
                            <>
                                <Button className="button-sms" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteSmsCustomerJoy)}>Save + SMS Customer Joy</Button>
                                <Button className="button-whatsapp" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteWhatsappCustomerJoy)}>Save + WA Customer Joy</Button>
                            </>
                        ) : (
                            <div className="btn btn-customer-joy btn-shadow" type="submit" onClick={() => handleClickCreateTokenCustomerJoy()}>CUSTOMER JOY TOKEN</div>
                        )}
                    </>
                }
                { /* SAVE + LEAD EMAIL */}
                {dialer.customer_email && (lastStatusId === 5 || lastStatusId === 11) &&
                    <Button className="button-email" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteEmail)}>Save + Lead Email</Button>
                }
                { /* SAVE + LEAD EMAIL (MISSING CUSTOMER EMAIL) */}
                {!dialer.customer_email && (lastStatusId === 5 || lastStatusId === 11) &&
                    <Button color="error" type="submit" variant="contained">Save + Lead Email (Customer Email is Missing)</Button>
                }
                { /* SAVE + WHATSAPP + SMS (LIMIT_ATTEMPTS) */}
                {lastStatusId === 2 && logNotesByLeadId.log_notes_in_sospeso_count === 3 &&
                    <>
                        <Button className="button-sms" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteSmsLimitAttempts)}>Save + Lead SMS</Button>
                        <Button className="button-whatsapp" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteWhatsappLimitAttempts)} color="success">Save + Lead Whatsapp</Button>
                    </>
                }
                { /* SAVE + ECOMMERCE ORDER EMAIL */}
                {dialer.lead_status_1?.id === 42 &&
                    <>
                        <Button className="button-whatsapp" type="submit" variant="contained" onClick={() => handleSaveNote(saveOrderWhatsapp)}>Save + Lead Whatsapp</Button>
                        <Button className="button-email" type="submit" variant="contained" onClick={() => handleSaveNote(saveOrderEmail)}>Save + Order Email</Button>
                        <Button className="button-email" type="submit" variant="contained" onClick={() => handleSaveNote(saveOrderEmailAdministration)}>Save + Order Email + Administration</Button>
                    </>
                }
                { /* SAVE + SMS REVIEW */}
                {dialer.lead_status_3?.id === 101 && dialer.lead_status_4?.id >= 150 &&
                    <>
                        <Button className="button-email" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteSmsReview)}>Save + Lead SMS Review</Button>
                    </>
                }
                { /* SHOP IS NOT ASSOCIATED */}
                {!dialer.shop?.id &&
                    <Button color="error" variant="contained">Shop is not associated</Button>
                }
                { /* SHOP EMAIL IS MISSING */}
                {(([17, 19].includes(lastStatusId))) && (!dialer.shop_info?.shop_email_1 || !dialer.shop_info?.shop_email_2) &&
                    <>
                        <Button color="error" variant="contained">Save + Shop Email (Shop Email is missing)</Button>
                        <Button className="button-whatsapp" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteWhatsapp)}>Save + Lead Whatsapp</Button>
                        <Button className="button-sms" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteSms)}>Save + Lead SMS</Button>
                        <Button color="error" variant="contained">Save + Shop Email + Lead Whatsapp (Shop Email is missing)</Button>
                    </>
                }
                { /* APPOINTMENT AT IS PAST */}
                {(([17, 19].includes(dialer.lead_status_1?.id))) && (isPast()) &&
                    <>
                        <Button color="error" variant="contained">Save + Shop Email (Appointment Date Has Expired)</Button>
                        <Button className="button-whatsapp" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteWhatsapp)}>Save + Lead Whatsapp</Button>
                        <Button className="button-sms" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteSms)}>Save + Lead SMS</Button>
                        <Button color="error" variant="contained">Save + Shop Email + Lead Whatsapp (Appointment Date Has Expired)</Button>
                    </>
                }
                { /* SHOP IS ASSOCIATED */}
                {(dialer.shop?.id && ([17, 19, 51, 52, 53, 55, 101].includes(lastStatusId))) &&
                    <>
                        <Button className="button-email" type="submit" variant="contained" onClick={() => handleSaveNote(saveShopEmail)}>Save + Shop Email</Button>
                        <Button className="button-whatsapp" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteWhatsapp)}>Save + Lead Whatsapp</Button>
                        <Button className="button-sms" type="submit" variant="contained" onClick={() => handleSaveNote(saveNoteSms)}>Save + Lead SMS</Button>
                        <Button className="button-whatsapp" type="submit" variant="contained" onClick={() => handleSaveNote(saveShopEmailLeadWhatsapp)}>Save + Shop Email + Lead Whatsapp</Button>
                    </>
                }
            </Stack>
        </div >
    )
}

export default ActionsBarTwo