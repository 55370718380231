import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { deleteRemarketing, resetRemarketing, setRefreshRemarketing } from '../../../redux/slices/remarketingSlice';

const TableSettingsRemarketing = () => {

  const dispatch = useDispatch();
  const remarketing = useSelector((state) => state.remarketing.data);
  const refreshRemarketing = useSelector((state) => state.remarketing.refreshRemarketing);

  const handleClickDeleteRmkt = (id) => {
    dispatch(deleteRemarketing(id))
    dispatch(setRefreshRemarketing(!refreshRemarketing))
  }

  const handleResetRmkt = (id) => {
    dispatch(resetRemarketing(id))
    dispatch(setRefreshRemarketing(!refreshRemarketing))
  }

  const list = Array.isArray(remarketing) ? remarketing.map((rmkt, index) => {

    const { id, type, from, to, created_at, totals } = rmkt;

    return (
      <tr key={index}>
        <td>
          <IconButton
            variant="text"
            sx={{
              ml: 0,
              borderRadius: "4px",
              bgcolor: 'danger.main',
              color: 'white',
              ':hover': {
                bgcolor: 'danger_secondary.main',
                color: 'white',
              },
            }}
            edge="start" color="inherit" aria-label="close" onClick={() => handleClickDeleteRmkt(id)}>
            <DeleteIcon />
          </IconButton>
        </td>
        <td>{id}</td>
        <td>{type}</td>
        <td>
          <ul className='list-unstyled m-0'>
            {remarketing[index].lead_status_1 &&
              <li>{remarketing[index].lead_status_1.name}</li>
            }
            {remarketing[index].lead_status_2 &&
              <li>{remarketing[index].lead_status_2.name}</li>
            }
            {remarketing[index].lead_status_3 &&
              <li>{remarketing[index].lead_status_3.name}</li>
            }
            {remarketing[index].lead_status_4 &&
              <li>{remarketing[index].lead_status_4.name}</li>
            }
          </ul>
        </td>
        <td>{dayjs(from).format('DD/MM/YYYY')}</td>
        <td>{dayjs(to).format('DD/MM/YYYY')}</td>
        <td>{dayjs(created_at).format('DD/MM/YYYY HH:mm')}</td>
        <td>{totals}</td>
        <td>{totals === 0 &&
          <Button variant="contained" onClick={() => handleResetRmkt(id)}>Reset</Button>
        }</td>
      </tr>
    )
  }) : <tr>
    <td colSpan="4" className="text-center">No results found</td>
  </tr>

  return (
    <div className="table-responsive datatable-custom table-fix-head card" id="table-dialer">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="9" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">
                  <div className="d-flex justify-content-center">
                    <span style={{ marginTop: 15 }} >REMARKETING LIST ({remarketing.length})</span>
                  </div>
                </h5>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>ID</th>
              <th>TYPE</th>
              <th>STATUS</th>
              <th>FROM</th>
              <th>TO</th>
              <th>CREATED AT</th>
              <th>TOTALS</th>
              <th>RESET</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {list}
          </tbody>
        </table>
      </div>

    </div>
  )

}


export default TableSettingsRemarketing