import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';

const historyLeadSlice = createSlice({
    name: 'historyLead',
    initialState: {
        data: [],
        isError: false
    },
    reducers: {
        setHistoryLead: (state, action) => {
            state.data = action.payload;
        },
        setDisableLeadHistoryLead: (state, action) => {
            const dataitemIndex = action.payload;
            if (dataitemIndex !== -1) {
                const selectedItem = state.data[dataitemIndex];
                selectedItem.disabled = !selectedItem.disabled;
                selectedItem.lead_status_1.name = !selectedItem.disabled ? 'IN ATTESA' : 'DISATTIVATO';
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHistoryLead.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchHistoryLead.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchHistoryLead.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setHistoryLead, setDisableLeadHistoryLead } = historyLeadSlice.actions;

export const fetchHistoryLead = createAsyncThunk('fetch/historyLead', async (_, { getState }) => {

    try {

        const state = getState();
        const customerPhone = getState().dialer.data.customer_phone;
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get(`/history-lead/${customerPhone}`);
        return response.data;

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export default historyLeadSlice.reducer;
