import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { setLogActionsSearchParams, setRefreshLogActionsSearch } from '../../../../redux/slices/logActionsSearchSlice';

const OpLoggedSearch = () => {

    const dispatch = useDispatch();
    const manager = useSelector((state) => state.manager.data);
    const logActionsSearchParams = useSelector((state) => state.logActionsSearch.params);
    const refreshLogActionsSearch = useSelector((state) => state.logActionsSearch.refreshLogActionsSearch);

    return (
        <Autocomplete
            fullWidth
            id="op_logged"
            options={manager.op_settings}
            getOptionLabel={(option) => option.op_name || logActionsSearchParams.op_name}
            value={logActionsSearchParams.op_id || null}
            isOptionEqualToValue={(option) => option.op_name === logActionsSearchParams.op_name}
            onChange={(event, option) => {
                if (option) {
                    dispatch(
                        setLogActionsSearchParams(
                            {
                                ...logActionsSearchParams,
                                op_id: option.id,
                                op_name: option.op_name
                            })
                    );
                }
                else {
                    dispatch(
                        setLogActionsSearchParams(
                            {
                                ...logActionsSearchParams,
                                op_id: null,
                                op_name: null
                            })
                    );
                }
                dispatch(setRefreshLogActionsSearch(!refreshLogActionsSearch))
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        {option.op_name}
                    </li>
                );
            }}
            renderInput={(params) => <TextField fullWidth {...params} label="Op Logged" />}
        />
    )
}

export default OpLoggedSearch
