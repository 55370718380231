import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { copyToClipboard } from '../../helpers.js'
import Pagination from '@mui/material/Pagination';
import { setDialerListQueryPage, setRefreshManager } from '../../redux/slices/managerSlice.js';
import { storeLogAction } from '../../redux/slices/logActionStoreSlice';
import BadgeCustomerJoy from './BadgeCustomerJoy.js';

const DialerList = () => {

    const dispatch = useDispatch();
    const logCustomerJoyTokens = useSelector((state) => state.logCustomerJoyToken.data.data);
    const manager = useSelector((state) => state.manager.data);
    const { refreshManager } = useSelector((state) => state.manager);

    const goToPage = (event, value) => {
        dispatch(setDialerListQueryPage(value))
        dispatch(setRefreshManager(!refreshManager))
        dispatch(
            storeLogAction({
                type: 'manager',
                section: 'manager/dialer/dialerListPage',
                value,
                input_type: 'button'
            })
        );
    }

    const list = Array.isArray(manager.dialer_list?.data) ? manager.dialer_list?.data.map((leads) => {

        const { id, source, customer_name, status_1, status_2, status_3, status_4, appointment_at, calendar_at, status_group, rmkt } = leads;
        const isCustomerJoy = logCustomerJoyTokens && logCustomerJoyTokens.some(token => token.lead_id === id);

        return (
            <tr key={id}>
                {id !== 1 &&
                    <>
                        <td style={{ width: 190 }}>
                            <div className="row div-table-center" style={{ marginLeft: 1 }}>
                                <div className="col-4" style={{ marginRight: 0 }}>

                                <BadgeCustomerJoy isCustomerJoy={isCustomerJoy} user_action={leads.user_action} user_answer={leads.user_answer} lead_status_1={leads.lead_status_1} />

                                </div>
                                <div className="col-4" style={{ marginRight: 0 }}>
                                    {rmkt > 0 ? (
                                        <span className="badge source-dialer">RMKT</span>
                                    ) : (
                                        <span className="badge source-dialer">{status_group.includes('pai') ? status_group.substring(0, 3) : status_group.substring(0, 2)}</span>
                                    )
                                    }
                                </div>
                                <div className="col-4">
                                    {status_1 && status_1 === "IN ATTESA" &&
                                        <span className="badge source-dialer">
                                            NEW!
                                        </span>
                                    }
                                    {source && source.toLowerCase().includes('ciao') &&
                                        <span className="badge source-dialer">
                                            CIAO
                                        </span>
                                    }
                                    {source && source.toLowerCase().includes('h360') &&
                                        <span className="badge source-dialer">
                                            H360
                                        </span>
                                    }
                                </div>
                            </div>
                        </td>
                        <td className='copy-clipboard' onClick={() => { copyToClipboard(source) }}>{source}</td>
                        <td className='copy-clipboard' onClick={() => { copyToClipboard(id) }}>{id}</td>
                        <td className='copy-clipboard' onClick={() => { copyToClipboard(customer_name) }}>{customer_name}</td>
                        <td>
                            <ul className='list-unstyled m-0'>
                                <li>{status_1}</li>
                                {status_2 &&
                                    <li>{status_2}</li>
                                }
                                {status_3 &&
                                    <li>{status_3}</li>
                                }
                                {status_4 &&
                                    <li>{status_4}</li>
                                }
                            </ul>
                        </td>
                        <td className='copy-clipboard' onClick={() => { copyToClipboard(appointment_at) }}>{appointment_at}</td>
                        <td className='copy-clipboard' onClick={() => { copyToClipboard(calendar_at) }}>
                            {calendar_at}
                        </td>
                    </>
                }
            </tr >
        )
    }) : <tr>
        <td colSpan="8" className="text-center">No results found</td>
    </tr>


    return (
        <div className="table-responsive datatable-custom table-fix-head card" id="table-dialer">
            <div className="table-responsive text-nowrap">
                <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
                    <thead>
                        <tr>
                            <th colSpan="8" className="mt-2 mb-2">
                                <h5 className="text-center text-white mb-0 pb-2">DIALER LIST</h5>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>SOURCE</th>
                            <th>ID</th>
                            <th>NAME/SURNAME</th>
                            <th>STATUS</th>
                            <th>APPOINTMENT AT</th>
                            <th>CALENDAR AT</th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {list}
                    </tbody>
                </table>
            </div>
            <div className="col-12 d-flex justify-content-center mt-3">
                {manager && manager.dialer_list?.last_page > 1 &&
                    <Pagination count={manager.dialer_list?.last_page} defaultPage={manager.dialer_list?.current_page} onChange={goToPage} color="primary" />
                }
            </div>
        </div>
    )
}

export default DialerList 