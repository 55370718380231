import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { setIsDrawerOpen } from '../../../redux/slices/logActionsSearchSlice';
import ReactJson from 'react-json-view'

const DrawerLogActions = () => {

    const dispatch = useDispatch();
    const { isDrawerOpen, valueObject } = useSelector((state) => state.logActionsSearch);

    let isValidJSON = true;

    try {
        JSON.parse(valueObject);
    } catch (error) {
        isValidJSON = false;
    }

    return (
        <div>
            <Drawer
                open={isDrawerOpen}
                onClose={() => dispatch(setIsDrawerOpen(false))}
                PaperProps={{
                    sx: { width: "30%" },
                }}
            >
                <Button onClick={() => dispatch(setIsDrawerOpen(false))}>close</Button>
                {isValidJSON ? (
                    <ReactJson src={JSON.parse(valueObject)} />
                ) : (
                    <p style={{ marginLeft: 20 }}>La stringa non è un JSON valido.</p>
                )}
            </Drawer>
        </div>
    );
}

export default DrawerLogActions