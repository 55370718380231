import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { setLeadSearchParams, setRefreshLeadSearch } from '../../../../redux/slices/leadSearchSlice';

const ItalyDbSearch = () => {

    const dispatch = useDispatch();
    const italyDb = useSelector((state) => state.italyDb.data);
    const leadSearchParams = useSelector((state) => state.leadSearch.params);
    const { refreshLeadSearch } = useSelector((state) => state.leadSearch);

    return (
        <Autocomplete
            fullWidth
            id="shop_province"
            options={italyDb}
            getOptionLabel={(option) => option.comune || leadSearchParams.shop_province}
            onChange={(event, option) => {
                if (option) {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, shop_province: option.comune }));
                }
                else {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, shop_province: "" }));
                }
                dispatch(setRefreshLeadSearch(!refreshLeadSearch));
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField fullWidth {...params} label="Municipalities" />}
        />
    )
}

export default ItalyDbSearch
