import React from 'react';
import { useSelector } from 'react-redux';
import { copyToClipboard } from '../../../../../helpers.js'

const LogNotesByCustomerPhoneTable = () => {

  const logNotesByCustomerPhone = useSelector((state) => state.logNotesByCustomerPhone.data);

  const listLogNote = logNotesByCustomerPhone[0]?.map((notes) => {

    const { id, lead_id, op_logged, created_at, lead_status_1, lead_status_2, lead_status_3, lead_status_4, note } = notes;

    return (
      <tr key={id}>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(id) }}>{id}</td>
        <td className='copy-clipboard' onClick={() => { copyToClipboard(lead_id) }}>{lead_id}</td>
        <td>{op_logged}</td>
        <td>{created_at}</td>
        <td>
          <ul className='list-unstyled m-0'>
            <li>{lead_status_1?.name}</li>
            <li>{lead_status_2?.name}</li>
            <li>{lead_status_3?.name}</li>
            <li>{lead_status_4?.name}</li>
          </ul>
        </td>
        <td className='col-responsive' dangerouslySetInnerHTML={{ __html: note.replace(/((\d[\d\-.]*){9,})/g, '<a href="tel://$1">$1</a>') }}></td>
      </tr>
    )
  })

  return (
    <div className="table-responsive datatable-custom table-fix-head card mt-4" id="table-log-notes">
      <div className="table-responsive text-nowrap">
        <table className="table table-hover table table-borderless table-striped table-text-center table-align-middle">
          <thead>
            <tr>
              <th colSpan="8" className="mt-2 mb-2">
                <h5 className="text-center text-white mb-0 pb-2">LOG NOTES BY CUSTOMER PHONE</h5>
              </th>
            </tr>
            <tr>
              <th>ID</th>
              <th>LEAD ID</th>
              <th>OP LOGGED</th>
              <th>CREATED AT</th>
              <th>STATUS</th>
              <th>NOTE</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {listLogNote && listLogNote.length > 0 ? (
              listLogNote
            ) : (
              <tr className="text-primary card-subtitle mb-2">
                <td colSpan="6" >No Results</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default LogNotesByCustomerPhoneTable