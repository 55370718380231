import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material'
import Button from '@mui/material/Button';
import { copyToClipboard } from '../../../../helpers';
import { setDialer } from '../../../../redux/slices/dialerSlice';
import { setNoteForm } from '../../../../redux/slices/actionBarTwoSlice';
import LogNotesByLeadIdTable from './TabLogNotes/LogNotesByLeadIdTable';
import LogNotesByCustomerPhoneTable from './TabLogNotes/LogNotesByCustomerPhoneTable';

const TabNotes = () => {

    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const noteForm = useSelector((state) => state.actionBarTwo.noteForm);
    const { lastStatusName, message } = useSelector((state) => state.status);

    const copyScript = (message) => {
        if(dialer.lead_status_1.id === 42){
            copyToClipboard(message)
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <ul className='list-unstyled'>

                        <li className='mb-2'>
                            <Button disabled variant="contained" style={{ color: 'black', marginRight: 10, fontSize: 10 }}>Status</Button>
                            <span className='copy-clipboard' onClick={() => { copyToClipboard(lastStatusName) }}>{lastStatusName}</span>
                        </li>

                        {(dialer.lead_status_1.id === 17 || dialer.lead_status_1.id === 19) &&
                            <>
                                <li className='mb-2'>
                                    <Button disabled variant="contained" style={{ color: 'black', marginRight: 10, fontSize: 10 }}>Shop Email</Button>
                                    <span>
                                        <span className='copy-clipboard' style={{ marginRight: 10 }} onClick={() => { copyToClipboard(dialer.shop_info?.shop_email_1) }}>{dialer.shop_info?.shop_email_1}</span>
                                        <span className='copy-clipboard' onClick={() => { copyToClipboard(dialer.shop_info?.shop_email_2) }}>{dialer.shop_info?.shop_email_2}</span>
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <Button disabled variant="contained" style={{ color: 'black', marginRight: 10, fontSize: 10 }}>Appointment At</Button>
                                    <span className='copy-clipboard' onClick={() => { copyToClipboard(dialer.appointment_at) }}>{dialer.appointment_at}</span>
                                </li>
                            </>
                        }

                        {message &&
                            <li className='mb-2'>
                                <Button disabled variant="contained" style={{ color: 'black', marginRight: 10, fontSize: 10 }}>Script</Button>
                                <span className='disabled-selected' onClick={() => copyScript(message)}>{message}</span>
                            </li>
                        }

                    </ul>
                </div>
                <div className="col-6">
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-6">
                    <TextField
                        required
                        onChange={(event) => {
                            dispatch(setNoteForm(event.target.value))
                        }}
                        value={noteForm || ""}
                        fullWidth
                        id="log_notes" label="Note" multiline rows={8} />
                </div>
                {(dialer.lead_status_1.id === 17 || dialer.lead_status_1.id === 19 || dialer.lead_status_1?.id !== 42) &&
                    <div className="col-6">
                        <TextField
                            onChange={event => dispatch(setDialer({ ...dialer, note_shop: event.target.value }))}
                            value={dialer.note_shop || ""}
                            fullWidth
                            id="note_shop" label={dialer.lead_status_1.id !== 42 ? 'Note to Shop' : 'Order Note'} multiline rows={8} />
                    </div>
                }

                {/* {dialer.lead_status_1.id === 42 && (
                    <div className="col-6" style={{ height:220, overflow: 'auto' }}>
                        {dialer.note_shop?.split('@').map((note, index) => (
                            <div key={index}>
                                {`${note}`}
                            </div>
                        ))}
                    </div>
                )} */}

            </div>

            <div className="row mt-10" style={{ marginTop: 20 }}>
                <LogNotesByLeadIdTable />
                <LogNotesByCustomerPhoneTable />
            </div>
        </>
    )
}

export default TabNotes