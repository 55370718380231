import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { setLeadSearchParams } from '../../../../redux/slices/leadSearchSlice';

const ShopIds = (props) => {

    const { handleKeyEnter } = props;

    const dispatch = useDispatch();
    const leadSearchParams = useSelector((state) => state.leadSearch.params);

    return (
        <>
            <TextField style={{ marginTop: 0 }}
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, shop_id: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Shop Id" id="shop_id" value={leadSearchParams.shop_id || ""}
            />
            <TextField
                type='number'
                autoComplete="off"
                onChange={(event) => {
                    dispatch(setLeadSearchParams({ ...leadSearchParams, shop_info_id: event.target.value }));
                }}
                onKeyPress={handleKeyEnter}
                margin="normal" fullWidth label="Shop info id" id="shop_info_id" value={leadSearchParams.shop_info_id || ""}
            />

        </>
    )
}

export default ShopIds