import React from 'react';
import { useSelector } from 'react-redux';
import { copyToClipboard } from '../../../../../helpers';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import GoogleIcon from '@mui/icons-material/Google';
import PropTypes from 'prop-types';
import BadgeCustomerJoy from '../../../../Manager/BadgeCustomerJoy';

const AppBarInfo = ({ handleClose }) => {

    AppBarInfo.propTypes = {
        handleClose: PropTypes.func.isRequired,
    };

    const logCustomerJoyTokens = useSelector((state) => state.logCustomerJoyToken.data.data);
    const dialer = useSelector((state) => state.dialer.data);
    const isCustomerJoy = logCustomerJoyTokens && logCustomerJoyTokens.some(token => token.lead_id === dialer.id);

    return (

        <AppBar className="app-bar-lead fixed-top" bg="light" expand="lg" style={{ background: '#1a73e8', marginRight: 0 }}>

            <Toolbar style={{ minHeight: '30px' }}>

                <IconButton
                    sx={{ position: 'absolute', mt: 7, zIndex: 10000 }}
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>

                <Typography sx={{ mt: 1, ml: 4.2, flex: 1 }} variant="h6">

                    <Badge color="primary" style={{ marginRight: dialer?.user_action ? 5 : 15 }}>
                        <IconButton
                            disabled
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                            }}>
                            <PersonIcon />
                        </IconButton>
                    </Badge>

                    <BadgeCustomerJoy isCustomerJoy={isCustomerJoy} user_action={dialer.user_action} user_answer={dialer.user_answer} lead_status_1={dialer.lead_status_1} />

                    <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.id) }}>
                        <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.id}</Button>
                    </Badge>

                    {dialer.lead_sources?.source &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.lead_sources?.source) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.lead_sources?.source}</Button>
                        </Badge>
                    }

                    {dialer.customer_name &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.customer_name) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.customer_name}</Button>
                        </Badge>
                    }

                    {dialer.customer_phone &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.customer_phone) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.customer_phone}</Button>
                        </Badge>
                    }

                    <Badge color="primary" style={{ marginRight: 15 }}>
                        <IconButton
                            disabled
                            style={{ backgroundColor: 'white', color: 'black' }}>
                            <StoreIcon />
                        </IconButton>
                    </Badge>

                    {dialer.shop?.id &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.shop?.id) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.shop?.id}</Button>
                        </Badge>
                    }

                    {dialer.shop_info?.id &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.shop_info?.id) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.shop_info?.id}</Button>
                        </Badge>
                    }

                    {dialer.shop?.business_name &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.shop?.business_name) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.shop?.business_name}</Button>
                        </Badge>
                    }

                    {(dialer.shop_info?.shop_address && dialer.shop_info?.shop_province) ? (
                        <>

                            <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.shop_info?.shop_address) }}>
                                <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.shop_info?.shop_address}</Button>
                            </Badge>

                            <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.shop_info?.shop_province) }}>
                                <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.shop_info?.shop_province}</Button>
                            </Badge>

                            {dialer.shop_info?.shop_fraction &&
                                <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.shop_info?.shop_fraction) }}>
                                    <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>Fraz. {dialer.shop_info?.shop_fraction}</Button>
                                </Badge>
                            }

                            <Button href={`https://www.google.it/maps/dir/${dialer.customer_zipcode}/${dialer.shop_info?.shop_address},${dialer.shop_info?.shop_province}`} target="_blank" rel="noopener noreferrer" variant="contained" style={{ display: 'inline', padding: '7px 10px 8px 10px', backgroundColor: 'white', color: 'black' }}><GoogleIcon /></Button>
                        </>
                    ) : (
                        <Badge color="primary" style={{ marginRight: 15 }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'red' }}>NOT ASSOCIATED!</Button>
                        </Badge>
                    )
                    }
                </Typography>

            </Toolbar>

            <Toolbar style={{ minHeight: '30px' }}>

                <Typography sx={{ mt: 1, pb: 6, ml: 4.2, flex: 1, height: '10px' }} variant="h6">

                    <Badge color="primary" style={{ marginRight: 15 }}>
                        <IconButton
                            disabled
                            style={{ backgroundColor: 'white', color: 'black' }}>
                            <AppRegistrationIcon />
                        </IconButton>
                    </Badge>

                    <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.lead_status_1.name) }}>
                        <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.lead_status_1.name}</Button>
                    </Badge>
                    {dialer.lead_status_2?.name &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.lead_status_2.name) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.lead_status_2?.name}</Button>
                        </Badge>
                    }
                    {dialer.lead_status_3?.name &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.lead_status_3.name) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.lead_status_3?.name}</Button>
                        </Badge>
                    }
                    {dialer.lead_status_4?.name &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.lead_status_4.name) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.lead_status_4?.name}</Button>
                        </Badge>
                    }
                    {dialer.appointment_at &&
                        <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.appointment_at) }}>
                            <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.appointment_at}</Button>
                        </Badge>
                    }
                    {dialer.appointment_created_at &&
                        <>
                            <Badge color="primary" style={{ marginRight: 10 }}>
                                <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>PRESO IL</Button>
                            </Badge>
                            <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.appointment_created_at) }}>
                                <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.appointment_created_at}</Button>
                            </Badge>
                        </>
                    }
                    {dialer.calendar_at &&
                        <>
                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <IconButton
                                    disabled
                                    style={{ backgroundColor: 'white', color: 'black' }}>
                                    <EventAvailableIcon />
                                </IconButton>
                            </Badge>
                            <Badge color="primary" style={{ marginRight: 15 }} className='copy-clipboard' onClick={() => { copyToClipboard(dialer.calendar_at) }}>
                                <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>{dialer.calendar_at}</Button>
                            </Badge>
                        </>
                    }

                    {parseInt(dialer.rmkt) > 0 &&
                        <>
                            <Badge color="primary" style={{ marginRight: 15 }}>
                                <IconButton
                                    disabled
                                    style={{ backgroundColor: 'white', color: 'black' }}>
                                    <RestoreFromTrashIcon />
                                </IconButton>
                            </Badge>
                            <Badge color="primary">
                                <Button disabled variant="contained" style={{ fontSize: '0.6em', backgroundColor: 'white', color: 'black' }}>REMARKETING</Button>
                            </Badge>
                        </>
                    }
                </Typography>

            </Toolbar>

        </AppBar >
    )
}

export default AppBarInfo