import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import dayjs from 'dayjs';
import { setDialer } from './dialerSlice';
import { setIsLoading } from './managerSlice';
import { setSnackbar } from './snackbarSlices';

const actionBarTwoSlice = createSlice({
    name: 'actionBarTwo',
    initialState: {
        actionBarTwoPressed: '',
        noteForm: '',
        isError: false
    },
    reducers: {
        setActionBarTwoPressed: (state, action) => {
            state.actionBarTwoPressed = action.payload;
        },
        setNoteForm: (state, action) => {
            state.noteForm = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(storeLogNotes.pending, (state) => {
                state.isError = false;
            })
            .addCase(storeLogNotes.fulfilled, (state) => {
                state.isError = false;
            })
            .addCase(storeLogNotes.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setActionBarTwoPressed, setNoteForm } = actionBarTwoSlice.actions;

export const storeLogNotes = createAsyncThunk('store/logNotes', async (_, { getState, dispatch }) => {

    const state = getState();
    const token = state.auth.token;
    const dialer = state.dialer.data;
    const snackbar = state.snackbar;
    const loggedId = state.auth.loggedId;
    const lastStatusId = state.status.lastStatusId;
    const noteForm = state.actionBarTwo.noteForm;

    try {

        dispatch(setIsLoading(true));

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/log-notes/store`,

            {
                lead_id: dialer.id,
                op_logged: loggedId,
                op_master: dialer.op_master,
                customer_phone: dialer.customer_phone,
                status_id: lastStatusId,
                status1_id: dialer.lead_status_1?.id,
                status2_id: dialer.lead_status_2?.id,
                status3_id: dialer.lead_status_3?.id,
                status4_id: dialer.lead_status_4?.id,
                disputed: dialer.disputed,
                log_notes: noteForm
            });

        if (response.status === 200) {

            dispatch(setSnackbar({ ...snackbar, is_saved_note_success: true }))
            dispatch(setIsLoading(false));
            dispatch(setNoteForm(""))
            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        dispatch(setSnackbar({ ...snackbar, is_saved_note_error: true }))
        console.error('Error get Data:', error);
        throw error;

    }

});

export const updateNoteShop = createAsyncThunk('update/noteShop', async (_, { getState, dispatch }) => {

    const state = getState();
    const token = state.auth.token;
    const dialer = state.dialer.data;
    const snackbar = state.snackbar;

    try {

        dispatch(setIsLoading(true));

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.put(`/dialer/note-shop/update`,

            {
                id: dialer.id,
                note_shop: dialer.note_shop
            });

        if (response.status === 200) {

            dispatch(setSnackbar({ ...snackbar, is_saved_note_success: true }))
            dispatch(setIsLoading(false));
            return;

        } else {

            throw new Error('Error updating');

        }


    } catch (error) {

        dispatch(setSnackbar({ ...snackbar, is_saved_note_error: true }))
        console.error('Error get Data:', error);
        throw error;

    }

});

export const sendShopEmail = createAsyncThunk('email/shop', async (_, { getState, dispatch }) => {

    const state = getState();
    const token = state.auth.token;
    const dialer = state.dialer.data;
    const snackbar = state.snackbar;
    const lastStatusName = state.status.lastStatusName;

    try {

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/dialer/actions-bar/2`, {
            type: "SAVE_NOTE_+_SHOP_EMAIL", //sendShopEmail
            id: dialer.id,
            status_1: dialer.lead_status_1.name,
            status_name: lastStatusName,
            note_shop: dialer.note_shop
        });

        if (response.status === 200) {
            dispatch(setSnackbar({ ...snackbar, is_shop_email_success: true }))
            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        dispatch(setSnackbar({ ...snackbar, is_shop_email_error: true }))
        console.error('Error get Data:', error);
        throw error;

    }

});

export const sendLeadEmail = createAsyncThunk('email/lead', async (_, { getState, dispatch }) => {

    const state = getState();
    const token = state.auth.token;
    const dialer = state.dialer.data;
    const snackbar = state.snackbar;
    const message = state.status.message;

    try {


        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/dialer/actions-bar/2`, {
            type: "SAVE_NOTE_+_LEAD_EMAIL", //sendLeadEmail
            id: dialer.id,
            customer_name: dialer.customer_name,
            script_text: message,
        });

        if (response.status === 200) {
            dispatch(setSnackbar({ ...snackbar, is_lead_email_success: true }))
            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        dispatch(setSnackbar({ ...snackbar, is_lead_email_error: true }))
        console.error('Error get Data:', error);
        throw error;

    }

});

export const sendOrderEmail = createAsyncThunk('email/order', async (type, { getState, dispatch }) => {

    const state = getState();
    const token = state.auth.token;
    const dialer = state.dialer.data;
    const snackbar = state.snackbar;
    const lastStatusName = state.status.lastStatusName;

    try {

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/dialer/actions-bar/2`, {
            type,
            id: dialer.id,
            status_1: dialer.lead_status_1.name,
            status_name: lastStatusName,
            note_shop: dialer.note_shop,
            ecommerce_order_note: dialer.ecommerce_order_note
        });

        if (response.status === 200) {
            dispatch(setSnackbar({ ...snackbar, is_order_email_success: true }))
            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        dispatch(setSnackbar({ ...snackbar, is_order_email_error: true }))
        console.error('Error get Data:', error);
        throw error;

    }

});

export const sendLeadWhatsappCustomerJoy = createAsyncThunk('whatsapp/lead/customerJoy', async (_, { getState, dispatch }) => {

    try {

        const state = getState();
        const token = state.auth.token;
        const dialer = state.dialer.data;
        const message = state.status.message;
        const oneDay = dayjs().add(1, 'day').startOf('minute').add(5 - (dayjs().minute() % 5), 'minute');
        const formattedDate = oneDay.locale('it').format('YYYY-MM-DD HH:mm:ss');

        navigator.clipboard.writeText(message);
        setTimeout(() => {
            let directWhatsapp = "https://api.whatsapp.com/send?phone=39" + dialer.customer_phone + "&text=" + message + "";
            window.open(directWhatsapp, '_blank', 'noopener,noreferrer');
        }, 1000);

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/dialer/actions-bar/2`, {
            type: "SAVE_NOTE_+_CUSTOMER_JOY_WHATSAPP", //sendLeadWhatsappCustomerJoy
            id: dialer.id,
            customer_name: dialer.customer_name,
            calendar_at: formattedDate,
            script_text: message,
        });

        if (response.status === 200) {

            dispatch(
                setDialer(
                    {
                        ...dialer,
                        'status1_id': 22,
                        'calendar_at': dayjs(oneDay).format('DD/MM/YYYY HH:mm'),
                        lead_status_1: {
                            ...dialer.lead_status_1,
                            id: 22,
                            name: 'INVIATO WHATSAPP'
                        }
                    }
                )
            );

            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;

    }


});

export const sendLeadSmsCustomerJoy = createAsyncThunk('sms/lead/customerJoy', async (_, { getState, dispatch }) => {

    try {

        const state = getState();
        const token = state.auth.token;
        const dialer = state.dialer.data;
        const message = state.status.message;
        const oneDay = dayjs().add(1, 'day').startOf('minute').add(5 - (dayjs().minute() % 5), 'minute');
        const formattedDate = oneDay.locale('it').format('YYYY-MM-DD HH:mm:ss');

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/dialer/actions-bar/2`, {
            type: "SAVE_NOTE_+_CUSTOMER_JOY_SMS", //sendLeadWhatsappCustomerJoy
            id: dialer.id,
            customer_name: dialer.customer_name,
            customer_phone: dialer.customer_phone,
            calendar_at: formattedDate,
            script_text: message,
        });

        if (response.status === 200) {

            dispatch(
                setDialer(
                    {
                        ...dialer,
                        'status1_id': 28,
                        'calendar_at': dayjs(oneDay).format('DD/MM/YYYY HH:mm'),
                        lead_status_1: {
                            ...dialer.lead_status_1,
                            id: 28,
                            name: 'INVIATO SMS'
                        }
                    }
                )
            );

            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        console.error('Error get Data:', error);
        throw error;

    }


});

export const sendLeadWhatsapp = createAsyncThunk('whatsapp/lead', async (_, { getState }) => {

    const state = getState();
    const dialer = state.dialer.data;
    const message = state.status.message;

    navigator.clipboard.writeText(message);
    setTimeout(() => {
        let directWhatsapp = "https://api.whatsapp.com/send?phone=39" + dialer.customer_phone + "&text=" + message + "";
        window.open(directWhatsapp, '_blank', 'noopener,noreferrer');
    }, 1000);

});

export const sendLeadSmsAppointment = createAsyncThunk('sms/lead/appointment', async (type, { getState, dispatch }) => {

    const state = getState();
    const token = state.auth.token;
    const dialer = state.dialer.data;
    const snackbar = state.snackbar;
    const message = state.status.message;

    try {

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/dialer/actions-bar/2`, {
            type: "SAVE_NOTE_+_LEAD_SMS", //saveNoteSms
            id: dialer.id,
            customer_phone: dialer.customer_phone,
            script_text: message,
        });

        if (response.status === 200) {
            dispatch(setSnackbar({ ...snackbar, is_lead_sms_success: true }))
            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        dispatch(setSnackbar({ ...snackbar, is_lead_sms_error: true }))
        console.error('Error get Data:', error);
        throw error;

    }

});

export const sendLeadSmsReview = createAsyncThunk('sms/lead/review', async (type, { getState, dispatch }) => {

    const state = getState();
    const token = state.auth.token;
    const dialer = state.dialer.data;
    const snackbar = state.snackbar;
    const message = state.status.message;

    try {

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.post(`/dialer/actions-bar/2`, {
            type: "SAVE_NOTE_+_LEAD_SMS_REVIEW", //saveReviewSms
            id: dialer.id,
            customer_phone: dialer.customer_phone,
            script_text: message,
        });

        if (response.status === 200) {
            dispatch(setSnackbar({ ...snackbar, is_lead_sms_success: true }))
            return;

        } else {

            throw new Error('Error updating');

        }

    } catch (error) {

        dispatch(setSnackbar({ ...snackbar, is_lead_sms_error: true }))
        console.error('Error get Data:', error);
        throw error;

    }

});

export default actionBarTwoSlice.reducer;