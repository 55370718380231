import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GoogleIcon from '@mui/icons-material/Google';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { setDialer } from '../../../../../redux/slices/dialerSlice';
import { setAddressDistanceMatrix } from '../../../../../redux/slices/distanceMatrixSlice';
import { storeLogAction } from '../../../../../redux/slices/logActionStoreSlice';

const NearestShopsTable = () => {
    const dispatch = useDispatch();
    const dialer = useSelector((state) => state.dialer.data);
    const nearestShops = useSelector((state) => state.nearestShops.data);
    const distanceMatrix = useSelector((state) => state.distanceMatrix.data);
    const isLoadingDistanceMatrix = useSelector((state) => state.distanceMatrix.isLoadingDistanceMatrix);
    const { currentModal } = useSelector((state) => state.modal);

    const handleAssociateShop = (shop, shop_info) => {
        dispatch(setDialer({
            ...dialer,
            'shop_id': shop.id,
            'shop_info_id': shop_info.id,
            shop,
            shop_info
        }));
        dispatch(
            storeLogAction({
                type: currentModal,
                section: 'modal/shopAssociate/associate',
                value: shop.id + " - " + shop_info.id,
                input_type: 'button'
            })
        );
    }

    const handleDistanceMatrix = (event) => {
        const formattedAddress = event.replace(',', '-').replace(/\s+/g, '-').replace("--", '-').replace("'", '');
        dispatch(setAddressDistanceMatrix(formattedAddress));
    }

    const list = Array.isArray(nearestShops) ? nearestShops.map((shop) => {

        const { distance, shop_info } = shop;

        return (
            <tr key={shop_info.id} className={`${dialer.shop_id === shop_info.shops.id && dialer.shop_info_id === shop_info.id ? "table-success" : ""}`}>
                {dialer.shop_id === shop_info.shops.id && dialer.shop_info_id === shop_info.id ? (
                    <td><Button sx={{ display: 'none' }} type="submit" variant="contained" endIcon={<ArrowForwardIcon />}>ASSOCIATE</Button></td>
                ) : (
                    <td><Button onClick={() => handleAssociateShop(shop_info.shops, shop_info)} type="submit" color={`${shop_info.shop_lead_gen ? "success" : "danger"}`} sx={{ color: '#fff' }} variant="contained" endIcon={<ArrowForwardIcon />}>ASSOCIATE</Button></td>
                )}
                <td className='text-center'>{shop_info.shops.id}</td>
                <td className='text-center'>{shop_info.id}</td>
                <td className='text-left'>
                    <ul className='list-unstyled m-0'>
                        <li>{shop_info.shops.business_name}</li>
                        <li>{shop_info.shop_address}</li>
                        <li>{shop_info.shop_province} ({shop_info.shop_code}) </li>
                        {shop_info.shop_fraction &&
                            <li>Fraz. {shop_info.shop_fraction}</li>
                        }
                        <li>{shop_info.shop_zipcode}</li>
                    </ul>
                    <span className={`badge ${shop_info.shop_branch ? "bg-success " : "bg-danger "}`}>Branch</span>
                    <span className={`badge ${shop_info.shop_lead_gen ? "bg-success " : "bg-danger "}`}>Lead Gen</span>
                    <span className={`badge ${shop_info.shops.premium_subscription ? "bg-success " : "bg-danger "}`}>Premium</span>
                    <span className={`badge ${shop_info.shops.apt_price !== "0" ? "bg-success " : "bg-danger "}`}>Credits {shop_info.shops.apt_price !== "0" ? shop_info.shops.apt_credits : null}</span>
                    <span className={`badge ${shop_info.published ? "bg-success " : "bg-danger "}`}>WWW</span>
                    <div className='mt-1'>
                        {shop_info.shops.business_brands}
                    </div>
                </td>
                <td className='text-center'>{shop_info.shops.apt_cards}</td>
                <td className='text-center'>
                    <HtmlTooltip
                        title={
                            <>
                                {!isLoadingDistanceMatrix ? (
                                    <>
                                        <Typography color="inherit">{distanceMatrix.distance_km}</Typography>
                                        <em>{distanceMatrix.duration}</em>
                                    </>
                                ) : (
                                    <CircularProgress />
                                )}
                            </>
                        }
                    >
                        <Button
                            onMouseEnter={() => handleDistanceMatrix(shop_info.shop_address + " " + shop_info.shop_province + "-" + shop_info.shop_zipcode)}
                            onMouseLeave={() => dispatch(setAddressDistanceMatrix(""))}
                        >
                            {distance}
                        </Button>
                    </HtmlTooltip>
                </td>
                <td className='text-center'><a target="_blank" href={"https://www.google.it/maps/dir/" + dialer.customer_zipcode + "/" + shop_info.shop_address + "," + shop_info.shop_province} rel="noopener noreferrer"><GoogleIcon /></a></td>
            </tr>
        );
    }) : <tr><td colSpan="8" className="text-center">Shops not found</td></tr>;

    return (
        <div className="table-responsive datatable-custom table-fix-head card" id="table-nearest-shop">
            <div className="table-responsive text-nowrap">
                <table className="table table-hover table table-borderless table-striped table-text-left table-align-middle">
                    <thead>
                        <tr>
                            <th></th>
                            <th className='text-center'>IDC</th>
                            <th className='text-center'>IDR</th>
                            <th className='text-left'>SHOP</th>
                            <th className='text-center'>RECHARGES</th>
                            <th className='text-center'>DISTANCE A/L</th>
                            <th className='text-center'>MAP</th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {dialer.customer_zipcode > 0 ? (
                            list
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">Shops not found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default NearestShopsTable;
