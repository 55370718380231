import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, TextField } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Radio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { setDashboardOpParams, setRefreshDashboardOp } from '../../../redux/slices/dashboardOpSlice';

const DashboardSearch = () => {

    const dispatch = useDispatch();
    const dashboardOp = useSelector((state) => state.dashboardOp);
    const sources = useSelector((state) => state.sources.data);

    const handleSwitchMonth = (value) => {
        const prevMonth = new Date(dashboardOp.params.date_from);
        const nextMonth = new Date(dashboardOp.params.date_from);
        if (value === '+') {
            prevMonth.setMonth(prevMonth.getMonth() + 1);
            nextMonth.setMonth(nextMonth.getMonth() + 1);
        } else {
            prevMonth.setMonth(prevMonth.getMonth() - 1);
            nextMonth.setMonth(nextMonth.getMonth() - 1);
        }
        const year = prevMonth.getFullYear();
        const month = prevMonth.getMonth();

        // calcoliamo manualmente la data del primo giorno del mese corrente
        const firstDayOfMonth = new Date(year, month, 1);

        const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
        nextMonth.setDate(lastDayOfMonth);

        // decrementiamo il mese di un'unità e impostiamo il giorno al primo del mese
        prevMonth.setFullYear(firstDayOfMonth.getFullYear());
        prevMonth.setMonth(firstDayOfMonth.getMonth());
        prevMonth.setDate(firstDayOfMonth.getDate());

        dispatch(
            setDashboardOpParams({
                ...dashboardOp.params,
                date_from: dayjs(prevMonth).format('YYYY-MM-DD'),
                date_to: dayjs(nextMonth).format('YYYY-MM-DD')
            })
        );

    }

    const handleOnChangetype_date = (value) => {
        dispatch(
            setDashboardOpParams({
                ...dashboardOp.params,
                type_date: value
            })
        );
        dispatch(setRefreshDashboardOp(!dashboardOp.refreshDashboardOp))
    }

    const handleOnChangetype_sort = (value) => {
        dispatch(
            setDashboardOpParams({
                ...dashboardOp.params,
                type_sort: value,
            })
        );
    }

    const copySources = [...sources];

    const elementsToRemove = copySources
        .filter(source => source.source.includes("_tmp") || source.source === "inoltro")
        .map(source => source.source);

    const customOptionAll = { source: "ALL" };
    const customOptionCiao = { source: "CIAO" };

    const newSources = copySources
        .filter(source => !elementsToRemove.includes(source.source))
        .concat(customOptionAll, customOptionCiao);

    return (
        <>
            <div className='d-flex justify-content-center'>
                <Stack spacing={2} direction="row">
                    <FormControlLabel
                        checked={dashboardOp.params.type_date === 'created_at'}
                        onChange={() => handleOnChangetype_date('created_at')}
                        value="created_at"
                        control={<Radio />}
                        label="Created At"
                    />
                    <FormControlLabel
                        checked={dashboardOp.params.type_date === 'appointment_at'}
                        onChange={() => handleOnChangetype_date('appointment_at')}
                        value="appointment_at"
                        control={<Radio />}
                        label="Appointment At"
                    />
                    <FormControlLabel
                        checked={dashboardOp.params.type_date === 'appointment_created_at'}
                        onChange={() => handleOnChangetype_date('appointment_created_at')}
                        value="appointment_created_at"
                        control={<Radio />}
                        label="Appointment Created At"
                    />
                </Stack>
            </div>
            <div className='d-flex justify-content-center mt-3'>
                <Stack spacing={2} direction="row">

                    <IconButton sx={{ width: 50 }} aria-label="close" onClick={() => handleSwitchMonth('-')}>
                        <ArrowBackIcon />
                    </IconButton>

                    {/* DATE FROM */}
                    <LocalizationProvider
                        id="date_from"
                        dateAdapter={AdapterDayjs}
                        adapterLocale="it">
                        <DesktopDatePicker
                            label="From"
                            value={dashboardOp.params.date_from ? dayjs(dashboardOp.params.date_from, 'YYYY-MM-DD') : null}
                            onChange={(newValue) => {
                                dispatch(setDashboardOpParams({ ...dashboardOp.params, date_from: dayjs(newValue).format('YYYY-MM-DD') }));
                            }}
                            inputProps={{ readOnly: true }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    {/* DATE TO */}
                    <LocalizationProvider
                        id="date_to"
                        dateAdapter={AdapterDayjs}
                        adapterLocale="it">
                        <DesktopDatePicker
                            label="To"
                            value={dashboardOp.params.date_to ? dayjs(dashboardOp.params.date_to, 'YYYY-MM-DD') : null}
                            onChange={(newValue) => {
                                dispatch(setDashboardOpParams({ ...dashboardOp.params, date_to: dayjs(newValue).format('YYYY-MM-DD') }));
                            }}
                            inputProps={{ readOnly: true }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    {/* SOURCES */}
                    <Autocomplete
                        required
                        disableClearable
                        id="source"
                        options={newSources}
                        value={dashboardOp.params.type_source || null}
                        getOptionLabel={(option) => option.source || dashboardOp.params.type_source}
                        isOptionEqualToValue={(option, value) => option.source === value}
                        onChange={(event, option) => {
                            if (option) {
                                dispatch(
                                    setDashboardOpParams(
                                        {
                                            ...dashboardOp.params,
                                            type_source: option.source
                                        })
                                );
                            } else {
                                dispatch(
                                    setDashboardOpParams(
                                        {
                                            ...dashboardOp.params,
                                            type_source: ""
                                        }
                                    )
                                );
                            }
                        }}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.source}
                                </li>
                            );
                        }}
                        renderInput={(params) => <TextField sx={{ marginTop: 0, width: 180 }} required margin="normal" {...params} label="Source" />}
                    />

                    {/* NEXT ARROW => */}
                    <IconButton sx={{ width: 50 }} aria-label="close" onClick={() => handleSwitchMonth('+')}>
                        <ArrowForwardIcon />
                    </IconButton>
                </Stack>
            </div>
            <div className='d-flex justify-content-center'>
                <Stack spacing={2} direction="row">
                    <FormControlLabel
                        checked={dashboardOp.params.type_sort === 'sort_by_rate'}
                        onChange={() => handleOnChangetype_sort('sort_by_rate')}
                        value="sort_by_rate"
                        control={<Radio />}
                        label="Rate"
                    />
                    <FormControlLabel
                        checked={dashboardOp.params.type_sort === 'sort_by_positive_apt'}
                        onChange={() => handleOnChangetype_sort('sort_by_positive_apt')}
                        value="sort_by_positive_apt"
                        control={<Radio />}
                        label="Positive Apt"
                    />
                </Stack>
            </div>
            <div className='d-flex justify-content-center mt-2 mb-4'>
                <Button variant="contained" onClick={() => dispatch(setRefreshDashboardOp(!dashboardOp.refreshDashboardOp))}>Filter</Button>
            </div>
        </>
    )
}

export default DashboardSearch