import React from 'react';
import { useSelector } from 'react-redux';
import DialerNavbar from './DialerNavbar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { copyToClipboard } from '../../helpers.js'
import BadgeCustomerJoy from './BadgeCustomerJoy.js';

const Dialer = () => {

    const dialer = useSelector((state) => state.dialer.data);
    const manager = useSelector((state) => state.manager.data);
    const logCustomerJoyTokens = useSelector((state) => state.logCustomerJoyToken.data.data);
    const isCustomerJoy = logCustomerJoyTokens && logCustomerJoyTokens.some(token => token.lead_id === dialer.id);

    const { id, created_at, customer_name, customer_phone, customer_email, appointment_at, calendar_at } = dialer || [];
    const { lead_id, created_at: created_at_note, op_assigned, note } = manager.last_note ?? {};

    return (

        <div className="card h-100" id="dialer">

            <DialerNavbar />

            <div className="card-body pt-0">

                {manager.totals > 0 && id !== 1 &&
                    <div>
                        {calendar_at && (
                            <div className="badge calendar-dialer">
                                <CalendarMonthIcon />
                            </div>
                        )}
                        {manager.dialer_list.data[0].status_group?.substring(0, 4) === 'rmkt' ? (
                            <div className="badge source-dialer">
                                RMKT
                            </div>
                        ) : (
                            <div className="badge source-dialer">
                                {manager.dialer_list.data[0].status_group.substring(0, 2)}
                            </div>
                        )}
                        {manager.dialer_list.data[0].status_1 === "IN ATTESA" && (
                            <div className="badge source-dialer">
                                NEW!
                            </div>
                        )}
                        {manager.dialer_list?.data[0].source?.toLowerCase().includes('ciao') && (
                            <div className="badge source-dialer">
                                CIAO
                            </div>
                        )}
                        {manager.dialer_list.data[0].source?.toLowerCase().includes('h360') && (
                            <div className="badge source-dialer">
                                H360
                            </div>
                        )}

                        <BadgeCustomerJoy isCustomerJoy={isCustomerJoy} user_action={dialer.user_action} user_answer={dialer.user_answer} lead_status_1={dialer.lead_status_1} />

                        <div>
                            <hr />
                            <small className="text-primary card-subtitle mb-2">LEAD INFO</small>
                            <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(dialer.lead_sources?.source) }}>{dialer.lead_sources?.source}</p>

                            <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(id) }}>{id}</p>
                            <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(created_at) }}>{created_at}</p>
                            <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(customer_name) }}><strong>{customer_name}</strong></p>
                            <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(customer_phone) }}>{customer_phone}</p>
                            {customer_email &&
                                <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(customer_email) }}>{customer_email}</p>
                            }
                        </div>

                        {appointment_at &&
                            <div>
                                <hr />
                                <small className="text-primary card-subtitle mb-2">APPOINTMENT AT</small>
                                <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(customer_email) }}>{appointment_at}</p>
                            </div>
                        }

                        <div>
                            <hr />
                            <small className="text-primary card-subtitle mb-2">STATUS</small>
                            <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(dialer.lead_status_1?.name) }}>{dialer.lead_status_1?.name}</p>
                            {dialer && dialer.lead_status_2 &&
                                <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(dialer.lead_status_2?.name) }}>{dialer.lead_status_2?.name}</p>
                            }
                            {dialer && dialer.lead_status_3 &&
                                <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(dialer.lead_status_3?.name) }}>{dialer.lead_status_3?.name}</p>
                            }
                            {dialer && dialer.lead_status_4 &&
                                <p className="copy-clipboard card-text mb-0" onClick={() => { copyToClipboard(dialer.lead_status_4?.name) }}>{dialer.lead_status_4?.name}</p>
                            }
                        </div>

                        {calendar_at &&
                            <div>
                                <hr />
                                <small className="text-primary card-subtitle mb-2">CALENDAR AT</small>
                                <p className="card-text mb-0">{calendar_at}</p>
                            </div>
                        }

                        {manager.last_note ? (
                            <div>
                                <hr />
                                <small className="text-primary card-subtitle mb-2">
                                    <span style={{ 'marginRight': '5px' }}>LAST NOTE</span>
                                    <span className="badge bg-secondary">OP {op_assigned}</span>
                                </small>
                                <p className="card-text mb-0">{lead_id}</p>
                                <p className="card-text mb-0">{created_at_note}</p>
                                <p className="card-text mb-0">{note}</p>
                            </div>
                        ) : (
                            <small className="text-primary card-subtitle mb-2"></small>
                        )}
                    </div>
                }

            </div>

        </div>
    )
}

export default Dialer