import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../instance';
import { setIsLoading } from './managerSlice';

const remarketingSlice = createSlice({
    name: 'remarketing',
    initialState: {
        data: [],
        typeDate: 'created_at',
        refreshRemarketing: false,
        isError: false
    },
    reducers: {
        setTypeDate: (state, action) => {
            state.typeDate = action.payload;
        },
        setRefreshRemarketing: (state, action) => {
            state.refreshRemarketing = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRemarketing.pending, (state) => {
                state.isError = false;
            })
            .addCase(fetchRemarketing.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isError = false;
            })
            .addCase(fetchRemarketing.rejected, (state, action) => {
                console.error('Error fetching:', action.error);
                state.isError = true;
            })
    },
});

export const { setTypeDate, setRefreshRemarketing } = remarketingSlice.actions;

export const fetchRemarketing = createAsyncThunk('fetch/remarketing', async (_, { getState, dispatch }) => {

    try {

        dispatch(setIsLoading(true));

        const state = getState();
        const token = state.auth.token;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const response = await instance.get('/settings/remarketing');

        if (response.status === 200) {
            dispatch(setIsLoading(false))
            return response.data;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {

        console.error('Error get data:', error);
        throw error;
    }

});

export const storeRemarketing = createAsyncThunk('store/remarketing', async (_, { getState, dispatch }) => {

    dispatch(setIsLoading(true));

    try {
        const state = getState();
        const token = state.auth.token;
        const type = state.remarketing.typeDate;
        const { status1_id, status2_id, status3_id, status4_id, created_at_from, created_at_to, appointment_at_from, appointment_at_to } = getState().leadSearch.params;

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        let from, to;

        if (type === 'created_at') {
            from = created_at_from;
            to = created_at_to;
        } else {
            from = appointment_at_from;
            to = appointment_at_to;
        }

        const response = await instance.post(`/settings/remarketing/store`, {
            type,
            status1_id,
            status2_id,
            status3_id,
            status4_id,
            from,
            to
        });

        if (response.status === 200) {
            dispatch(setIsLoading(false))
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {
        console.error('Error getting data:', error);
        throw error;
    }

});

export const deleteRemarketing = createAsyncThunk('delete/remarketing', async (id, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;

        const response = await instance.delete(`/settings/remarketing/destroy/${id}`);

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {
        console.error('Error getting data:', error);
        throw error;
    }

});

export const resetRemarketing = createAsyncThunk('reset/remarketing', async (id, { getState }) => {

    try {

        const state = getState();
        const token = state.auth.token;

        const response = await instance.get(`/settings/remarketing/reset/${id}`);

        if (token) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        if (response.status === 200) {
            return;
        } else {
            throw new Error('Error updating');
        }

    } catch (error) {
        console.error('Error getting data:', error);
        throw error;
    }

});

export default remarketingSlice.reducer;
